import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAssignedAssessments, claimCode, getEvaluation } from 'src/redux/thunks';
import { updateAccessCode, updateAssessmentId, updateOwnerEmail, updateAssessmentType } from 'src/redux/actions';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Toolbar, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import TableSpinner from 'src/components/shared/TableSpinner';
import HeaderButtons from 'src/components/shared/HeaderButtons';
import Footer from 'src/components/shared/Footer';

const TakeAssessment = (props) => {

    const {
        userInfo,
        assignedAssessments,
        isAssignedLoading,
        isClaimUpdating,
        didClaimFail,
        claimMessage,
        didClaimPass,
        getList,
        claimCode,
        getEvaluation,
        isEvaluationLoading,
        evalError,
        didEvalLoadPass,
        updateAccessCode,
        updateAssessmentId,
        updateOwnerEmail,
        updateAssessmentType,
        ownershipData
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [isClaimUpdating])

    useEffect(() => {
        let _assessments = []
        if (assignedAssessments.length > 0) {
            _assessments.push(...assignedAssessments)
        }
        if (ownershipData.length > 0) {
            _assessments.push(...ownershipData)
        }

        _assessments = _assessments.filter((assessment, index, self) =>
            index === self.findIndex((t) => (
                t.accessCode === assessment.accessCode
            ))
        )

        _assessments = _assessments.map(assessment => {
            if (!assessment.proposedOwner && !(assessment.owner.email === userInfo.email && assessment.assessor.email && assessment.owner.email !== assessment.assessor.email)) {
                return assessment
            }
        })

        _assessments = _assessments.filter(Boolean)

        setAssesments(_assessments)
    }, [assignedAssessments, ownershipData])

    const [localAccess, setLocalAccess] = useState(null)
    const [assessments, setAssesments] = useState([])

    const getData = () => {
        getList(userInfo.email);
    }

    const handleLinkClick = async (rowData) => {
        if (!isEvaluationLoading) {
            //identify loading button
            setLocalAccess(rowData.accessCode)
            //get assessment
            const didPass = await getEvaluation(rowData.storeId, rowData.accessCode)

            if (!rowData.owner.email) {
                alert('There is no owner assigned to this assessment, please contact customer service')
                return
            }
            if (didPass) {
                //update redux 
                updateAccessCode(rowData.accessCode)
                updateAssessmentId(rowData.storeId)
                updateOwnerEmail(rowData.owner.email)
                updateAssessmentType(rowData.type)
                //navigate
                navigate('/app/assessment-details', { replace: true })
            }
        }
    }

    const getFrom = (data, ownerIsPurchaser) => {

        const isPromoCode = data.purchaserEmail == 'adminpurch@vectorreports.com'

        if (data.assessor.email === userInfo.email && data.owner.email !== 'adminpurch@vectorreports.com') {
            return data.owner.email
        } else {
            return isPromoCode && !data?.previousOwner ? "VR Promo" : ownerIsPurchaser ? data?.owner?.email : data?.previousOwner
        }
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | Take</title>
            </Helmet>
            <Box my={8} mx={4} sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" style={{ fontWeight: 600 }}>
                        Take Assessment
                    </Typography>
                    <HeaderButtons />
                </Container>
                {/* Table */}
                <Box mt={2}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <Toolbar>
                                <Typography variant="h4" id="takeLabel" color="textSecondary" >
                                    Select the appropriate assessment to Take.
                                </Typography>
                            </Toolbar>
                            <TableContainer sx={{ pl: 1.2 }}>
                                <Table aria-label="takeTable" size={'medium'}>
                                    {/* Table Header - Sort */}
                                    <TableHead>
                                        <TableRow >
                                            {
                                                headCells.map((headCell) => {
                                                    return (
                                                        <TableCell key={headCell.id}>
                                                            {headCell.label}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {/* Table Body */}
                                    <TableBody>
                                        {assessments && assessments.length > 0 ? (
                                            assessments.map((row, index) => {
                                                const key = `take-${index}`;
                                                const statusColor = row.assessor?.email !== row.owner?.email ? 'red' : '#4caf50'
                                                const ownerIsPurchaser = row.owner?.email == row.purchaserEmail;
                                                const isPromoCode = row.purchaserEmail == 'adminpurch@vectorreports.com'

                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell sx={{ color: statusColor }}>
                                                            {ownerIsPurchaser ? 'Purchase' : 'Transfer In'}
                                                        </TableCell>
                                                        <TableCell sx={{ color: statusColor }}>
                                                            {getFrom(row, ownerIsPurchaser)}
                                                        </TableCell>
                                                        <TableCell sx={{ color: statusColor }}>{row.assessor?.email}</TableCell>
                                                        <TableCell sx={{ color: statusColor }}>{row.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                                        <TableCell sx={{ color: statusColor }}>{row.displayCode}</TableCell>
                                                        <TableCell sx={{ color: statusColor }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-center' }}>
                                                                <LoadingButton
                                                                    sx={{ backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                                                    variant="contained"
                                                                    size="large"
                                                                    onClick={() => handleLinkClick(row)}
                                                                    loading={isEvaluationLoading && localAccess == row.accessCode}
                                                                >
                                                                    Take
                                                                </LoadingButton>

                                                                {(evalError && localAccess == row.accessCode) && (
                                                                    <Typography color="#f44335" pt={2} fontSize={15} >
                                                                        {evalError}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <TableSpinner isLoading={isAssignedLoading} colSpan={headCells.length} />
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Container>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }
});

const headCells = [
    { id: 'status', numeric: false, label: 'Method' },
    { id: 'from', numeric: false, label: 'From' },
    { id: 'assessor', numeric: false, label: 'Assigned Assessor Email' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'displayCode', numeric: false, label: 'Code' },
    { id: 'assessment', numeric: false, label: 'Action' },
];

const mapStateToProps = state => ({
    ownershipData: state.assessment.ownershipData,
    userInfo: state.auth.userInfo,
    assignedAssessments: state.assessment.assignedAssessments,
    isAssignedLoading: state.assessment.isAssignedLoading,
    isClaimUpdating: state.assessment.isClaimUpdating,
    didClaimFail: state.assessment.didClaimFail,
    claimMessage: state.assessment.claimMessage,
    didClaimPass: state.assessment.didClaimPass,
    isEvaluationLoading: state.takingAssessment.isEvaluationLoading,
    evalError: state.takingAssessment.evalError,
    didEvalLoadPass: state.takingAssessment.didEvalLoadPass
});


const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getAssignedAssessments(email)),
    claimCode: (accessCode, email) => dispatch(claimCode(accessCode, email)),
    getEvaluation: (assessmentId, accessCode) => dispatch(getEvaluation(assessmentId, accessCode)),
    updateOwnerEmail: (email) => dispatch(updateOwnerEmail(email)),
    updateAccessCode: (data) => dispatch(updateAccessCode(data)),
    updateAssessmentId: (data) => dispatch(updateAssessmentId(data)),
    updateOwnerEmail: (data) => dispatch(updateOwnerEmail(data)),
    updateAssessmentType: (data) => dispatch(updateAssessmentType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TakeAssessment);