import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setNavigation } from 'src/redux/actions';

import { Button, Box, Typography, Divider } from '@material-ui/core'

import { DownArrowIcon } from './DownArrowIcon'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        maxWidth: 200
    },
}));

const HeaderButtons = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const ownershipData = useSelector(state => state.assessment.ownershipData)
    const assessmentHistory = useSelector(state => state.assessment.assessmentHistory)
    const assignedAssessments = useSelector(state => state.assessment.assignedAssessments)
    const isLoadingAllData = useSelector(state => state.assessment.isLoadingAllData)

    useEffect(() => {
        if (!isLoadingAllData) {
            let _buttons = [0, 0, 0, 0]
            ownershipData.forEach(data => {

                if (!data.isCompleted && data.assessor?.email === '') {
                    _buttons[0] = 1
                }
                _buttons[2] = 1


                if (!data.assessor?.email) {
                    _buttons[3] = 1
                }
            })

            assignedAssessments.forEach(assigned => {

                _buttons[0] = 1
            })

            assessmentHistory.forEach(history => {
                _buttons[1] = 1
            })

            dispatch(setNavigation(_buttons))
        }
    }, [isLoadingAllData, ownershipData, assessmentHistory, assignedAssessments])

    const navigation = useSelector(state => state.auth.navigation)

    return (
        <>
            <Typography color="textPrimary" gutterBottom variant="body2">
                You may exercise up to four actions depending upon both your role and the status of the assessment
                itself. 1) Assessors may &quot;Take&quot; an assessment to evaluate an organization, and 2) &quot;View&quot; their report of a
                completed assessment, 3) Code owners may &quot;Assign&quot; or reassign an assessment to another assessor,
                and 4) &quot;Transfer&quot; and relinquish ownership to another person altogether. Use the navigation links on
                the left for guidance. Click a button below to list available assessments to act upon.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2} mb={1}>
                {/* button bar */}
                <Box sx={{ width: 400, marginRight: '3em' }} mt={4}>
                    <Typography align="center" color="textPrimary" variant="body2">
                        Assessment Actions
                    </Typography>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2} mb={1}>
                        <BootstrapTooltip title="Displays a list of eligible assessments that you may use to assess a small business.">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                <Button onClick={() => navigate('/app/take', { replace: true })} sx={{ width: 140, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }} disabled={!navigation[0]} size="large" variant="contained">
                                    Take Assessment
                                </Button>
                                {location.pathname === '/app/take' && <DownArrowIcon color={'#4caf50'} style={{ marginTop: '1em' }} />}
                            </Box>
                        </BootstrapTooltip>

                        <BootstrapTooltip title="Displays a list of your completed assessment reports you may view.">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                <Button onClick={() => navigate('/app/view', { replace: true })} sx={{ width: 140, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }} disabled={!navigation[1]} size="large" variant="contained">
                                    View <br /> Reports
                                </Button>
                                {location.pathname === '/app/view' && <DownArrowIcon color={'#4caf50'} style={{ marginTop: '1em' }} />}
                            </Box>
                        </BootstrapTooltip>
                    </Box>
                </Box>
                <Box sx={{ width: 400 }} mt={4}>
                    <Typography align="center" color="textPrimary" variant="body2">
                        Owner Actions
                    </Typography>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2} mb={1}>
                        <BootstrapTooltip title="Displays a list of eligible Assessments you may identify another person to assess a business.">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                <Button onClick={() => navigate('/app/assign', { replace: false })} sx={{ width: 140 }} color="primary" disabled={!navigation[2]} size="large" variant="contained">
                                    Assign Assessor
                                </Button>
                                {location.pathname === '/app/assign' && <DownArrowIcon color={'#4166f5'} style={{ marginTop: '1em' }} />}
                            </Box>
                        </BootstrapTooltip>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <BootstrapTooltip title={<Box>
                                <Box>Displays a list of eligible Assessments you may identify another person to own.</Box>
                                <Box>This relinquishes your ability to Take, View, or Assign this Assessment.</Box>
                            </Box>}>
                                <span>
                                    <Button onClick={() => navigate('/app/transfer', { replace: true })} sx={{ width: 140 }} color="primary" disabled={!navigation[3]} size="large" variant="contained">
                                        Transfer Ownership
                                    </Button>
                                </span>
                            </BootstrapTooltip>
                            {location.pathname === '/app/transfer' && <DownArrowIcon color={'#4166f5'} style={{ marginTop: '1em' }} />}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default HeaderButtons;