import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import HomeNavbar from './HomeNavbar';
import HomeSidebar from './HomeSidebar';

const HomeLayoutRoot = experimentalStyled('div')(
    ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    })
);

const HomeLayoutWrapper = experimentalStyled('div')(
    ({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
        paddingLeft: 256
        }
    })
);

const HomeLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const HomeLayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
});

const HomeLayout = () => {
     const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <HomeLayoutRoot>
        <HomeNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <HomeSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
        />
        <HomeLayoutWrapper>
            <HomeLayoutContainer>
            <HomeLayoutContent>
                <Outlet />
            </HomeLayoutContent>
            </HomeLayoutContainer>
        </HomeLayoutWrapper>
    </HomeLayoutRoot>
  );
};

export default HomeLayout;
