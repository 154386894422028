import React from 'react'
import {
    NavLink as RouterLink,
    matchPath,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';


const NavItem = ({ href, icon: Icon, title, loadData, enabled, external, ...rest }) => {
    const location = useLocation();

    const active = href ? !!matchPath({
        path: href,
        end: false
    }, location.pathname) : false;

    return (
        <ListItem disableGutters sx={{ display: 'flex', py: 0 }} {...rest} >
            <Button
                disabled={enabled}
                component={external ? 'a' : RouterLink}
                sx={{
                    color: 'text.secondary', fontWeight: 'medium', justifyContent: 'flex-start', letterSpacing: 0,
                    py: 1.25, textTransform: 'none', width: '100%', ...(active && { color: 'primary.main' }), '& svg': { mr: 1 }
                }}
                target={external ? '_blank' : '_self'}
                href={href}
                to={href}
                onClick={loadData}
            >
                {Icon && (
                    <Icon size="20" />
                )}
                <span>
                    {title}
                </span>
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
    loadData: PropTypes.func,
};

export default NavItem;
