import { Box } from '@mui/material';
import Copyright from './Copyright';

const Footer = () => {
    return (
        <Box sx={{textAlign: 'center'}}>
            <Copyright />
        </Box>
      
    )
}

export default Footer;