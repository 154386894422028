import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Box, Typography, TextField } from '@material-ui/core';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'

import {
    verifyCode,
} from 'src/redux/thunks';
import { setResetCodeFields, setResetLoginFields } from 'src/redux/actions';

const Code = () => {

    const dispatch = useDispatch()
    const formikRef = useRef()
    const location = useLocation()

    const isVerifyUpdating = useSelector(state => state.auth.isVerifyUpdating)
    const resetCodeFields = useSelector(state => state.auth.resetCodeFields)

    useEffect(() => {
        if (location.key && formikRef?.current) {
            formikRef.current.resetForm()
            dispatch(setResetCodeFields(false))
        }
    }, [location, formikRef, resetCodeFields])

    const handleFocus = () => {
        dispatch(setResetLoginFields(true))
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 300 }}>
            <Formik
                innerRef={formikRef}
                initialValues={{ code: '' }}
                validationSchema={Yup.object().shape({ code: Yup.string().max(26).min(16) })}
                onSubmit={async event => {
                    await dispatch(verifyCode(event.code, null, true))

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ mb: 1 }}>
                            <Typography color="textPrimary" variant="h2" >
                                Have a Code?
                            </Typography>
                        </Box>
                        <TextField
                            error={Boolean(touched.code && errors.code)}
                            fullWidth
                            helperText={touched.code && errors.code}
                            label="Enter unused access code"
                            margin="normal"
                            name="code"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.code}
                            variant="outlined"
                            sx={{ backgroundColor: 'white' }}
                        />
                        <Box sx={{ py: 2 }}>
                            <Button
                                color="primary"
                                disabled={isVerifyUpdating}
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{ width: 200 }}
                            >
                                Enter
                            </Button>
                        </Box>
                        <Box pt={1}>
                            <Typography color="textSecondary" variant="body1" >
                                Carefully type in the access code you have in your possession.
                            </Typography>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
};

export default Code

