const LogoDark = (props) => (
  <img
    height="50px"
    alt="Logo"
    src="/static/logo.png"
    {...props}
  />
);

export default LogoDark;
