

import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import PageHeader from './PageHeader'

const ProcessRatingsPage = (props) => {

    const {
        assessorData
    } = props;

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''


    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Intepreting Your Process Ratings
                </Typography>
            </Box>

            {/* banner */}
            <Box className={classes.blueBanner} py={0.5} mt={1}>
                <Typography variant="body1" style={{ fontSize: 19 }} m={1}>
                    <i>Your overall report was created by the sum and interactions of all the Category Process Ratings. There are insights to be gained by examining these individual process scores.</i>
                </Typography>
            </Box>

            {/* first section */}
            <Box mt={6}>
                <Typography gutterBottom variant="h3">
                   Actionable Solutions are in the Process Details
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    Depending upon your Capabilities Maturity Plot results, you will need to improve some of your business
                    capabilities to become a more robust business. To make improvement efforts more efficient you will
                    need to change focus and improve your business processes in a logical and efficient way.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    Therein lies the challenge – How?
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    It makes little sense to improve your strengths when process vulnerabilities are inhibiting your progress.
                    Our advice is to focus on improving low-rated processes first. This supports the improvement philosophy
                    that “all ships rise with the tide.” Another way to convey this idea is that a chain is most vulnerable at its
                    weakest link—so start there.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    There are other decision factors (e.g. influence, sequence, maturity, know-how, environment) that need
                    to be considered when selecting what to work on first, second, and so on. No very small business has all
                    the resources needed to simultaneously work on all their vulnerabilities.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    The following pages in your report display bar charts of the Category Processes showing the ratings your
                    assessor(s) provided. Keep these factors in mind as you decide what to work on next. Work on whatever
                    you are convinced takes priority, but be sure you have solid logic for your prioritization.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    Should you need more guidance, this assessment provides a few process improvement
                    recommendations that follow a sound prioritization method.
                </Typography>
            </Box>

            {/* second section */}
            <Box mt={6}>
                <ul>
                    <Typography gutterBottom variant="h4">
                        Are there “patterns” in your Process Ratings Bar Charts on the next page?
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                        <li> Look for low or high outlier ratings anywhere on the four sets of Process Categories.</li>
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                        <li>Look for uneven ratings (highly rated processes along with low-rated processes) in the same
                            Category. This includes left-side bar maturities being lower than the right-side bar maturities (Note
                            that the left side bars are typically process predecessors that later ones are dependent upon).</li>
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                        <li> Consider efforts that could be delayed, resources that could be shifted away from mature or "good" processes, so that they could be applied to those processes that are less mature.</li>
                    </Typography>
                </ul>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    blueBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#B8CCE4',
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,

})

export default connect(mapStateToProps)(ProcessRatingsPage);
