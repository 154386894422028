import { combineReducers } from "redux";
import auth from './auth';
import assessment from './assessment';
import takingAssessment from './takingAssessment';
import adminReports from './adminReports';
import payment from './payment'

//combine all reducers
export default combineReducers({ 
    auth, 
    assessment, 
    takingAssessment,
    adminReports,
    payment
});