import React, { useEffect, useState } from 'react'
//redux
import { connect  } from 'react-redux';
//ui
import { Box, Typography  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types';
import FormatList from './FormatList'

//displays 1 of 12 quad messages 
const QuadMessage = (props) => {
    
    const {
        isFirstPage,
        quadMessageId,
        quadMessages,
    } = props;

    const [messageArr, setMessageArr] = useState([])
    const [pageBreakIndex, setPageBreakIndex] = useState(0);

    useEffect(() => { 
        try{
            if(quadMessageId && quadMessages) {
                //id is not index so subtract 1
                setMessageArr(quadMessages[quadMessageId - 1].paragraphs);
                setPageBreakIndex( quadMessages[quadMessageId - 1].pageBreakIndex);
            }
        } catch(e) {
            console.log(e.message)
        }
    },[])

    return (
        <>
            {   Array.isArray(messageArr) && (
                    //loop through selected quad messagae and output text
                    messageArr.map((element,index) => {
                        //display first or second page based on break index (page 5/6 main report)
                        if (isFirstPage && index <= pageBreakIndex || !isFirstPage && index > pageBreakIndex) {  
                            try {
                                return (
                                    <Box mt={4} key={index}>  
                                        { typeof element == 'object'
                                            ? <FormatList list={element} />
                                            : <Typography  variant="body1" style={{ fontSize: 18 }} >{element}</Typography>
                                        }                               
                                    </Box>   
                                )
                            } catch(e) {
                                console.log('QuadMessage return: ', e.message)
                            }
                        } 
                    })
                )
            }   
        </>
    )
}


QuadMessage.propTypes = {
    isFirstPage: PropTypes.bool,
}

const mapStateToProps = state => ({
    quadMessages: state.takingAssessment.quadMessages,
    quadMessageId: state.takingAssessment.quadMessageId
})

export default connect(mapStateToProps)(QuadMessage);