import React, { useState } from "react";
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Card, CardContent, Container, Modal, Link } from '@material-ui/core';

import TermsCard from 'src/components/shared/TermsCard';

const TermsModal = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* <Button variant="contained" color="primary" onClick={handleOpen}>
                Terms and Conditions
            </Button> */}
            <Link color="primary" underline="always" variant="h6" onClick={handleOpen}>
                Terms and Conditions
            </Link> 
            <Modal
                aria-labelledby="terms-modal-title"
                aria-describedby="terms-modal-description"
                open={open}
                onClose={handleClose}
                className={classes.modal}
            >
                <Box sx={{ my:12 }}>
                    <Container maxWidth="md">
                        <TermsCard />
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={handleClose}
                        >
                                Okay
                        </Button>
                    </Container>
                </Box>
            </Modal>
        </>
    );
}


const useStyles = makeStyles(theme => ({
    modal: {
        overflow:'scroll',
    },
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }
}));


export default TermsModal;