
import Calibri from './fonts/Calibri.woff';

export default {
  h1: {
    fontWeight: 600,
    fontSize: 35,
    letterSpacing: '-0.24px',
    fontFamily: Calibri
  },
  h2: {
    fontWeight: 600,
    fontSize: 30,
    letterSpacing: '-0.24px',
    fontFamily: Calibri
  },
  h3: {
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: '-0.06px',
    fontFamily: Calibri
  },
  h4: {
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: '-0.06px',
    fontFamily: Calibri
  },
  h5: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '-0.05px',
    fontFamily: Calibri
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '-0.05px',
    fontFamily: Calibri
  },
  overline: {
    fontWeight: 500
  },
  body1: {
    fontFamily: 'Calibri',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.1
  },
  body2: {
    fontFamily: 'Calibri',
    fontWeight: 400,
    fontSize: 16,
  },
  button: {
    textTransform: "none"
  }
};
