import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

const ProcessIntro = (props) => {

    const {
        header,
        paragraphs,
    } = props;

    const classes = useStyles();

    return (
        <Box className={classes.container} pl={8} pr={10}  mt={4}>
            <Box>
                <Typography color="textPrimary" gutterBottom variant="h2">{header}</Typography>
            </Box>
            { paragraphs.map((row, index) => {
                return (
                    <Box mt={4} key={`intro-p-${index}`}>
                        {/* <Typography color="textPrimary">{row}</Typography> */}
                        <Typography color="textPrimary"  style={{ fontSize: 18 }} ><div dangerouslySetInnerHTML={{__html:row}}></div></Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        maxWidth: 1250
    },
});

ProcessIntro.propTypes = {
    header: PropTypes.string.isRequired,
    paragraphs: PropTypes.array.isRequired,
}

export default ProcessIntro;

