import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";

import WelcomePage from 'src/views/app/MyPanel';

const Welcome = () => {

  const accessCode = useSelector(state => state.auth.accessCode)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const navigate = useNavigate();

  useEffect(() => {
    if(!isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated])

  const Verify = () => (
    <Box m={10}>
      <Typography color="textPrimary" variant="h3"> Verify Access Code </Typography>
    </Box>
  )

  return (
    <> 
      <Helmet>
          <title>Vector Reports | Welcome</title>
      </Helmet>
      {accessCode ? <WelcomePage /> : <Verify /> }
    </>
  )
}

export default Welcome