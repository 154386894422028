
//update access code
export const UPDATE_ACCESS_CODE = 'UPDATE_ACCESS_CODE';
export const updateAccessCode = data => ({
    type: UPDATE_ACCESS_CODE,
    data
});

//update assessment id
export const UPDATE_ASSESSMENT_ID = 'UPDATE_ASSESSMENT_ID';
export const updateAssessmentId = data => ({
    type: UPDATE_ASSESSMENT_ID,
    data
});

//update assessment id
export const UPDATE_ASSESSMENT_TYPE = 'UPDATE_ASSESSMENT_TYPE';
export const updateAssessmentType = data => ({
    type: UPDATE_ASSESSMENT_TYPE,
    data
});

//update owner email
export const UPDATE_OWNER = 'UPDATE_OWNER';
export const updateOwnerEmail = data => ({
    type: UPDATE_OWNER,
    data
});

//is taking assessment
export const UPDATE_TAKING_ASSESSMENT = 'UPDATE_TAKING_ASSESSMENT';
export const updateTakingAssessment = isTakingAssessment => ({
    type: UPDATE_TAKING_ASSESSMENT,
    isTakingAssessment
});

//show first page before process intro
export const SHOW_MAIN_INTRO = 'SHOW_MAIN_INTRO';
export const updateShowMainIntro = bool => ({
    type: SHOW_MAIN_INTRO,
    bool
});

//update selection
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const updateSelection = (score, categoryIndex, processIndex) => ({
    type: UPDATE_SELECTION,
    score, 
    categoryIndex, 
    processIndex
});

//update temp notes
export const UPDATE_TEMP_NOTES = 'UPDATE_TEMP_NOTES';
export const updateTempNotes = notes => ({
    type: UPDATE_TEMP_NOTES,
    notes,
});

//update notes
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const updateNotes = (notes, categoryIndex, processIndex) => ({
    type: UPDATE_NOTES,
    notes, 
    categoryIndex, 
    processIndex
});

//update category counter
export const UPDATE_CATEGORY_COUNTER = 'UPDATE_CATEGORY_COUNTER';
export const updateCategoryCounter = index => ({
    type: UPDATE_CATEGORY_COUNTER,
    index
});

//update process counter
export const UPDATE_PROCESS_COUNTER = 'UPDATE_PROCESS_COUNTER';
export const updateProcessCounter = index => ({
    type: UPDATE_PROCESS_COUNTER,
    index
});

//update did submit bool
export const UPDATE_DID_SUBMIT = 'UPDATE_DID_SUBMIT';
export const updateDidSubmit = didSubmit => ({
    type: UPDATE_DID_SUBMIT,
    didSubmit
});

//update did submit bool
export const UPDATE_FINAL_SCORES = 'UPDATE_FINAL_SCORES';
export const updateFinalScores = finalArr => ({
    type: UPDATE_FINAL_SCORES,
    finalArr
});

//update did submit bool
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export const resetAssessment = () => ({
    type: RESET_ASSESSMENT
});

//update assessor data
export const UPDATE_ASSESSOR = 'UPDATE_ASSESSOR';
export const updateAssessor = data => ({
    type: UPDATE_ASSESSOR,
    data
});

//temp, hides sidebar
export const EDIT_REPORT = 'EDIT_REPORT';
export const editReport = () => {
    return {
        type: EDIT_REPORT,
    };
}

//update report content
export const UPDATE_REPORT_CONTENT = 'UPDATE_REPORT_CONTENT';
export const updateReportContent = data => ({
    type: UPDATE_REPORT_CONTENT,
    data
});

/* 
 * thunks
 */

//get assessment evaluation
export const GET_EVALUATION_REQUEST = 'GET_EVALUATION_REQUEST';
export const getEvaluationRequest = () => {
    return {
        type: GET_EVALUATION_REQUEST,
    };
}
export const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS';
export const getEvaluationSuccess = (data, code) => {
    return {
        type: GET_EVALUATION_SUCCESS,
        data,
        code
    };
}
export const GET_EVALUATION_FAILURE = 'GET_EVALUATION_FAILURE';
export const getEvaluationFailure = errMsg => {
    return {
        type: GET_EVALUATION_FAILURE,
        errMsg
    };
}

//get graphic - grid image
export const GET_GRAPHIC_REQUEST = 'GET_GRAPHIC_REQUEST';
export const getGraphicRequest = () => {
    return {
        type: GET_GRAPHIC_REQUEST,
    };
}
export const GET_GRAPHIC_SUCCESS = 'GET_GRAPHIC_SUCCESS';
export const getGraphicSuccess = data => {
    return {
        type: GET_GRAPHIC_SUCCESS,
        data
    };
}
export const GET_GRAPHIC_FAILURE = 'GET_GRAPHIC_FAILURE';
export const getGraphicFailure = errMsg => {
    return {
        type: GET_GRAPHIC_FAILURE,
        errMsg
    };
}

//create evaluation record after completion

export const CREATE_EVAL_REQUEST = 'CREATE_EVAL_REQUEST';
export const evalRequest = () => {
    return {
        type: CREATE_EVAL_REQUEST,
    };
}
export const CREATE_EVAL_SUCCESS = 'CREATE_EVAL_SUCCESS';
export const evalSuccess = data => {
    return {
        type: CREATE_EVAL_SUCCESS,
        data
    };
}
export const CREATE_EVAL_FAILURE = 'CREATE_EVAL_FAILURE';
export const evalFailure = errMsg => {
    return {
        type: CREATE_EVAL_FAILURE,
        errMsg
    };
}

//get evaluation record 
export const GET_EVAL_REQUEST = 'GET_EVAL_REQUEST';
export const getEvalRequest = () => {
    return {
        type: GET_EVAL_REQUEST,
    };
}
export const GET_EVAL_SUCCESS = 'GET_EVAL_SUCCESS';
export const getEvalSuccess = data => {
    return {
        type: GET_EVAL_SUCCESS,
        data
    };
}
export const GET_EVAL_FAILURE = 'GET_EVAL_FAILURE';
export const getEvalFailure = errMsg => {
    return {
        type: GET_EVAL_FAILURE,
        errMsg
    };
}