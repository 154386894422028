
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Typography, List, ListItem, Table, TableContainer, TableBody, TableRow, TableCell } from '@material-ui/core';
import Footer from 'src/components/shared/Footer';
import { Document, Page, pdfjs } from 'react-pdf'
import Loading from 'src/components/shared/Loading'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import pdfFile from './FAQ.pdf'

const QuickHelp = () => {

    const [url, setUrl] = useState(null)
    const [file, setFile] = useState(pdfFile)
    const [numPages, setNumPages] = useState(null)
    const [pageWidth, setPageWidth] = useState(800)

    const containerRef = useRef(null)

    useEffect(() => {
        setPageWidth(containerRef.current.offsetWidth - 100)
    }, [containerRef])

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages)
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | FAQ</title>
            </Helmet>
            <Box sx={{ my: 8 }}>
                <Container maxWidth="md" mt={4} ref={containerRef}>
                    <Box m={6}>
                        <Document loading={<Loading />} file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Card sx={{ marginBottom: 2 }}>
                                    <CardContent>
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            scale={1.2}
                                        // width={pageWidth}
                                        />
                                    </CardContent>
                                </Card>
                            ))}
                        </Document>

                        {/* <Card sx={{ marginBottom: 2 }}>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                                
                            </CardContent>
                        </Card> */}
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default QuickHelp