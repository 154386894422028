import React, { useEffect, useState, useRef } from "react";
import { TextField} from '@material-ui/core';
import PropTypes from 'prop-types'

import { connect  } from 'react-redux';
import { updateTempNotes, updateNotes } from 'src/redux/actions';

/*
 * all from component : 
 * 1. ---- onload retrieve note for process in scope, update local scope
 * 2. ---- ontype update local scope and updateLocalNotes which doesnt update the evaluation array
 * 3. on back or next click, check for changes in updateLocalNotes to evaluation array and update if needed, empty UPDATE_TEMP_NOTES after 
 * -- create global counter, update it in Next/Back click, if change then update notes 
*/


const ProcessNotes = (props) => {
    const { 
        tempNotes,
        categoryIndex,
        processIndex,
        updateTempNotes,
        updateNotes,
        categories,
    } = props;

    const [localNotes, setLocalNotes] = useState('');
    const textRef = useRef(null);
    
    const handleNotesChange = async (event) => {
        setLocalNotes(event.target.value);
        //update temp notes, these are used to update the evaluation object on next/back click from parent
        updateTempNotes(event.target.value);
        if (event.target.value.length === 0) {
            textRef.current = '***';
        } else {
            textRef.current = event.target.value;
        }
    }

    //mount
    useEffect(() => {
        //reset temp notes
        updateTempNotes('');

        if (tempNotes) {
            //replace with object notes
            setLocalNotes(tempNotes)
        }

        //update local notes with object notes
        if (categories) {
            setLocalNotes(categories[categoryIndex].processes[processIndex].notes)
        }

    },[])
    
    //unmount
    useEffect(() => {
        return () =>{
            //update notes with cached text
            if(textRef.current && textRef.current.length) {
                //todo: updating notes causes slider/input bug
                if(textRef.current == '***') {
                    updateNotes('', categoryIndex, processIndex);
                } else {
                    updateNotes(textRef.current, categoryIndex, processIndex);
                }
            }
        }
    },[])
    

    return (
        <TextField
            label="Level justification notes"
            multiline
            rows={3}
            fullWidth
            InputLabelProps={{style: {fontStyle: 'italic'}}}
            sx={{backgroundColor:'white'}}
            value={localNotes}
            onChange={handleNotesChange} 
            inputProps={{ maxLength: 330 }}
        />
    )
}

ProcessNotes.propTypes = {
    categoryIndex: PropTypes.number.isRequired,
    processIndex: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
    categories: state.takingAssessment.evaluation,
    tempNotes: state.takingAssessment.tempNotes,
})
const mapDispatchToProps = dispatch => ({
    updateTempNotes: notes => dispatch(updateTempNotes(notes)),
    updateNotes: (notes, categoryIndex, processIndex) => dispatch(updateNotes(notes, categoryIndex, processIndex))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcessNotes);