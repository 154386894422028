
import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
//shared
import Footer from 'src/components/reports/Footer';

const CoverPage = (props) => {

    //redux
    const {
        assessorData,
        dateAssessed
    } = props;

    //variables
    const classes = useStyles();

    return (
        <>
            {/* logo */}
            <Box className={classes.centeredColumn} mt={4}>
                <img width="350px" alt="Logo" src="/static/logo.png" />
            </Box>

            {/* header */}
            <Box className={classes.centeredColumn} mt={8}>
                <Typography gutterBottom variant="h2">
                    B U S I N E S S  &nbsp;&nbsp;  C A P A B I L I T I E S  &nbsp;&nbsp;  M A T U R I T Y  &nbsp;&nbsp;  R E P O R T
                </Typography>
            </Box>

            {/*  sub header */}
            <Box className={classes.centeredColumn} mt={6} mb={12}>
                <Typography gutterBottom variant="h3">
                    <img height={30} alt="Logo" src="/static/images/VIA-logo.png" />
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="h5">
                    <i>(companies with a couple to 50 employees)</i>
                </Typography>
            </Box>

            {/*  company info */}
            <Divider color="black" />
            <Box mt={2}>
                <Box className={classes.splitRow} mt={1} mb={2}>
                    <Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }} mb={2}>
                            <Typography variant="body1" sx={{ fontSize: 19 }}>Company: </Typography>
                            <Typography variant="body1" ml={1} sx={{ fontWeight: 600, fontSize: 20 }}>
                                {assessorData.company.name ? assessorData.company.name : ''}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" sx={{ fontSize: 19 }}>Assessor: </Typography>
                            <Typography variant="body1" ml={1.6} sx={{ fontWeight: 600, fontSize: 20 }}>
                                {assessorData.assessor.name ? assessorData.assessor.name : ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }} mb={2}>
                            <Typography variant="body1" sx={{ fontSize: 19 }}>Project: </Typography>
                            <Typography variant="body1" ml={1} sx={{ fontWeight: 600, fontSize: 20 }}>
                                {assessorData.company.projectName ? assessorData.company.projectName : ''}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" sx={{ fontSize: 19 }}>Date: </Typography>
                            <Typography variant="body1" ml={3.5} sx={{ fontWeight: 600, fontSize: 20 }}>
                                {moment.unix(dateAssessed.seconds).format("M/D/YYYY")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

            </Box>
            <Divider color="black" />
            {/* intro text */}
            <Box mt={12}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    <b>VIA·Business</b> is a systemic view of the maturity status of selected interdependent
                    business processes that your team or organization uses, which reveals capability maturities. It is
                    designed to identify potential vulnerabilities in the use (or non-use) of processes that create and sustain
                    reliable, measured and balanced processes that produce expected business performance.
                </Typography>
            </Box>

            {/* secondary text row with image */}
            <Box mt={12}>
                <Divider color="black" />
                <Box className={classes.flexStart} mt={4}>
                    <Box>
                        <Box>
                            <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                                Your assessor’s ratings of the assessment statements are summarized
                                in this report. Ratings will point to recommendations customized for
                                your team or organization. Imbalances and/or lower process ratings
                                help point to what may be needed to further mature your team or
                                organizational processes and subsequent capability.
                            </Typography>
                        </Box>
                        <Box mt={4}>
                            <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                                You will find within this report a summary results plot and description,
                                process rating graphs, and selected influential process improvement
                                recommendations based upon assessor’s ratings.
                            </Typography>
                        </Box>
                    </Box>

                    <Box pl={4}>
                        <img height="240" alt="Logo" src="/static/images/vsba/cover-graph.png" />
                    </Box>

                </Box>
            </Box>

            {/* final message */}

        </>
    )
}


const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'flext-start',
        alignItems: 'flex-start',
        width: '80%'
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,
    dateAssessed: state.takingAssessment.dateAssessed,
})

export default connect(mapStateToProps)(CoverPage);