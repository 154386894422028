
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import Footer from 'src/components/reports/Footer';
import PropTypes, { bool } from 'prop-types';

const PageLayout = (props) => {
    
    const {
        pageNumber,
        hideFooter
    } = props;

    const classes = useStyles();

    return (
        <Box className={classes.page} px={18} mt={8} pt={10}>
            {props.children}

            {/* copyright */}
            {hideFooter 
                ? <></>
                : <Footer page={pageNumber}/>
            }
        </Box>
    )
}

const useStyles = makeStyles({
    page: {
        backgroundColor: 'white',
        padding: 50,
        height: 1680,
        minWidth: 1200,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
})

PageLayout.propTypes = {
    pageNumber: PropTypes.number.isRequired,
    hideFooter: PropTypes.bool.isRequired
}

export default PageLayout;

