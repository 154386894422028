import React, { useState, useEffect, useRef } from 'react'
//ui
import { Helmet } from 'react-helmet-async';
import { 
    Box, Typography, Container, Paper, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ChevronRight as ArrowForwardIosSharpIcon } from 'react-feather';
import PropTypes from 'prop-types'

import TextEditor from 'src/components/shared/TextEditor';
import TabBar from 'src/components/admin/TabBar';
import { set } from 'lodash'; 


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    //backgroundColor: '#f4f6f8',
    // flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    height: 380,
  }));


const TabView = (props) => {

    const {
        index,
        tabsArr,
        handleHtmlChange,
        handleHtmlChange_2,
        updateTab,
        // initSave,
        // didSave
    } = props;

    const [tabData, setTabData] = useState(tabsArr[0])

    const handleTabChange = async (newTab) => {
        // console.log('1. handleTabChange: ', tabData.name, index,  )
        await updateTab(tabData.name, index, newTab.name)
        setTabData(newTab)
    }
  
    const handleEditorChange = (data) => {
        handleHtmlChange(data, tabData.name, index)
    }
    const handleEditorChange_2 = (data) => {
        handleHtmlChange_2(data, tabData.name, index)
    }
    
    if(!tabsArr[0]) {
        return <></>
    }

    //display 1 or 2 editors based on number pages for report item
    return (
        <>
            <TabBar tabsArr={tabsArr} handleTabChange={handleTabChange}/>
            <Box mt={2}>
                <TextEditor 
                    html={(tabData) ? tabData.content : ''}
                    handleChange={handleEditorChange} />
            </Box>

            {/* { (tabsArr[0] != undefined) && ( */}
            { (tabsArr[0].content_2 != undefined) && (
                <Box mt={10}>
                    <TextEditor 
                        html={(tabData) ? tabData.content_2 : ''}
                        handleChange={handleEditorChange_2}
                        blur={true} />
                </Box>
            )}
        </>
    )
}

const EditAssessmentReport = (props) => {
    
    const { 
        assessment,
        handleSave,
        handleBack,
    } = props;

    const [content, setContent] = useState(null)
    const [expanded, setExpanded] = useState(0)
    const [isDyn, setIsDyn] = useState(false)
    const [didContentChange, setDidContentChange] = useState(false)
    
    const classes = useStyles();
    
    //refs 
    const html = useRef(null);
    const html_2 = useRef(null);
    const collectionIndex = useRef(null);
    const tabNameRef = useRef(null);
    //used for accordian switch bug when wrong tabs were updating
    const scopeTabNameRef = useRef(null);
    const scopeHtml = useRef(null);
    const scopeHtml_2 = useRef(null);
    // const htmlCounter = useRef(null);
    // const htmlCounter_2 = useRef(null);

    // useEffect(() => {
    //         console.log('didContentChange: ', didContentChange)
    // },[didContentChange])

    useEffect(() => {
        if(assessment && assessment.report) {
            // console.log('setContent: ', assessment.report)
            setContent(assessment.report)
        }
    },[])

    const handleAccordionChange = (panel, data, isDynamic) => (event, newExpanded) => {

      try {

        //if previous editor is dynamic open, update content on open/close
        if (isDyn){  //&& didContentChange) {
            // updates collection on accordian change
            // console.log('-------- START \n1: UPDATE ', ) 
            if (tabNameRef.current) {
                // console.log('2: pass ', tabNameRef.current) 
                updateTab(tabNameRef.current, collectionIndex.current, null, true)
            }
            
        }

        if (isDynamic && newExpanded) {
            //sets first tab content on open
            try {
                if (data[0]['content']) {
                    html.current = data[0].content
                 }
                 if (data[0]['content_2']) {
                    html_2.current = data[0].content_2
                 }
            } catch(e) {
                console.log('accordion inner error : ', e.message)
            }

        } else {
            //set collection index per panel to update main object
            collectionIndex.current = panel;
            scopeHtml.current = '';
            scopeHtml_2.current = '';
            // single fields
            if (data.length > 0) {
                html.current = 0
            }
        }
        //
        if(didContentChange) {
            setDidContentChange(false)
        }
        setIsDyn(isDynamic)
      } catch(e) {
          console.log('handleAccordionChange error: ', e.message)
      }

      setExpanded(newExpanded ? panel : false);
    };

    //update content on any change in the html editr for dynamic fields
    const handleHtmlChange = (data, tabName, index) => {
        // console.log('handleHtmlChange: ',tabName, data)
        html.current = data;
        collectionIndex.current = collectionIndex.current != index ? index : collectionIndex.current;
        tabNameRef.current = tabNameRef.current != tabName ? tabName : tabNameRef.current;
        if(!didContentChange) {
            setDidContentChange(true)
        }
    }
    const handleHtmlChange_2 = (data, tabName, index) => {
        html_2.current = data;
        collectionIndex.current = collectionIndex.current != index ? index : collectionIndex.current;
        tabNameRef.current = tabNameRef.current != tabName ? tabName : tabNameRef.current;
        if(!didContentChange) {
            setDidContentChange(true)
        }
    }
    const handleStaticHtmlChange = (data) => {
        let contentObj = content;
        contentObj[collectionIndex.current].content = data
        setContent(contentObj)
    }
       
    //update previous tab on change 
    const updateTab = (tabName ,index, scopeTabName, didAccordionChange) => {
        // console.log('2: tabName ,index+1: ',tabName ,index+1, scopeTabName, html.current)
        if(!didContentChange) {
            return
        }
        if(tabName) {
            try {
                index = parseInt(index)
                //prevents wrong tab updating on accordion exit (when no changes have been made to tab in scope)
                // console.log('<><><> ', didAccordionChange, didContentChange)
                if(didAccordionChange && tabNameRef.current != scopeTabNameRef.current && scopeTabNameRef.current) {
                    // console.log('***** didAccordionChange: ')
                    tabNameRef.current = scopeTabNameRef.current
                    html.current = scopeHtml.current
                    html_2.current = scopeHtml_2.current
                    // console.log(tabNameRef.current, html, html_2)
                }
                // console.log('checkstate: ',content)
                // console.log('checkstate[index+1]: ',content[index+1])
                const newState = content;
                //find and replave object value in array
                try {
                    newState[index+1].content.find((o,i) => {
                        if(newState[index+1].content[i]['name'] === tabName) {
                            // console.log('4: ',content[index+1].content[i])
                            if (o.name === tabName) {
                                // console.log('3: tab match: ', o.name, tabName)
                                //check for second page of content
                                if( newState[index+1].content[i].content_2 != undefined) {
                                    newState[index+1].content[i] = { 
                                        name: tabName, 
                                        content: (html.current) ? html.current : '', 
                                        content_2: (html_2.current) ? html_2.current : ''
                                    };
                                    // console.log('4: ', tabName, html.current, html_2.current)
                                } else {
                                    newState[index+1].content[i] = { 
                                        name: tabName, 
                                        content: (html.current) ? html.current : '', 
                                    };
                                }
                                // console.log('5: ',newState[index+1].content[i], '\n ---- END')
                                //prevents wrong tab updating on accordion exit (when no changes have been made to tab in scope)
                                if (scopeTabName) {
                                    scopeTabNameRef.current = scopeTabName;
                                    scopeHtml.current = html.current;
                                    scopeHtml_2.current = html_2.current;
                                }
                                //stop searching
                                html.current = ''
                                html_2.current = ''
                                return true;
                            }
                        }
                    })
                    setContent(newState)
                    setDidContentChange(false)
                } catch(e) {
                    // console.log('4E: index+1: ',index+1)
                    // console.log('5E: newState: ',newState)
                    // console.log('6E: newState[index+1]: ', newState[index+1])
                    // console.log('7E: index: ',index)
                    console.log('8E: updateTab error: ', e.message,'\n --- END')
                }
                
            } catch(e) {
                console.log('updateTab error: ', e.message)
            }
        }
    }

    const saveChanges = () => {
        //updates tab in scope that hasnt updated from switching
        updateTab(tabNameRef.current, collectionIndex.current, null, true)
        handleSave(content, assessment.reportId)
    }

    /* button container */
    const ButtonContainer = () => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button sx={{width:100}} variant='outlined'  color="primary"
            onClick={() => handleBack()}> 
                Back 
            </Button>
            <Button sx={{width:100}} variant='outlined'  color="secondary"
            onClick={() => saveChanges()}> 
                Save 
            </Button>
        </Box>
    )
    return (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        Edit Content
                    </Typography>
                </Container>
                {/* content */}
                <Box mt={4}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ padding: 5 }} >
                            <Box mb={4}>
                                <ButtonContainer />
                            </Box>
                            { content && (
                                //loop through object collection and display data to edit
                                Object.keys(content).map((key, index)  => {
                                    // if(content[key].name == 'QuadMsg') {
                                    if (!content[key].hideEditor) {
                                        //set accordian height (if array has content_2 option we need a second editor)
                                        const data = content[key]['content']
                                        //check for dyanmic content with two content fields
                                        let accordianStyles = '';//classes.accordionOne;
                                        if (expanded === key) {
                                            //set double text editor vs one
                                            if (content[key].isDynamic ) {
                                                if(data[0] && data[0]['content_2'] != undefined) {
                                                    accordianStyles = classes.accordionTwo;
                                                } else {
                                                    accordianStyles = classes.accordionOneDynamic;
                                                }
                                                
                                            } else {
                                                accordianStyles = classes.accordionOne;
                                            }
                                        }
                                        // accordianStyles = (expanded === key) 
                                        //     ?  ( content[key].isDynamic && data[0] && data[0]['content_2'] != undefined
                                        //         ? classes.accordionTwo
                                        //         : classes.accordionOne 
                                        //     ) : '';
                                        return (
                                            <Box key={`editcontent-${index}`} mt={1}>
                                                <Accordion 
                                                expanded={expanded === key} 
                                                onChange={handleAccordionChange(key, data, content[key].isDynamic)} 
                                                classes={{ root: accordianStyles }}
                                                >
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography><b>{key}</b> &nbsp;{content[key].name}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {/* only load wen opened, if type is object show list options */}
                                                        {(expanded === key) &&(
                                                            //if object, map and show options
                                                            typeof content[key].content === 'object'// && content[key].content.length > 0
                                                                ? <TabView 
                                                                    index={index}
                                                                    tabsArr={data} 
                                                                    handleHtmlChange={handleHtmlChange}
                                                                    handleHtmlChange_2={handleHtmlChange_2}
                                                                    updateTab={updateTab}  
                                                                    />
                                                                : <TextEditor 
                                                                    html={data}
                                                                    handleChange={handleStaticHtmlChange}
                                                                    /> 

                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        )
                                    }
                                })
                            )}
                            
                            <Box mt={4}>
                                <ButtonContainer />
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left'
    },
    accordionOne: {
        height: "450px"
    },
    accordionOneDynamic: {
        height: "500px"
    },
    accordionTwo: {
        height: "900px"
    }
});

EditAssessmentReport.propTypes = {
    assessment: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired
}

export default EditAssessmentReport;

