import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@material-ui/core';

import TermsCard from '../../components/shared/TermsCard'
import Footer from 'src/components/shared/Footer';

const Terms = () => {
    return (
        <>
            <Helmet>
                <title>Vector Reports | Terms</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'center', my:8 }}>
                <Container maxWidth="md">
                    <TermsCard />
                </Container>
            </Box>
            <Footer />
        </>
    );
}
 
export default Terms;