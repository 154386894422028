import React, { useState, useEffect } from 'react'
import { createStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import IconChevronRight from '@material-ui/icons/ChevronRight'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'

const EvaluationSidebar  = (props) => {

    const {
        categories ,
        categoryCounter,
        processCounter,
        updateCategoryCounter,
        updateProcessCounter,
        didSubmit,
        showMainIntro,
        updateIntro
    } = props;

    const [openCategory, setOpenCategory] = useState(null)
    const [close, setClose] = useState(false)
    //const [_didSubmit, setDidSubmit] = useState(false);

    const classes = useStyles()
    const categoryBg = "#dfe7f6";
    const processBg = "#EBF0F9";
    const greyBg = "#707070";
    const redBg = "#ff3232"
    
    //update local category on back/next click
    useEffect(() => {
        setOpenCategory(categoryCounter)
        setClose(false)
    },[categoryCounter])
    
    //update local process category on back/next click
    useEffect(() => {
        setOpenCategory(categoryCounter)
    },[processCounter])

    //set index of category clicked
    const handleCategoryClick = (category) => {
        if (category === openCategory) {
            //close if value is set open (false) else open
            if(!close) {
                setClose(true)
            } else {
                updateCategoryCounter(category)
                setClose(false)
            }
        } else {
            updateCategoryCounter(category)
            setClose(false)
        }
        if(showMainIntro) {
            updateIntro(false)
        }
    }
    //set category and process on menu item click
    const handleProcessClick = (category, process) => {
        if(showMainIntro) {
            updateIntro(false)
        }
        if(category !== categoryCounter) {
            updateCategoryCounter(category)
        }
        updateProcessCounter(process)
    }
    //check to see if processes are unanswered
    const getViewStatus = (processes) => {
        let status = false;
        processes.forEach(element => {
            if (element.selection === -1) {
                status = true;
                return;
            }
        });
        return status;
    }
    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            { categories && categories.length && (
                categories.map((row, cIndex) => {
                    const showCategory = (cIndex === openCategory && !close);
                    const isCategory = (cIndex === openCategory);
                    let catColor = (getViewStatus(categories[cIndex].processes) && categories[cIndex].didView) ? redBg : '';
                    //map categories
                    return (
                        <div key={`cat-nav-${cIndex}`}>
                            <Box backgroundColor={isCategory ? categoryBg : ''}>
                                <ListItem button onClick={() => handleCategoryClick(cIndex)} className={classes.menuItem}>
                                    <ListItemText 
                                    primary={<Typography style={{ fontSize: 19, color: catColor }} >{row.category}</Typography>}  />
                                    {showCategory ? <IconExpandMore /> : <IconChevronRight />}
                                </ListItem>
                            </Box>
                            {/* map processes */}
                            <Collapse in={showCategory} timeout="auto" unmountOnExit >
                                <Divider /> 
                                <List component="div" disablePadding>
                                    {row.processes.length && (
                                        row.processes.map((process, pIndex) => {
                                            const isProcess = (pIndex === processCounter);
                                            //highlighted vs selected level
                                            const backgroundColor=(isCategory && isProcess) ? processBg : '';
                                            const fontWeight=(isCategory && isProcess) ? 'bold' : '';
                                            const selection = (categories[cIndex].processes[pIndex].selection)
                                            //grey bg if level is selected
                                            let color = (selection > -1 ) ? greyBg : '';
                                            //if submitted and unselected show red
                                            color = (categories[cIndex].didView && selection === -1 ) ? redBg : color;

                                            return (
                                                <Box   
                                                    sx={{backgroundColor}} 
                                                    key={`cat-nav-${pIndex}`} >
                                                    <ListItem 
                                                        button 
                                                        className={classes.menuItem} 
                                                        onClick={() => handleProcessClick(cIndex, pIndex)} >
                                                        <ListItemText 
                                                            inset 
                                                            // primary={process.menuName} 
                                                            primary={<Typography style={{ fontWeight, color, fontSize: 18 }} ml={-3}>{process.menuName}</Typography>} />
                                                    </ListItem>
                                                </Box>
                                            )
                                        })
                                    )}
                                </List>
                            </Collapse>
                        </div>
                    )
                })
            )}
        </List>
    )
}

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
            paddingLeft: 30,
            marginLeft: 30,
        },
        menuItemIcon: {
            color: '#97c05c',
        },
    }),
)

EvaluationSidebar.propTypes = {
    categories: PropTypes.array,
    categoryCounter: PropTypes.number,
    processCounter: PropTypes.number,
    updateCategoryCounter: PropTypes.func,
    updateProcessCounter: PropTypes.func,
    didSubmit: PropTypes.bool,
    showMainIntro: PropTypes.bool,
    updateIntro: PropTypes.func,
};
export default EvaluationSidebar;
