import { Box, Typography } from '@material-ui/core';

const BlockContainer = ({ header, subheader, children }) => {
  return (
    <Box sx={{padding: 3, marginTop: 4}}>
      { header &&
        <Typography color="textPrimary" gutterBottom variant="h4">
          { header }
        </Typography>
      }
      { subheader &&
        <Typography color="textPrimary" gutterBottom  variant="body2" mt={2}>
          { subheader }
        </Typography>
      }
      { children }
    </Box>
  )
}

export default BlockContainer;