import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getOwnershipData, updateOwnership } from 'src/redux/thunks';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Toolbar, TableSortLabel, Collapse } from '@material-ui/core';

import CustomButton from 'src/components/shared/CustomButton';
import UpdateUserForm from 'src/components/shared/UpdateUserForm';
import TableSpinner from 'src/components/shared/TableSpinner';
import HeaderButtons from 'src/components/shared/HeaderButtons';
import Footer from 'src/components/shared/Footer';

const TransferOwnership = (props) => {
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('desc');
    const [assessment, setAssessment] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    //redux variables
    const {
        userInfo,
        ownershipData,
        isUpdatingOwner,
        didUpdateOwnerFail,
        updateOwnerMessage,
        didOwnerUpdatePass,
        isOwnershipLoading,
        getList,
        transferOwnership
    } = props;

    useEffect(() => {
        //get purchase history: on refresh, on nav click
        getData();
        //close form
        if (didOwnerUpdatePass) {
            setAssessment(null);
            setOpen(false);
        }
    }, [didOwnerUpdatePass])

    useEffect(() => {
        if (ownershipData) {
            let _data = ownershipData.filter(assessment => {
                return assessment.assessor.email === ''
            })
            setData(_data)
        }
    }, [ownershipData])

    //dispatch ownership data
    const getData = () => {
        getList(userInfo.email);
    }

    const handleRequestSort = (property) => {
        setOrderBy(property);
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const handleOwnerUpdate = (row, info) => {
        if (info.email === userInfo.email) {
            alert('You are already the owner')
            return
        }
        transferOwnership(row?.accessCode, info, userInfo.email);
    }

    const handleCustomClick = (row, buttonTitle) => {
        if (buttonTitle == 'Cancel') {
            //reset local state after Cancel is pressed 
            setAssessment(null);
        } else if (buttonTitle === 'Undo') {
            transferOwnership(
                row?.accessCode, userInfo, row.owner.email, true
            );
        } else {
            setAssessment(row);
        }
        setOpen(!open);
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | Transfer</title>
            </Helmet>
            <Box my={8} mx={4} sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" style={{ fontWeight: 600 }}>
                        Transfer Ownership
                    </Typography>
                    <HeaderButtons />
                </Container>
                {/* Table */}
                <Box mt={2}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <Toolbar>
                                <Typography variant="h4" id="ownershipLabel" color="textSecondary" >
                                    You may completely relinquish an assessment to another person.
                                </Typography>
                            </Toolbar>
                            <TableContainer sx={{ pl: 1.2 }}>
                                <Table aria-label="ownershipTable" size={'medium'}>
                                    {/* Table Header - Sort */}
                                    <TableHead>
                                        <TableRow >
                                            {
                                                headCells.map((headCell) => {
                                                    return (
                                                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                                            {
                                                                headCell.id !== 'ownerEmail'
                                                                    ? <TableSortLabel
                                                                        active={orderBy === headCell.id}
                                                                        direction={orderBy === headCell.id ? order : 'asc'}
                                                                        onClick={() => handleRequestSort(headCell.id)}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableSortLabel>
                                                                    : headCell.label
                                                            }
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {/* Table Body */}
                                    <TableBody>
                                        {data && data.length > 0 ? (
                                            stableSort(data, getComparator(order, orderBy))
                                                .map((row, index) => {
                                                    const isOwner = !row.proposedOwner ? row?.owner?.email === userInfo?.email : false
                                                    const ownerIsPurchaser = row.owner?.email == row.purchaserEmail
                                                    const isPromoCode = row.purchaserEmail == 'adminpurch@vectorreports.com'
                                                    const key = `owner-${index}`;
                                                    const key2 = `owner-${index}-cllps`;
                                                    const key3 = `assign-assessor-${index}-frag`;
                                                    const assessorText = row?.assessor?.email ? row?.assessor?.email : "None";
                                                    const assessorName = row?.assessor?.name ? row?.assessor?.name : 'None';
                                                    const statusColor = isOwner ? '#4caf50' : 'red'
                                                    const statusWeight = row?.status == 'Available' ? '600' : ''
                                                    let buttonTitle = isOwner ? "Select" : "Undo";
                                                    buttonTitle = (row?.displayCode != assessment?.displayCode) ? buttonTitle : "Cancel";
                                                    let buttonColor = isOwner ? "primary" : "#ff9100";
                                                    let buttonHover = isOwner ? "" : "#b26a00";
                                                    //make red if cancel
                                                    buttonColor = (row?.displayCode != assessment?.displayCode) ? buttonColor : "#f44336";
                                                    buttonHover = (row?.displayCode != assessment?.displayCode) ? buttonHover : "#ba000d";
                                                    if (((assessment && row?.displayCode == assessment?.displayCode) || !assessment) && !row?.isCompleted) {
                                                        // if(!assessment){
                                                        //console.log( row?.entityName , userInfo )
                                                        return (
                                                            <React.Fragment key={key3}>
                                                                <TableRow key={key}>
                                                                    <TableCell sx={{ color: statusColor }}>
                                                                        {isOwner ? 'Available' : 'Pending'}
                                                                    </TableCell>
                                                                    <TableCell sx={{ color: statusColor }}>{isPromoCode && !row?.previousOwner ? "VR Promo" : ownerIsPurchaser ? row?.owner?.email : row?.previousOwner}</TableCell>
                                                                    <TableCell sx={{ color: statusColor }}>{row?.proposedOwner}</TableCell>
                                                                    <TableCell sx={{ color: statusColor }}>{assessorName}</TableCell>
                                                                    <TableCell sx={{color:statusColor}}>{row?.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                                                    <TableCell sx={{ color: statusColor }}>{row?.displayCode}</TableCell>
                                                                    <TableCell sx={{ color: statusColor }}>
                                                                        <CustomButton
                                                                            minWidth={160}
                                                                            buttonTitle={buttonTitle}
                                                                            backgroundColor={buttonColor}
                                                                            hoverColor={buttonHover}
                                                                            handleClick={() => handleCustomClick(row, buttonTitle)}
                                                                            disabled={open && row?.displayCode != assessment?.displayCode}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {row?.displayCode == assessment?.displayCode && (
                                                                    <TableRow key={key2}>
                                                                        <TableCell colSpan={6}>
                                                                            <Collapse in={open} timeout="auto" unmountOnExit >
                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                    <UpdateUserForm
                                                                                        role="Owner"
                                                                                        assessment={assessment}
                                                                                        handleSubmit={handleOwnerUpdate}
                                                                                        didUpdateFail={didUpdateOwnerFail}
                                                                                        errorMessage={updateOwnerMessage}
                                                                                        isLoading={isUpdatingOwner}
                                                                                        didUpdateSucceed={didOwnerUpdatePass}
                                                                                        showEntity={true}
                                                                                    />
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })
                                        ) : (
                                            <TableSpinner isLoading={isOwnershipLoading} colSpan={headCells.length} />
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Container>
                    <Container maxWidth='lg'>
                        {didOwnerUpdatePass && (
                            <Typography color="#0bd17d" pt={2} fontSize={15} >
                                Owner Updated
                            </Typography>
                        )}
                    </Container>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

const headCells = [
    { id: 'status', numeric: false, label: 'Status' },
    { id: 'from', numeric: false, label: 'From' },
    { id: 'currentOwnerEmail', numeric: false, label: 'Proposed Owner' },
    { id: 'assessorName', numeric: false, label: 'Assigned Assessor' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'displayCode', numeric: false, label: 'Code' },
    { id: 'transfer', numeric: false, label: 'Action' },
];

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    ownershipData: state.assessment.ownershipData,
    isUpdatingOwner: state.assessment.isUpdatingOwner,
    didUpdateOwnerFail: state.assessment.didUpdateOwnerFail,
    didOwnerUpdatePass: state.assessment.didOwnerUpdatePass,
    updateOwnerMessage: state.assessment.updateOwnerMessage,
    isOwnershipLoading: state.assessment.isOwnershipLoading,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getOwnershipData(email)),
    transferOwnership: (accessCode, info, assessor, undo) => dispatch(updateOwnership(accessCode, info, assessor, undo))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnership);