export const SET_PAYMENT_SESSION = 'SET_PAYMENT_SESSION';
export const setPaymentSession = session => {
    return {
        type: SET_PAYMENT_SESSION,
        session
    }
}

export const REMOVE_PAYMENT_SESSION = 'REMOVE_PAYMENT_SESSION'
export const removePaymentSession = () => {
    return {
        type: REMOVE_PAYMENT_SESSION
    }
}