import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { makeStyles } from '@material-ui/styles'

import PageHeader from './PageHeader';

const list = () => (
    [
        {
            text: "Focus upon speed and working on the right things first",
        },
        {
            text: "Continuous measurable improvement and process waste reduction",
        },
        {
            text: 'Aim for balanced and aligned process maturities '
        },
        {
            text: 'Help assessed organizations “test out” and grow out of the VIA Assessment'
        }
    ]
)

const list2 = () => (
    [
        {
            text: 'If no, then congratulations, and confidently return to your pursuits!'
        },
        {
            text: 'If yes, then Vector Reports&trade; encourages you to start an immediate conversation about making change. Talk about your understandings of current-state conditions with all your team members. Think back on the reasons you, as the single assessor or as a member of an assessor team, chose those particular ratings and then ask these two questions to start conversations:'
        }
    ]
)

const subList = () => (
    [
        {
            subheader: true,
            text: 'How did we see ourselves before taking the assessment?'
        },
        {
            subheader: true,
            text: 'How do we see things now, after reading the assessment results?'
        }
    ]
)

const ClosingAdvicePage2 = (props) => {

    const {

    } = props;

    const classes = useStyles();

    return (
        <>
            {/* header */}
            <PageHeader />
            {/* first content block */}
            <Box mt={4}>

                {list().map((item, index) => {
                    return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ mr: 2 }} variant="body1"> • </Typography>
                        {Object.keys(item).map(key => (
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                style={{ fontSize: 20 }}
                                dangerouslySetInnerHTML={{ __html: item[key] }}>
                            </Typography>
                        ))}

                    </Box>
                })}
            </Box>

            {/* second content block */}
            <Box mt={4}>
                <Typography variant="body1" style={{ fontSize: 20 }} >
                    After you have read your Report, ask yourself: <i>“Do the results suggest you have process immaturities?”</i>
                </Typography>

                <Box mt={2}>
                    {list2().map((item) => {
                        const style = {
                            display: 'flex',
                            mb: 0.5,
                            ml: item.subheader ? 6 : 0,
                            mt: 2
                        }
                        return <Box sx={style}>
                            <Typography sx={{ mr: 2 }} variant="body1"> {item.subheader ? '⚬' : '•'} </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                style={{ fontSize: 20 }}
                                dangerouslySetInnerHTML={{ __html: item.text }}>
                            </Typography>
                        </Box>
                    })}

                    <Box mt={3}>
                        {subList().map((item) => {
                            const style = {
                                display: 'flex',
                                mb: 0.5,
                                ml: 6,
                            }
                            return <Box sx={style}>
                                <Typography sx={{ mr: 2 }} variant="body1"> ⚬ </Typography>
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    style={{ fontSize: 20 }}
                                    dangerouslySetInnerHTML={{ __html: item.text }}>
                                </Typography>
                            </Box>
                        })}
                    </Box>

                </Box>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    The information in your Report is about level-setting among your team as well as creating alignment and balance in your organization—laying a foundation for future success. Let low scores or imbalances in the results serve as an “attention-focusing device” for change.
                </Typography>


                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    <i>A rising tide lifts all boats</i>—as an analogy, imagine your ship of business sailing along the tops of your business process score bar charts from left to right. Is it a smooth and level ride or a bumpy one (high waves, dips, slopes, or drop offs)? Continuing the analogy, the low score processes are like heavy anchors tied to sea floor and when a rising tide of business opportunity comes it will sink the ship itself because it cannot rise with the opportunity.
                </Typography>
            </Box>

            {/* second content block */}
            <Box mt={4}>
                <Typography variant="h5" style={{ fontSize: 20, textAlign: 'center' }} >
                    Have a bias to Action
                </Typography>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    Change can be challenging.
                </Typography>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    Your report results may seem negative or a bit overwhelming. Be comforted that almost every small business that has come before you have likely trod this path. Be encouraged that these challenges can be overcome by smart, cogent, consistent and visible action on your part.
                </Typography>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    Make change on your own by following the recommendations. Or, if you elect to get a mentor, consultant or coach, this report can help you select the right business mentor or consultant by identifying areas of expertise they should possess that tie to your needs. And, this Report helps you save time and money by getting that help up to speed faster than going through another discovery process.
                </Typography>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    The last message is time ― and this report is a reminder to you to get going ― don't let this report sit on a shelf and collect dust. Use it. Challenge it. Make change now! Your competitors are not waiting.
                </Typography>

                <Typography mt={4} variant="body1" style={{ fontSize: 20 }} >
                    Best wishes to you on your change efforts.
                </Typography>
            </Box>

            <Box mt={14} className={classes.centeredColumn} >
                <img width="350px" alt="Logo" src="/static/images/VIA-logo.png" />
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
    whiteBanner: {
        borderBottom: '3px solid black',
        borderTop: '3px solid black',
    },
})

export default ClosingAdvicePage2;

