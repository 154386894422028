import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { verifyAuth } from "./thunks";
import rootReducer from "./reducers";



//DEV MODE - comment for builds

// const persistConfig = {
//     key: 'root',
//     storage,
// }
// const persistedReducer = persistReducer(persistConfig, rootReducer)

//returns configured store, will be called when app launches and browser refreshes
// export default function configureStore(persistedState) {
//     const store = createStore(
//         persistedReducer,
//         persistedState,
//         composeWithDevTools(applyMiddleware(thunkMiddleware)),
//     );
//     const persistor = persistStore(store);
//     //ensure auth verification is first action in redux store
//     const inDevMode = store.getState().auth.inDevMode
//     //console.log('state: ', state)
//     store.dispatch(verifyAuth(inDevMode));
//     return { store, persistor };
// }

//RELEASE MODE - uncomment for builds

//returns configured store, will be called when app launches and browser refreshes
export default function configureStore() {
    const store = createStore(
        rootReducer,
        //persistedState,
        composeWithDevTools(applyMiddleware(thunkMiddleware)),
    );
    //ensure auth verification is first action in redux store
    //store.dispatch(verifyAuth());
    return { store };
}