import React, { useState, useEffect } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import PropTypes from "prop-types";

import { Box } from '@material-ui/core';

const TextEditor = (props) => {
    const { 
        html,
        handleChange,
        blur 
    } = props;

    const { 
        quill,
        quillRef 
    } = useQuill();

    // console.log('quill : ', quill);    // undefined > Quill Object
    // console.log(quillRef); // { current: undefined } > { current:

    useEffect(() => {
        if(quill) {
            //paste existing html
            if(html) {
                quill.clipboard.dangerouslyPasteHTML(html);
            } else {
                quill.clipboard.dangerouslyPasteHTML('')
            }
            //on change handler
            quill.on('text-change', (delta, oldDelta, source) => {
                handleChange(quill.root.innerHTML)
            });
            
            if (blur) quill.blur();
        }
    })

    return (
        <Box style={{ height: 300 }}>
            <div ref={quillRef} />
        </Box>
    )
}

TextEditor.propTypes = {
    html: PropTypes.string,
    handleChange: PropTypes.func,
    blur: PropTypes.bool,
}

export default TextEditor;