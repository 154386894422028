
import React, { useState, useEffect } from 'react'
//redux
import { connect  } from 'react-redux';
import { getGridImage } from 'src/redux/thunks';
import { editReport } from 'src/redux/actions';
//ui
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

const PlotImage = (props) => {
 
    //redux
    const {
        finalEvaluation,
        getGridImage,
        graphic,
    } = props;

    //variables
    const classes = useStyles();

    //get grid image
    useEffect(() => {
        if (finalEvaluation) {
            getGridImage(finalEvaluation)
        }
    },[])

    return (
        <>
            {graphic && (
                <Box id="plotImage" className={classes.container}> 
                    <Box className={classes.backgroundImage}>
                        <img width="100%" src="/static/images/vsba/VSBA-Plot-Background-transparent.png" id="vector-graphic" />
                    </Box>
                    <Box className={classes.foregroundImage}>
                        <img width="65%" src={graphic} id="vector-graphic" />
                    </Box>
                </Box>
            )}
        </>
    )
}


const useStyles = makeStyles({
    container: {
        minHeight: 606.9
    },
    backgroundImage : {
        zIndex: 20,
        position: 'relative'
    },
    foregroundImage: {
        zIndex: 1,
        position: 'relative',
        marginLeft: '25%',
        marginTop: '-57.5%',
    }
});

const mapStateToProps = state => ({
    finalEvaluation: state.takingAssessment.finalEvaluation,
    graphic: state.takingAssessment.graphic,
})

const mapDispatchToProps = dispatch => ({
    getGridImage: (params) => dispatch(getGridImage(params)),
    editReport: () => dispatch(editReport())
})

export default connect(mapStateToProps, mapDispatchToProps)(PlotImage);