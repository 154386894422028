

import React, { useState, useEffect } from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import PageHeader from './PageHeader';

const InterpretingPlotPage = (props) => {

    const {
        finalEvaluation,
        assessorData
    } = props;

    const [sizeMsg, setSizeMsg] = useState('');
    const [centerMsg, setCenterMsg] = useState('');
    const [shapeMsg, setShapeMsg] = useState('');

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''
    const ratioLim = 1.4;
    const askewLim = 3;

    const plotSize = {
        zone1: 'You have at least one set of ratings in the small or “Premature Zone.” Unless you are truly a startup and are just beginning your business development journey, this zone suggests you likely will be tripped up, stopped in your tracks, and may be unpleasantly surprised unless you rapidly establish or significantly improve your vulnerable Category processes. Time is of the essence for action.',
        zone2: 'Your results indicate that you have some ratings in the medium or “Developmental zone.” This zone suggests you have some process abilities, but you may end up hindering future success by lack of process maturities in those Categories (unreliable, incomplete, inadequate). Unless you focus your efforts to improve those processes you will likely experience unexpected and unfortunate surprises.',
        zone3: 'Your results indicate that you have all ratings in the larger or “Foundational” Zone.” This zone indicates you have influential and other processes in place that can be utilized to fine tune your responses to opportunities at hand. This zone means you are building upon your performance capabilities, but don\'t feel like "you\'ve made it." because the ideal result is a fully rendered plot',
    }
    const plotCenteredness = {
        centerMsg: 'Your plot results indicate all four colors are of equal size which means your process strengths are centered and balanced (which is desired).  It implies that you have multi-process capabilities to address most anything that might show up.  The exception would be indicated by the maturity zone of your color plots (Premature, Developmental, or Foundational). Your Report will provide improvement suggestion(s) about what to work on next.',
        mostlyCenterMsg: 'Your plot results indicate that all four colors are about the same size indicating your process strengths are supporting all four capabilities more or less equally—a good result, as the journey to growing in a more-centered, balanced manner is not too far away.  When a “mostly balanced” result occurs, the next step is to next check the size of each color by Zone (Premature, Developmental or Foundational) which identifies the Category of business processes to emphasize while you continue your improvement journey.',
        // offCenterMsg : 'Your plot results indicate that all four colors are NOT the same size—the maturities of your business processes are unequally developed and imbalanced. When imbalances are large it suggests there are issues present preventing the development of full capabilities. This can create risks to the success of your team\'s or organization\'s endeavors. Later, in your Report, you will find Category(s) of business processes you must address on your improvement journey.',
        nwBig: 'Your plot results indicate that all four colors are NOT the same size—the maturities of your business processes are unequally developed and imbalanced away from the “Capability to create a business model.” When imbalances are large it suggests there are issues present preventing the development of full capabilities.  This can create risks to the success of your team\'s or organization\s endeavors.  Later, in your Report, you will find Category(s) of business processes you must address on your improvement journey.',
        swBig: 'Your plot results indicate that all four colors are NOT the same size—the maturities of your business processes are unequally developed and imbalanced away from the “Capability to develop an offering.” When imbalances are large it suggests there are issues present preventing the development of full capabilities.  This can create risks to the success of your team\'s or organization\'s endeavors.  Later, in your Report, you will find Category(s) of business processes you must address on your improvement journey.',
        seBig: 'Your plot results indicate that all four colors are NOT the same size—the maturities of your business processes are unequally developed and imbalanced away from the “Capability to respond to market demand.” When imbalances are large it suggests there are issues present preventing the development of full capabilities.  This can create risks to the success of your team\'s or organization\'s endeavors.  Later, in your Report, you will find Category(s) of business processes you must address on your improvement journey.',
        neBig: 'Your plot results indicate that all four colors are NOT the same size—the maturities of your business processes are unequally developed and imbalanced away from the “Capability to generate revenue.” When imbalances are large it suggests there are issues present preventing the development of full capabilities.  This can create risks to the success of your team\'s or organization\'s endeavors.  Later, in your Report, you will find Category(s) of business processes you must address on your improvement journey.'
    }
    const plotShape = {
        horzRecMsg: 'Your plot has an oblong shape, more like a horizontal rectangle than a square. This shape hints that there is much more active learning and data collections from external sources (customers and market forces), and perhaps more comfort with, or a bias for, activities that are more fluid and dynamic and less in your direct control. Yet, your success is also dependent upon internal controls of your organizational and design processes.',
        vertRecMsg: 'Your plot has an oblong shape, more like a vertical rectangle than a square. This shape hints that there is more internal and operational process maturity, and perhaps more comfort with or a bias for, activities that are within your span of control. Yet, your success is also dependent upon external entities, customers, market forces, and more that are typically more dynamic, fluid and mostly out of your direct control.',
        biasUnclearMsg: 'Sometimes rankings produce an oblong shape, either vertical rectangle or horizontal rectangle. However, your rankings show this is not the case and you have more of a balanced set of process maturities. This means you do not have a noticeable bias towards either internally (things you control) or externally (things you influence) focused processes.',
    }

    useEffect(() => {
        setSizeMessage();
        setCenterMessage();
        setShapeMessage();
    }, [])

    //get lowest average and select appropriate message
    const setSizeMessage = () => {
        let scores = [];
        let message = '';

        try {
            finalEvaluation.forEach(element => {
                scores.push(element.average)
            })

            scores = scores.sort(function (a, b) { return a - b });

            if (scores[0] >= 2) {
                message = plotSize.zone3;
            } else if (scores[0] >= 1) {
                message = plotSize.zone2;
            } else if (scores[0] < 1) {
                message = plotSize.zone1;
            }

            setSizeMsg(message);
        } catch (e) {
            console.log(' error: ', e.message)
        }

    }

    //calculate centerdness of plot å
    const setCenterMessage = () => {

        let N = 0;
        let E = 0;
        let S = 0;
        let W = 0;

        try {

            finalEvaluation.forEach(category => {
                //assign axis averages to variables
                if (category.axis == 'North') {
                    N = category.rawSum;
                } else if (category.axis == 'East') {
                    E = category.rawSum;
                } else if (category.axis == 'South') {
                    S = category.rawSum;
                } else if (category.axis == 'West') {
                    W = category.rawSum;
                }
            })

            if (N === W && W === S && S === E) {
                //all equal
                setCenterMsg(plotCenteredness.centerMsg);
            } else if ((N * W) > (askewLim * S * E)) {
                //nwbig
                setCenterMsg(plotCenteredness.nwBig);
            } else if ((W * S) > (askewLim * E * N)) {
                //swbig
                setCenterMsg(plotCenteredness.swBig);
            } else if ((S * E) > (askewLim * W * N)) {
                //sebig
                setCenterMsg(plotCenteredness.seBig);
            } else if ((E * N) > (askewLim * S * W)) {
                //nebig
                setCenterMsg(plotCenteredness.neBig);
            } else {
                // mostly centered #2
                setCenterMsg(plotCenteredness.mostlyCenterMsg);
            }

        } catch (e) {
            console.log('setCenterMessage error: ', e.message)
        }

    }

    //calculate shape
    const setShapeMessage = () => {

        let N = 0;
        let E = 0;
        let S = 0;
        let W = 0;

        try {
            finalEvaluation.forEach(category => {
                //assign axis averages to variables
                if (category.axis == 'North') {
                    N = category.average;
                } else if (category.axis == 'East') {
                    E = category.average;
                } else if (category.axis == 'South') {
                    S = category.average;
                } else if (category.axis == 'West') {
                    W = category.average;
                }
            })

            //determine shape
            if ((E + W) > (ratioLim * (N + S))) {
                setShapeMsg(plotShape.horzRecMsg);
            } else if ((N + S) > (ratioLim * (E + W))) {
                setShapeMsg(plotShape.vertRecMsg);
            } else {
                setShapeMsg(plotShape.biasUnclearMsg);
            }

        } catch (e) {
            console.log('setShapeMessage error: ', e.message)
        }

    }

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Interpreting your Capabilities Maturity Plot
                </Typography>
            </Box>

            {/* banner */}
            <Box className={classes.brownBanner} py={0.5} mt={1}>
                <Typography variant="body1" style={{ fontSize: 18 }} m={1}>
                    <i>The shape of your plot result provides a visual indicator of your capability maturity status along with high level information about improvement opportunities.</i>
                </Typography>
            </Box>

            {/* static content */}
            <Box mt={6}>
                <Typography variant="body1" style={{ fontSize: 18 }}>
                Your Plot on the previous page includes all four quadrant colors together as a rectangle. Look at the size, centerdness and shape.
                </Typography>
            </Box>
            <Box mt={6}>
                <Typography gutterBottom variant="h4">
                    Size of your plot
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                    The bigger the better. There are three Zones; Premature, Developmental, and Foundational.
                </Typography>
                {/*  bullet list */}
                <Box mt={4} ml={6} className={classes.flexStart}>
                    <ul>
                        <li>
                            <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                                <i>Premature</i> zone represents interacting processes that are insufficient or are inadequate.
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                                <i>Developmental</i> zone represents that most processes exist (but not all) and, many may be somewhat unreliable as they work together.
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                                <i>Foundational</i> zone represents that most processes exist and interact in a dependable way.
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Box>

            {/* Dynamic Content */}
            <Box>
                {/* size message */}
                <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={2}>
                    {sizeMsg}
                </Typography>
                <Typography gutterBottom variant="h4" mt={6}>
                    Centeredness of your plot area as a square
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                    {centerMsg}
                </Typography>
                <Typography gutterBottom variant="h4" mt={6}>
                    Shape of your plot as a vertical versus horizontal rectangle
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} ml={4} mt={4}>
                    {shapeMsg}
                </Typography>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
});

const mapStateToProps = state => ({
    finalEvaluation: state.takingAssessment.finalEvaluation,
    userInfo: state.auth.userInfo,
    assessorData: state.takingAssessment.assessorData,
})

export default connect(mapStateToProps)(InterpretingPlotPage);
