import React from 'react'
import { motion, AnimatePresence } from "framer-motion"

const styles = {
    container: {
        paddingTop: '3em',
        minWidth: 300,
        margin: 'auto',
    },
    title: {
        fontSize: '1.6em'
    },
    nav: {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100%',
        zIndex: 1200,
        backgroundColor: 'white',
        overflow: 'auto',
        height: '100vh'
    },
    content: {
        marginTop: '1.64em',
        display: 'flex'
    }
}

const PageOverlay = ({ isOpen, toggleOpen, children, title, width }) => {
    return (
        <>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        style={styles.nav}
                        key="content"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "anticipate", duration: 0.4 }}
                        exit={{ opacity: 0 }}
                    >
                        <div style={{ ...styles.container, maxWidth: width ? width : '60%' }}>
                            <span style={styles.title}> {title} </span>
                            {children}
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </>
    )
}

export default PageOverlay