
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Typography, TextField, Button, InputLabel, FormControl, Select, MenuItem, Slider} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import ProcessNotes from './ProcessNotes';

const Process = (props) => {

    //props
    const {
        name,
        description,
        maturityLevels,
        category,
        selection,
        handleSelection,
        categoryIndex,
        processIndex,
    } = props;

    //state
    const [level, setLevel] = useState(-1);

    //variables
    const classes = useStyles();
    const levelBG = [
        '/static/images/3graphic.png',
        '/static/images/2graphic.png',
        '/static/images/1graphic.png',
        '/static/images/0graphic.png',
    ];

    //updates state based on level
    useEffect(() => {
        setLevel(selection);
    },[selection])

    //event handlers
    const handleSelectChange = (event) => {
        setLevel(event.target.value);
        handleSelection(event.target.value);
    }
    const handleLevelClick = (level) => {
        setLevel(level);
        handleSelection(level);
    }
    const handleSliderChange = (event, newValue) => {
        //doesnt update redux to remain flui
        setLevel(newValue);
    }
    const handleSliderChangeCommitted = (event, newValue) => {
        setLevel(newValue);
        handleSelection(newValue);
    }

    //loops through maturity levels and assigns dynamic styling and content
    const Levels = () => (
        <Box ml={4} width="75%">
            {/* headers */}
            <Box className={classes.maturityHeaderCointainer}>
                <Typography color="textSecondary" gutterBottom variant="body1" style={{ fontSize: 17 }} ><i>Maturity level descriptions</i></Typography>
                <Typography color="textSecondary" gutterBottom variant="body1" style={{ fontSize: 17 }} ><i>Select Level</i></Typography>
            </Box>
            {/* rows */}
            {maturityLevels.map((row, index) => {
                const key = `descriptors-${index}`;
                //set styles (non selected vs selected)
                const backgroundContainer = (row.level > level) ? classes.maturityCointainer: classes.highlightedMaturityCointainer;
                const levelContainer = (row.level > level) ? classes.greyMaturity : classes.whiteMaturity;
                //map rows
                return (
                    <Box key={key} className={backgroundContainer} p={1}>
                        <Box className={levelContainer} pl={1} pt={.75}>
                            <Typography color="textPrimary" style={{ fontSize: 18 }} >{row.description}</Typography>
                        </Box>
                        <Box sx={{width: 70,cursor: 'pointer' }} ml={5} mb={-1}>
                            <img
                                height="45px"
                                src={levelBG[index]}
                                sx={{cursor: 'pointer' }}
                                onClick={() => handleLevelClick(row.level)}
                            />
                        </Box>
                    </Box>
                )
            })}
        </Box>
    );

    const NotesAndInput = () =>(
        <Box mt={2} className={classes.notesInputContainer}>
            <Typography color="textSecondary" gutterBottom variant="body2" width={60} mt={4} mr={5} ml={2} style={{ fontSize: 16 }} >
                <i>Slide Pointer to Level Rating</i>
            </Typography>
            <Box mr={4} width='63%'>
                <ProcessNotes
                    categoryIndex={categoryIndex}
                    processIndex={processIndex}
                    />
            </Box>
            <Box width="12.5%" ml={1.6}>
                <FormControl >
                    <Select
                        labelId="level-label"
                        id="level-select"
                        value={level >= 0 ? level : ''}
                        label="Level"
                        onChange={handleSelectChange}
                        className={classes.select}
                    >
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={0}>0</MenuItem>
                    </Select>
                </FormControl>
                <Typography color="textSecondary" gutterBottom variant="body2" width={50} ml={.5} mt={.5} style={{ fontSize: 17 }} ><i>Enter Level Rating</i></Typography>
            </Box>
        </Box>
    );

    return (
            <Box pl={8} pr={10} className={classes.container}>
                {/* header and description */}
                <Box pt={4} mx={4} pr={4}>
                    <Typography color="textPrimary" gutterBottom variant="h2" >{category}</Typography>
                    <Box mt={2}>
                        <Typography color="textSecondary" gutterBottom variant="body1" style={{ fontSize: 17 }} ><i>Process Name</i></Typography>
                        <Typography color="textPrimary" gutterBottom variant="h3" >{name}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography color="textSecondary" gutterBottom variant="body1"  style={{ fontSize: 17 }} ><i>Statement Description</i></Typography>
                        <Typography color="textPrimary" style={{ fontSize: 18 }}  dangerouslySetInnerHTML={{ __html: description }}></Typography>
                    </Box>
                </Box>
                {/* slider and maturity levels */}
                <Box mt={4} ml={2} className={classes.sliderLevelsContainer}>
                    <Box sx={{ height: 240 }} spacing={1} direction="row" mt={8} mr={4} mb={-4}>
                        <Slider key="slider" aria-label="thumb " name='slider'
                            id='slider'
                            value={level}
                            defaultValue={0}
                            orientation="vertical"
                            step={1}
                            min={-1}
                            max={3}
                            onChangeCommitted={handleSliderChangeCommitted}
                            onChange={handleSliderChange}
                            className={classes.slider}
                        />
                    </Box>
                    <Levels />
                </Box>
                {/* notes and input */}
                <NotesAndInput />
            </Box>
    );
}


const useStyles = makeStyles({
    container: {
        maxWidth: 1250
    },

    typography: {
        body2: {
            fontSize: [18, "!important"]
        }
    },
    maturityHeaderCointainer: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, marginRight: 10,
    },
    maturityCointainer: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight:60,
    },
    highlightedMaturityCointainer: {
        display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight:60, backgroundColor: '#98B1D1'
    },
    greyMaturity: {
        backgroundColor:"#DEDEDE", width: '100%',  alignItems: 'center', border: '2px solid #BCBFC6',
    },
    whiteMaturity: {
        backgroundColor:"white", width: '100%',  alignItems: 'center', border: '2px solid #BCBFC6',
    },
    notesInputContainer: {
        display: 'flex', justifyContent: 'flex-start'
    },
    sliderLevelsContainer: {
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'
    },
    select: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        width: 46,
        height: 46,
        backgroundColor: 'white',
        fontWeight: 1000,
        '& div': {
            paddingTop:10,
            paddingLeft:5,
            fontSize: 20,
            color:"#666",
            fontWeight: '500',
        }
    },
    slider: {
        "& .MuiSlider-thumb": {
            height: 0,
            width: 0,
            backgroundColor: 'transparent',
            borderRadius: "0px",
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent',
            borderLeft: '35px solid #2196f3',
            marginLeft: -12,
            "&:focus, &:hover, &:active": {
                boxShadow:'none !important'
            },
          },
        '& .MuiSlider-thumb.Mui-focusVisible': {
            boxShadow:'none !important'
        }
    }
});

Process.propTypes = {
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    maturityLevels: PropTypes.array.isRequired,
    selection: PropTypes.number.isRequired,
    handleSelection: PropTypes.func.isRequired,
    categoryIndex: PropTypes.number,
    processIndex: PropTypes.number,
}

export default Process;
