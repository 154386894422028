
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Typography, TextField, Button, Link, TextareaAutosize  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import * as Yup from 'yup';
import { Formik } from 'formik';


const Contact = () => {
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>Vector Reports | Contact</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'center' }} >
                <Container  maxWidth="sm">
                    <Formik
                        initialValues={{
                            topic: '',
                            ccEmails: '',
                            message: ''
                        }}
                        validationSchema={Yup.object().shape({
                            topic: Yup.string().max(200).required('Topic is required'),
                            ccEmails: Yup.string(),
                            message: Yup.string().required('Message is required')
                        })}
                        onSubmit={(data) => {
                            //open mail client
                            window.location = `mailto:wmpalmer91@gmail.com?cc=${data.ccEmails}&subject=${data.topic}&body=${data.message}`;
                            e.preventDefault();
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <Card >
                                <CardContent >
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ mb: 3 }}>
                                            <Typography color="textPrimary" variant="h2" >
                                                Contact Us
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom variant="body2" >
                                                We want to hear from you!
                                            </Typography>
                                        </Box>
                                        <TextField
                                            error={Boolean(touched.topic && errors.topic)}
                                            fullWidth
                                            helperText={touched.topic && errors.topic}
                                            label="Topic"
                                            placeholder="Write topic"
                                            margin="normal"
                                            name="topic"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.topic}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(touched.ccEmails && errors.ccEmails)}
                                            fullWidth
                                            helperText={touched.ccEmails && errors.ccEmails}
                                            label="Cc"
                                            placeholder="Add comma separated emails"
                                            margin="normal"
                                            name="ccEmails"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.ccEmails}
                                            variant="outlined"
                                        /> 
                                        <TextField
                                            error={Boolean(touched.message && errors.message)}
                                            fullWidth
                                            helperText={touched.message && errors.message}
                                            //id="outlined-textarea"
                                            label="Message"
                                            placeholder="Write message"
                                            margin="normal"
                                            name="message"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.message}
                                            multiline
                                            variant="outlined"
                                            inputProps={{ className: classes.textarea }}
                                        />
                                        <Box mt={2}>
                                               
                                        </Box>
                                        <Box sx={{ py: 2 }}>
                                            <Button
                                                color="primary"
                                                //disabled={isLoggingIn}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Create Email
                                            </Button>
                                        </Box>
                                    </form>
                                </CardContent>
                            </Card>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
}
const useStyles = makeStyles(theme => ({
    textarea: {
      resize: "both"
    }
  }));
export default Contact;