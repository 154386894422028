import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@material-ui/styles'

const ReportLoading = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('Compiling...');
    const [progress, setProgress] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        setShowModal(true); // Show modal on component mount
        let progressInterval;

        const startProgress = () => {
            progressInterval = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        clearInterval(progressInterval);
                        return 100;
                    }
                    // Increase progress to complete over 3 seconds
                    const diff = 100 / (1500 / 100); // Total duration divided by interval
                    return Math.min(oldProgress + diff, 100);
                });
            }, 100); // Update progress every 100ms
        };

        startProgress();

        const halfwayPoint = setTimeout(() => {
            setModalText('Analyzing...'); // Change text to 'Analyzing' after 1.5 seconds
        }, 1000); // Half of 3 seconds


        const timer = setTimeout(() => {
            setShowModal(false); // Hide modal after 3 seconds
        }, 2000); // Total duration of the modal

        return () => {
            clearTimeout(halfwayPoint);
            clearTimeout(timer);
            clearInterval(progressInterval);
        };
    }, []);

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
            hideBackdrop="true"
        >
            <Box className={classes.modal} >
                <Box className={classes.centered}>
                    <Typography id="loading-modal-title" variant="h1">
                        {modalText}
                    </Typography>

                    <Box sx={{ mt: 1, width: '30%' }}>
                        <LinearProgress variant="determinate" value={progress} sx={{ height: 20, borderRadius: 5 }} />
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%'
    },
    modal: {
        backgroundColor: '#f4f6f8',
        height: '98%',
        width: '80%',
        position: 'absolute',
        top: 65,
        left: 270

    },
});

export default ReportLoading;
