import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../Logo';
import DevModeSwitch from 'src/components/shared/DevModeSwitch'

const HomeNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);

  return (
    <AppBar
        elevation={0}
      {...rest}
    >
        <Toolbar>
            <RouterLink to="/">
            <Logo />
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />
            <DevModeSwitch />
            <Hidden lgUp>
            <IconButton
                color="inherit"
                onClick={onMobileNavOpen}
            >
                <MenuIcon />
            </IconButton>
            </Hidden>
        </Toolbar>
    </AppBar>
  );
};

HomeNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default HomeNavbar;
