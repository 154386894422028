import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Container, Box, Button, TextField, Typography, Card, CardContent } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { verifyEmail } from 'src/redux/thunks';
import { setEmail, verifyCodeReset } from 'src/redux/actions';

const AccessCodeRegistration = () => {

    const isSigningUp = useSelector(state => state.auth.isSigningUp)
    const accessCode = useSelector(state => state.auth.accessCode)

    const [errorMessage, setErrorMessage] = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch()

    return (

        <Container maxWidth="sm">
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(60).required('Email is required'),
                    })
                }
                onSubmit={(data) => {

                    let _email = data.email.toLowerCase()
                    if ((accessCode.owner?.email === 'adminpurch@vectorreports.com' && accessCode.assessor?.email === '') || _email === accessCode.owner?.email || _email === accessCode.assessor?.email) {
                        dispatch(verifyEmail(_email))
                        dispatch(setEmail(_email))
                    } else {
                        setErrorMessage("Error Email and Access code mismatch")
                    }

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Card >
                            <CardContent >
                                <Box ml={-2} mt={-1}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            dispatch(verifyCodeReset())
                                            navigate('/', { replace: true })
                                        }}>
                                        Back
                                    </Button>
                                </Box>
                                {/* Create Accountt */}
                                <Box sx={{ my: 1 }}>
                                    <Typography color="textPrimary" variant="h2" >
                                        Access Code has been verified
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2" >
                                        Enter your email below
                                    </Typography>
                                </Box>

                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    autoComplete="username"
                                />

                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        disabled={isSigningUp}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {false ? 'Register' : 'Submit'}
                                    </Button>
                                </Box>
                                {errorMessage && (
                                    <Typography color="#f44335" pt={2} fontSize={15} >
                                        {errorMessage}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        </Container>
    )
};


export default AccessCodeRegistration
