
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Copyright from 'src/components/shared/Copyright'

/*
 * footer component for reports
 */

const Footer = (props) => {

    const {
        page,
    } = props;

    const classes = useStyles();

    return (
        <Box className={classes.flexEnd}>
            <Copyright />
            <Box className={classes.positionRight}>
                <Typography color="black" variant="body2">{ page }</Typography>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles({
    flexEnd: {
        position: 'absolute',
        bottom: 50,
        display: 'inline-flex',
        width:'100%'
    },
    positionRight: {
        position: 'absolute',
        right: 300,
    },
})

Footer.propTypes = {
    page: PropTypes.number.isRequired,
}

export default Footer;