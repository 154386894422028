

import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
//custom
import QuadMessage from 'src/components/reports/QuadMessage';
import PageHeader from './PageHeader';

const InterpretingPlotPage3 = (props) => {

    const {
        quadMessages,
        assessorData,
    } = props;

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Interpreting Your Vulnerable Capability
                </Typography>
            </Box>

            {/* Dynamic Content */}
            <Box mt={8}>
                <Box id="secondVulnMsg">
                    <div className="content" dangerouslySetInnerHTML={{ __html: quadMessages.content_2 }}></div>
                    {/* <QuadMessage isFirstPage={false} />   */}
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    flexEnd: {
        position: 'absolute',
        bottom: 60,
        display: 'inline-flex',
        width: '100%'
    },
    positionRight: {
        position: 'absolute',
        right: 300,
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,
    quadMessages: state.takingAssessment.quadMessages,
})

export default connect(mapStateToProps)(InterpretingPlotPage3);
