import {
    CLEAR_ASSESSMENT_DATA,
    GET_ASSESSMENT_HISTORY_SUCCESS, GET_ASSESSMENT_HISTORY_REQUEST, GET_ASSESSMENT_HISTORY_FAILURE,
    GET_PURCHASE_HISTORY_SUCCESS, GET_PURCHASE_HISTORY_REQUEST, GET_PURCHASE_HISTORY_FAILURE,
    GET_ASSIGNED_SUCCESS, GET_ASSIGNED_REQUEST, GET_ASSIGNED_FAILURE,
    UPDATE_ASSESSOR_REQUEST, UPDATE_ASSESSOR_SUCCESS, UPDATE_ASSESSOR_FAILURE, RESET_ASSESSOR_NOTIFICATION,
    UPDATE_OWNER_REQUEST, UPDATE_OWNER_SUCCESS, UPDATE_OWNER_FAILURE, RESET_OWNER_NOTIFICATION,
    GET_OWNERSHIP_SUCCESS, GET_OWNERSHIP_REQUEST, GET_OWNERSHIP_FAILURE,
    GET_STORE_SUCCESS, GET_STORE_REQUEST, GET_STORE_FAILURE,
    CLAIM_CODE_REQUEST, CLAIM_CODE_SUCCESS, CLAIM_CODE_FAILURE, CLAIM_CODE_RESET, LOADING_ALL_DATA,
} from '../actions';

const assessmentState = {
    //get completed assessments
    isLoadingAllData: true,
    assessmentHistory: [],
    isAssessmentLoading: true,
    //get purchase history
    purchaseHistory: [],
    isPurchaseLoading: false,
    //get ownership
    ownershipData: [],
    isOwnershipLoading: false,
    //get assigned assessments
    assignedAssessments: [],
    isAssignedLoading: false,
    //get store
    storeItems: [],
    isStoreLoading: false,
    //update assessor
    isUpdatingAssessor: false,
    didUpdateAssessorFail: false,
    didAssessorUpdatePass: false,
    updateAssessorMessage: '',
    //update owner
    isUpdatingOwner: false,
    didUpdateOwnerFail: false,
    didOwnerUpdatePass: false,
    updateOwnerMessage: '',
    //claim code
    isClaimUpdating: false,
    didClaimFail: false,
    didClaimPass: false,
    claimMessage: '',
}

const assessment = (state = assessmentState, action) => {
    switch (action.type) {
        case CLEAR_ASSESSMENT_DATA:
            return {
                ...state,
                isLoadingAllData: true,
                //get completed assessments
                assessmentHistory: [],
                isAssessmentLoading: true,
                //get purchase history
                purchaseHistory: [],
                isPurchaseLoading: false,
                //get ownership
                ownershipData: [],
                isOwnershipLoading: false,
                //get assigned
                assignedAssessments: [],
                isAssignedLoading: false,
                //get store
                storeItems: [],
                isStoreLoading: false,
                //update assessor
                isUpdatingAssessor: false,
                didUpdateAssessorFail: false,
                didAssessorUpdatePass: false,
                updateAssessorMessage: '',
                //update owner
                isUpdatingOwner: false,
                didUpdateOwnerFail: false,
                didOwnerUpdatePass: false,
                updateOwnerMessage: '',
                //claim code
                isClaimUpdating: false,
                didClaimFail: false,
                claimMessage: '',
            }
        //get assessments
        case GET_ASSESSMENT_HISTORY_REQUEST:
            return {
                ...state,
                isAssessmentLoading: true,
            }
        case GET_ASSESSMENT_HISTORY_SUCCESS:
            return {
                ...state,
                assessmentHistory: action.history,
                isAssessmentLoading: false,
            }
        case GET_ASSESSMENT_HISTORY_FAILURE:
            return {
                ...state,
                isAssessmentLoading: false,
            }
        //get purchase history
        case GET_PURCHASE_HISTORY_REQUEST:
            return {
                ...state,
                isPurchaseLoading: true,
            }
        case GET_PURCHASE_HISTORY_SUCCESS:
            return {
                ...state,
                purchaseHistory: action.history,
                isPurchaseLoading: false,
            }
        case GET_PURCHASE_HISTORY_FAILURE:
            return {
                ...state,
                isPurchaseLoading: false,
            }
        //get ownership
        case GET_OWNERSHIP_REQUEST:
            return {
                ...state,
                isOwnershipLoading: true,
            }
        case GET_OWNERSHIP_SUCCESS:
            return {
                ...state,
                ownershipData: action.data,
                isOwnershipLoading: false,
            }
        case GET_OWNERSHIP_FAILURE:
            return {
                ...state,
                isOwnershipLoading: false,
            }
        //get assigned
        case GET_ASSIGNED_REQUEST:
            return {
                ...state,
                isAssignedLoading: true,
            }
        case GET_ASSIGNED_SUCCESS:
            return {
                ...state,
                assignedAssessments: action.data,
                isAssignedLoading: false,
            }
        case GET_ASSIGNED_FAILURE:
            return {
                ...state,
                isAssignedLoading: false,
            }
        //get store
        case GET_STORE_REQUEST:
            return {
                ...state,
                isStoreLoading: true,
            }
        case GET_STORE_SUCCESS:
            return {
                ...state,
                storeItems: action.data,
                isStoreLoading: false,
            }
        case GET_STORE_FAILURE:
            return {
                ...state,
                isStoreLoading: false,
            }
        //update assessor
        case UPDATE_ASSESSOR_REQUEST:
            return {
                ...state,
                isUpdatingAssessor: false,
                didUpdateAssessorFail: false,
                didAssessorUpdatePass: false,
                updateAssessorMessage: '',
            }
        case UPDATE_ASSESSOR_SUCCESS:
            return {
                ...state,
                isUpdatingAssessor: false,
                didAssessorUpdatePass: true,
            }
        case UPDATE_ASSESSOR_FAILURE:
            return {
                ...state,
                isUpdatingAssessor: false,
                didUpdateAssessorFail: true,
                updateAssessorMessage: action.message,
            }
        case RESET_ASSESSOR_NOTIFICATION:
            return {
                ...state,
                didUpdateAssessorFail: false,
                didAssessorUpdatePass: false,
                updateAssessorMessage: '',
            }
        //update owner
        case UPDATE_OWNER_REQUEST:
            return {
                ...state,
                isUpdatingOwner: false,
                didUpdateOwnerFail: false,
                didOwnerUpdatePass: false,
                updateOwnerMessage: '',
            }
        case UPDATE_OWNER_SUCCESS:
            return {
                ...state,
                isUpdatingOwner: false,
                didOwnerUpdatePass: true,
            }
        case UPDATE_OWNER_FAILURE:
            return {
                ...state,
                isUpdatingOwner: false,
                didUpdateOwnerFail: true,
                updateOwnerMessage: action.message,
            }
        case RESET_OWNER_NOTIFICATION:
            return {
                ...state,
                didUpdateOwnerFail: false,
                didOwnerUpdatePass: false,
                updateOwnerMessage: '',
            }
        //claim access code
        case CLAIM_CODE_REQUEST:
            return {
                ...state,
                isClaimUpdating: true,
                didClaimFail: false,
                didClaimPass: false,
                claimMessage: '',
            }
        case CLAIM_CODE_SUCCESS:

            let _ownership = state.ownershipData.map(own => (own.accessCode === action.code) ? { ...own, ...action.data } : own)
            let _assigned = state.assignedAssessments.map(assessment => (assessment.accessCode === action.code) ? { ...assessment, ...action.data } : assessment)

            let checkO = _ownership.find(o => o.accessCode === action.code)
            let checkA = _assigned.find(a => a.accessCode === action.code)

            if (!checkO && !checkA) {
                _ownership = [..._ownership, { ...action.purchase, ...action.data }]
            }

            return {
                ...state,
                isClaimUpdating: false,
                didClaimPass: true,
                claimMessage: 'Access Code claimed',
                ownershipData: _ownership,
                assignedAssessments: _assigned

            }
        case CLAIM_CODE_FAILURE:
            return {
                ...state,
                isClaimUpdating: false,
                didClaimFail: true,
                claimMessage: action.message
            }
        case CLAIM_CODE_RESET:
            return {
                ...state,
                isClaimUpdating: false,
                didClaimFail: false,
                claimMessage: ''
            }
        case LOADING_ALL_DATA:
            return {
                ...state,
                isLoadingAllData: action.loading
            }

        default:
            return state;
    }
}


export default assessment;