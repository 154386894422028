

import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
import BarChart from 'src/components/reports/BarChart';
//custom
import Footer from 'src/components/reports/Footer';
import PageHeader from './PageHeader';

const Charts = (props) => {

    const {
        finalEvaluation,
        assessorData
    } = props;

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''

    //returns chart labels  
    const getChartLabels = (categoryIndex) => {
        let values = [];
        if (Number.isInteger(categoryIndex)) {
            finalEvaluation[categoryIndex].processes.forEach(element => {
                values.push(element.menuName)
            })
        }
        return values;
    }
    //return chart data
    const getChartData = (categoryIndex) => {
        let values = [];
        if (Number.isInteger(categoryIndex)) {
            finalEvaluation[categoryIndex].processes.forEach(element => {
                values.push(element.selection)
            })
        }
        return values;
    }

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography color="textPrimary" gutterBottom riant="body1" style={{ fontSize: 28 }}>
                    Process Ratings by Category
                </Typography>
            </Box>
            <Box className={classes.blueBanner} py={0.5} mt={1}>
                <Typography color="textPrimary" variant="body1" style={{ fontSize: 19 }} m={1}>
                    <i>Your overall report is a derivative of all your Category Process Ratings. There are insights to be gained by
                        examining these individual process scores.</i>
                </Typography>
            </Box>

            {/* product chart */}
            <Box mt={6}>
                <Typography gutterBottom variant="h3" mb={4}>
                    Product
                </Typography>
                <Box id="productChart">
                    <BarChart
                        labels={getChartLabels(0)}
                        data={getChartData(0)}
                        backgroundColors={['#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD']} />
                </Box>
                {/* <Typography gutterBottom variant="body1" style={{ fontSize: 16 }} mt={3} ml={4}>
                    <i>Of all these factors "Performance" is critical and influential</i>
                </Typography> */}
            </Box>

            {/* market chart */}
            <Box mt={7}>
                <Typography gutterBottom variant="h3" mb={4}>
                    Market
                </Typography>
                <Box id="marketChart">
                    <BarChart
                        labels={getChartLabels(1)}
                        data={getChartData(1)}
                        backgroundColors={['#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD', '#4F81BD']} />
                </Box>
                {/* <Typography gutterBottom variant="body1" style={{ fontSize: 16 }} mt={3} ml={4}>
                    <i>Of all these factors "Perceptions" is critical and influential</i>
                </Typography> */}
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    leftCenteredColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    blueBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#B8CCE4',
    },
});

const mapStateToProps = state => ({
    finalEvaluation: state.takingAssessment.finalEvaluation,
    assessorData: state.takingAssessment.assessorData,
})

export default connect(mapStateToProps)(Charts);
