import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider'
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { useSearchParams, useNavigate } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';
import { setEmail } from 'src/redux/actions'
import { getLineItems, getSessionStatus, createAccessCode } from './lib'
import Loading from 'src/components/shared/Loading'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Receipt from './Receipt'


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const Success = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const printRef = useRef()

    const [searchParams] = useSearchParams()
    const [payment, setPayment] = useState(null)
    const [items, setItems] = useState(null)
    const [codes, setCodes] = useState([])
    const [loading, setLoading] = useState(true)
    const [showVideo, setShowVideo] = useState(false)

    useEffect(() => {
        async function fetch() {
            let session = await getSessionStatus(searchParams.get("sessionId"))
            let lineItems = await getLineItems(searchParams.get("sessionId"))

            if (session.data && lineItems) {
                setPayment(session.data)
                setItems(lineItems.data[0])
                createCodes(session.data, lineItems.data[0])
            }
        }
        if (searchParams.get("sessionId")) {
            fetch(searchParams.get("sessionId"))
        }
    }, [searchParams])

    const goToRegistration = () => {

        dispatch(setEmail(payment.customer_details.email))
        // dispatch(setNewAssets(codes.map(code => code)))
        //Navigate to registration view
        navigate('/', { replace: true })

    }

    const createCodes = async (session, lineItems) => {
        if (session && lineItems) {
            await Promise.all([...Array(lineItems && lineItems.quantity).keys()].map(async () => {
                let _code = await createAccessCode({ id: 'via', type: 'VIA·Biz' }, session.customer_details.email.toLowerCase(), session, lineItems.price.unit_amount)

                if (Array.isArray(_code)) {
                    setCodes(_code)
                } else {
                    setCodes(prevArray => [...prevArray, _code])
                }
            }))
            setLoading(false)
        }
    }

    const amountPaid = () => {

        if (items && items.quantity > 1) {
            return (
                <>
                    {items.quantity} x ${items.price.unit_amount / 100} = <b>${payment?.amount_total / 100}</b>
                </>
            )
        } else {
            return (
                <>
                    ${payment?.amount_total / 100}
                </>
            )
        }
    }

    const renderCodes = () => {
        return (
            <>
                {codes.map((code, index) => (
                    <>
                        <Typography color="textPrimary" gutterBottom variant="body1">{code}</Typography>
                        {index !== codes.length - 1 && <Divider style={{ width: 140, marginLeft: 10, marginBottom: '0.35em', display: 'block' }} />}
                    </>
                ))}
            </>
        )
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: 'vsba-receipt'
    })

    if (loading) return <Loading />

    return (
        <>
            <Box my={8} mx={4} sx={{ py: 3 }} >
                <Container maxWidth='lg'>
                    <Typography variant="body1" gutterBottom sx={{ fontSize: 28 }}>
                        <b>Congratulations!</b> Your new assessment is identified below.
                    </Typography>
                    <Box mt={3} mb={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button sx={{ width: 150, backgroundColor: '#4caf50' }} color='success' onClick={handlePrint} variant="contained">Print</Button>
                        <Typography ml={6} variant='body1' gutterBottom>
                            Stripe will send your a purchase receipt, but this is the only place you will find the full access code identifier.
                            We encourage you to print this page for your records of what assessments are associated with this email account.
                        </Typography>
                    </Box>
                    <Divider />
                    <TableContainer sx={{ padding: 2 }}>
                        <Table sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none"
                            },
                            marginLeft: 15,
                            width: 800
                        }} aria-label="table">
                            <TableBody>
                                <TableRow key={'email'}>
                                    <TableCell component="th" scope="row">
                                        <Box sx={{ width: 280 }}>
                                            Owner email account:
                                        </Box>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {payment ? payment?.customer_details.email.toLowerCase() : '####'}
                                    </TableCell>

                                </TableRow>
                                <TableRow key={'codes'}>
                                    <TableCell sx={{ width: '45%' }} component="th" scope="row">
                                        <Box sx={{ width: 280 }}> The following Assessment unique
                                            Access Code(s) are now available:</Box>
                                    </TableCell>
                                    <TableCell size="small" component="th" scope="row">
                                        {renderCodes()}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'price'}>
                                    <TableCell component="th" scope="row">
                                        Amount Paid:
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {amountPaid()}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'date'}>
                                    <TableCell component="th" scope="row">
                                        Date:
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {moment(Date.now(payment?.created)).format('MM/DD/yyyy')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Divider />
                    <Box mt={3} mb={3} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button sx={{ height: 36.5, width: 85 }} onClick={() => goToRegistration()} variant="contained">Home</Button>
                        <Box ml={6}>
                            <Typography color="textPrimary" variant="body1">
                                Click Home to return to the <b>Login page</b>—and <b>Login.</b> <br />
                                If this is your first purchase, you will need to set a password at Login
                            </Typography>
                            <Typography color="textPrimary" variant="body1" ml={10} mt={3}>
                                Click here to see a short <span onClick={() => setShowVideo(true)} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>walkthrough</span> video as a preview of what you can do in the site.
                            </Typography>
                            <Typography color="textPrimary" variant="body1" mt={3}>
                                If you are a returning client, simply proceed from the Login page.
                            </Typography>
                            <Typography color="textPrimary" variant="body1" mt={3}>
                                All new assessments require registration during login.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Modal
                open={showVideo}
                onClose={() => setShowVideo(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton onClick={() => setShowVideo(false)} sx={{ position: 'absolute', top: 5, right: 10 }}>
                        <CloseIcon />
                    </IconButton>
                    <video width="100%" height="100%" controls>
                        <source src="/static/videos/learn.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            </Modal>

            <div style={{ display: "none" }}>
                <Receipt data={payment} amount={amountPaid()} codes={codes} ref={(el) => (printRef = el)} />
            </div>
        </>
    );
};

export default Success
