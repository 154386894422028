import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormHelperText, Checkbox, Button, Box, TextField, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import {
    Eye as EyeIcon,
    EyeOff as EyeOffIcon
} from 'react-feather';

import { registerUser, updateUser } from 'src/redux/thunks';
import TermsModal from 'src/components/shared/TermsModal';
import BlockContainer from 'src/components/shared/blocks/BlockContainer';

const RegistrationForm = ({ onBack }) => {

    const newAssets = useSelector(state => state.auth.newAssets)
    const userInfo = useSelector(state => state.auth.userInfo)
    const userEmail = useSelector(state => state.auth.email)
    const userPassword = useSelector(state => state.auth.password)
    const accessCode = useSelector(state => state.auth.accessCode)
    const registrationErrorMessage = useSelector(state => state.auth.registrationErrorMessage)
    const didSignupFail = useSelector(state => state.auth.didSignupFail)
    const needsRegistration = useSelector(state => state.auth.needsRegistration)

    const [showPassword, setShowPassword] = useState('')
    const [showConfirmPassword, setShowConfirmPassword] = useState('')

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const dispatch = useDispatch();

    return (
        <>
            <Button
                color="primary"
                onClick={onBack}>
                Back
            </Button>

            <BlockContainer
                header="This account has a new Assessment. Please Register it."
            >

                <Formik
                    initialValues={{
                        email: userEmail,
                        firstName: userInfo.name ? userInfo.name.first : '',
                        lastName: userInfo.name ? userInfo.name.last : '',
                        phoneNumber: userInfo.phone ? userInfo.phone : '',
                        password: userPassword,
                        confirmPassword: userPassword,
                        companyName: userInfo.company?.name ? userInfo.company.name : '',
                        addressOne: userInfo.company ? userInfo.company.addressOne : '',
                        addressTwo: userInfo.company ? userInfo.company.addressTwo : '',
                        city: userInfo.company ? userInfo.company.city : '',
                        state: userInfo.company ? userInfo.company.state : '',
                        zip: userInfo.company ? userInfo.company.zip : '',
                        policy: false
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().max(60), //Yup.string().email('Must be a valid email').max(60).required('Email is required'),
                            firstName: Yup.string().max(60).required('First name is required'),
                            lastName: Yup.string().max(60).required('Last name is required'),
                            phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
                            password: Yup.string().min(8).max(60).required('Password is required'),
                            confirmPassword: Yup.string().max(60).oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),

                            companyName: Yup.string().max(60).notRequired(),//Yup.string().max(255).required('Company Name is required'),
                            addressOne: Yup.string().max(60).notRequired(),
                            city: Yup.string().max(20).notRequired(),
                            state: Yup.string().max(2, 'Two letter abbreviation').notRequired(),
                            zip: Yup.string().max(5, '5 number zip code').notRequired(),

                            poc: Yup.string().max(60),
                            policy: Yup.boolean().oneOf([true], 'This field must be checked')
                        })
                    }
                    onSubmit={(data) => {
                        data.email = userEmail
                        //use thunk to register user and store in redux 
                        if (!needsRegistration && newAssets.length > 0) {
                            dispatch(updateUser(data, newAssets))
                        } else {
                            dispatch(registerUser(data, accessCode))
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form onSubmit={handleSubmit}>

                            <Box sx={{ my: 1 }}>
                                <Typography gutterBottom variant="body2" >
                                    <i>Required information about <u>you</u> (or as a representative of <u>your</u> company):</i>
                                </Typography>
                            </Box>
                            {/* email */}
                            <Box sx={{ display: 'flex', alignItems: 'end' }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}

                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    //onChange={e => setEmail(e.target.value)}
                                    type="email"
                                    value={userEmail}
                                    variant="outlined"
                                    required={true}
                                    disabled={true}
                                    autoComplete="username" />

                                <Typography color="textSecondary" variant="body2" sx={{ width: 240 }}>
                                    Please enter the email to be associated with this account
                                </Typography>
                            </Box>
                            {/* first name */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    fullWidth
                                    helperText={touched.firstName && errors.firstName}
                                    label="First Name"
                                    margin="normal"
                                    name="firstName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    variant="outlined"
                                    required={true}
                                    type="text" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Please enter your first name
                                </Typography>
                            </Box>
                            {/* last name */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    fullWidth
                                    helperText={touched.lastName && errors.lastName}
                                    label="Last name"
                                    margin="normal"
                                    name="lastName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                    variant="outlined"
                                    required={true}
                                    type="text" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Please enter your last name
                                </Typography>
                            </Box>
                            {/* phone */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                    fullWidth
                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                    label="Phone Number"
                                    margin="normal"
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    required={true}
                                    variant="outlined"
                                    type="phone" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Please enter your phone #
                                </Typography>
                            </Box>
                            {/* password */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    required={true}
                                    variant="outlined"
                                    disabled={!needsRegistration && newAssets.length > 0}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    autoComplete="new-password" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Please set or enter your password
                                </Typography>
                            </Box>
                            {/* confirm password */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    fullWidth
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    label="Confirm Password"
                                    margin="normal"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={values.confirmPassword}
                                    variant="outlined"
                                    required={true}
                                    disabled={!needsRegistration && newAssets.length > 0}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <EyeOffIcon /> : <EyeIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    autoComplete="confirm-password" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Non-returners confirm password
                                </Typography>
                            </Box>

                            {/* Company Info */}

                            <Box sx={{ my: 3 }}>
                                <Typography color="textPrimary" gutterBottom variant="h4">
                                    Please enter account information:
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="body2">
                                    The <i>company</i> or <i>agency</i> you <i>represent</i> which may be different from the business to be assessed.
                                </Typography>

                            </Box>
                            {/* name */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.companyName && errors.companyName)}
                                    fullWidth
                                    helperText={touched.companyName && errors.companyName}
                                    label="Entity Name"
                                    margin="normal"
                                    name="companyName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyName}
                                    variant="outlined"
                                    type="text" />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 240, marginTop: 1 }}>
                                    Please enter your entity name
                                </Typography>
                            </Box>
                            {/* address */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.addressOne && errors.addressOne)}
                                    fullWidth
                                    helperText={touched.addressOne && errors.addressOne}
                                    label="Address"
                                    margin="normal"
                                    name="addressOne"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.addressOne}
                                    variant="outlined"
                                    type="text"
                                />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 280, marginTop: 1 }}>
                                    Please enter your entity mailing address
                                </Typography>
                            </Box>
                            {/* <TextField
								error={Boolean(touched.addressTwo && errors.addressTwo)}
								fullWidth
								helperText={touched.addressTwo && errors.addressTwo}
								label="Address 2"
								margin="normal"
								name="addressTwo"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.addressTwo}
								variant="outlined"
								type="text"
							/> */}
                            {/* city */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.city && errors.city)}
                                    fullWidth
                                    helperText={touched.city && errors.city}
                                    label="City"
                                    margin="normal"
                                    name="city"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.city}
                                    variant="outlined"
                                    type="text"
                                />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 280, marginTop: 1 }}>
                                    Please enter your City
                                </Typography>
                            </Box>
                            {/* city */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.state && errors.state)}
                                    fullWidth
                                    helperText={touched.state && errors.state}
                                    label="State"
                                    margin="normal"
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.state}
                                    variant="outlined"
                                    type="text"
                                />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 280, marginTop: 1 }}>
                                    Please enter your State
                                </Typography>
                            </Box>
                            {/* zip */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.zip && errors.zip)}
                                    fullWidth
                                    helperText={touched.zip && errors.zip}
                                    label="Zip code"
                                    margin="normal"
                                    name="zip"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.zip}
                                    variant="outlined"
                                    type="number"
                                />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 280, marginTop: 1 }}>
                                    Please enter your Zip code
                                </Typography>
                            </Box>
                            {/* contact */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -2 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', width: '50%', marginRight: 5 }}
                                    error={Boolean(touched.poc && errors.poc)}
                                    fullWidth
                                    helperText={touched.poc && errors.poc}
                                    label="Alternate POC "
                                    margin="normal"
                                    name="poc"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.poc}
                                    variant="outlined"
                                    type="text"
                                />
                                <Typography color="textSecondary" variant="body2" sx={{ width: 280, marginTop: 1 }}>
                                    Please provide an alternate Point of Contact name.
                                </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'center', display: 'flex', ml: -1 }} >
                                <Checkbox checked={values.policy} name="policy" onChange={handleChange} />
                                <Typography color="textSecondary" variant="body1" >
                                    I have read and accept the
                                    <Button>
                                        <TermsModal />
                                    </Button>
                                </Typography>
                            </Box>
                            {Boolean(touched.policy && errors.policy) && (
                                <FormHelperText error>
                                    {errors.policy}
                                </FormHelperText>
                            )}
                            <Box sx={{ py: 2 }}>
                                <Button
                                    sx={{ width: '50%' }}
                                    color="primary"
                                    disabled={!values.policy}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Accept and proceed to My Panel
                                </Button>
                                {didSignupFail && (
                                    <Typography color="#f44335" pt={2} fontSize={15} >
                                        Error: {registrationErrorMessage}
                                    </Typography>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </BlockContainer>
        </>
    )

};


export default RegistrationForm
