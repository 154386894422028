import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Drawer,
    Hidden,
    List,
} from '@material-ui/core';
import {
    LogIn as LogInIcon,
    FileText as FileTextIcon,
    UserPlus as UserPlusIcon,
    Edit as EditIcon,
    Home as HomeIcon,
    UserCheck as UserCheckIcon,
    Book as BookIcon,
} from 'react-feather';
import NavItem from '../NavItem';


const items = [
    {
        href: '/',
        icon: HomeIcon,
        title: 'Home'
    },
    {
        href: 'https://business.vectorreports.com/',
        icon: BookIcon,
        title: 'VIA-Services',
        external: true
    },
    // {
    //     href: 'https://business.vectorreports.com/',
    //     icon: BookIcon,
    //     title: 'Learn',
    //     external: true
    // },
    // {
    //     href: 'https://business.vectorreports.com/',
    //     icon: BookIcon,
    //     title: 'Learn',
    //     external: true
    // },
    // {
    //     href: '/login',
    //     icon: LogInIcon,
    //     title: 'Sign In'
    // },
    // {
    //     href: '/register',
    //     icon: UserPlusIcon,
    //     title: 'Register'
    // },
    {
        href: '/terms',
        icon: EditIcon,
        title: 'Terms'
    },
    // {
    //     href: '/about',
    //     icon: FileTextIcon,
    //     title: 'About'
    // },
    {
        href: '/admin-login',
        icon: UserCheckIcon,
        title: 'Admin'
    },
];

const HomeSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
        onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box sx={{  display: 'flex', flexDirection: 'column', height: '100%' }} >
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }} />
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            external={item.external}
                        />
                    ))}
                </List>
            </Box>
        <Box sx={{ flexGrow: 1 }} />
        </Box>
    );

    return (
        <>
        <Hidden lgUp>
            <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
            PaperProps={{
                sx: {
                width: 256
                }
            }}
            >
            {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <Drawer
            anchor="left"
            open
            variant="persistent"
            PaperProps={{
                sx: {
                width: 256,
                top: 64,
                height: 'calc(100% - 64px)'
                }
            }}
            >
            {content}
            </Drawer>
        </Hidden>
        </>
    );
};

HomeSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

HomeSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default HomeSidebar;
