
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { logoutUser } from 'src/redux/thunks';

const Logout = (props) => {

    const { 
        isLoggingOut, 
        didLogoutFail, 
        logoutErrorMessage,
        logout 
    } = props;

    const classes = useStyles();

    const handleSubmit = () => {
        logout();
    }
    
    return (
        <>
            <Helmet>
                <title>Vector Reports | Sign Out</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'center', height: '80%' }} >
                <Container maxWidth="sm">
                    <Card sx={{ justifyContent: 'center',alignItems: 'center', alignContent:'center' }}>
                        <CardContent>
                            <Box sx={{ my: 3 }} className={classes.center}>
                                <Typography color="textPrimary" variant="h2" >
                                    Sign Out
                                </Typography>
                                <Typography color="textSecondary" gutterBottom  variant="body2" pt={2}>
                                    Are you sure you want to log out?
                                </Typography>

                                <Box sx={{ pt: 4 }}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={isLoggingOut}
                                    >
                                        Sign Out now
                                    </Button>
                                </Box>
                                { didLogoutFail &&  ( 
                                    <Typography color="#f44335" pt={2} fontSize={15} width={'100%'}>
                                        {logoutErrorMessage}
                                    </Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
}

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }
});

const mapStateToProps = state => ({
    isLoggingOut: state.auth.isLoggingOut,
    didLogoutFail: state.auth.didLogoutFail,
    logoutErrorMessage: state.auth.logoutErrorMessage,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);