
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react'

import "@stripe/stripe-js";
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';

import GlobalStyles from 'src/components/shared/GlobalStyles';
//import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { logoutUser } from 'src/redux/thunks';

const App = (props) => {

    const {
        isAuthenticated,
        isAdmin,
        isTakingAssessment,
        newAssets,
        logout
    } = props;

    //send state values to route setup 
    const routing = useRoutes(routes(isAuthenticated, isTakingAssessment, isAdmin));

    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <GlobalStyles />
                {routing}
            </HelmetProvider>
        </ThemeProvider>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
    isTakingAssessment: state.takingAssessment.isTakingAssessment,
    newAssets: state.auth.newAssets,
    inDevMode: state.auth.inDevMode,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App); 
