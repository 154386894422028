import { Typography } from '@material-ui/core';
import moment from 'moment';

const Copyright = () => {
    return (
        <Typography color="black" variant="body2">
             Copyright © {moment().format('YYYY')}  Vector Reports 
        </Typography>
    )
}

export default Copyright;