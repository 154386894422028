import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { getAdminAssessmentHistory, getAdminReports, getCompletedAssessment } from 'src/redux/thunks';
import { updateReportContent } from 'src/redux/actions'
//ui
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Container, Paper, ButtonGroup, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//components
import TableSpinner from 'src/components/shared/TableSpinner';
import EnhancedTable from 'src/components/shared/EnhancedTable';

const AssessmentHistory = () => {

    const history = useSelector(state => state.adminReports.history)
    const isHistoryLoading = useSelector(state => state.adminReports.isHistoryLoading)
    const isCreateEvalLoading = useSelector(state => state.takingAssessment.isCreateEvalLoading)
    const adminReports = useSelector(state => state.adminReports.reports)

    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getAdminAssessmentHistory())
        dispatch(getAdminReports())
    }, [])

    useEffect(() => {
        dispatch(updateReportContent(adminReports))
    }, [adminReports])


    const openDownload = async (row) => {
        if (row.accessCode) {
            dispatch(getCompletedAssessment(row.accessCode))
            if(!isCreateEvalLoading) {
                navigate('/admin/view', { replace: true })
            }
           
        }
    }


    return (
        <>
            <Helmet>
                <title>Admin | Reports</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        Reports
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        View report content for an assessment below
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <EnhancedTable
                                headCells={headCells}
                                rows={history}
                                isLoading={isHistoryLoading}
                                openDownload={openDownload}
                            />
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

const headCells = [
    { id: 'organizationAssessed', numeric: false, disablePadding: true, label: 'Company Assessed' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
    { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
    { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
    { id: 'reports', numeric: false, disablePadding: false, label: 'View Reports' },
];

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', width: '100%'
    }
});


export default AssessmentHistory

