import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import AssessmentNavbar from './AssessmentNavbar';
import AssessmentSidebar from './AssessmentSidebar';

const AssessmentLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const AssessmentLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const AssessmentLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AssessmentLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const AssessmentLayout = (props) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <AssessmentLayoutRoot>
      <AssessmentNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <AssessmentSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}  />
      <AssessmentLayoutWrapper>
        <AssessmentLayoutContainer>
          <AssessmentLayoutContent>
            <Outlet />
          </AssessmentLayoutContent>
        </AssessmentLayoutContainer>
      </AssessmentLayoutWrapper>
    </AssessmentLayoutRoot>
  );
};


export default AssessmentLayout;
