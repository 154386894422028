import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Box, Container, Typography, TextField, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

/*
 *  form for adding email, first and last names 
 *  used to assign assessor and transfer ownership
 * 
 */

const UpdateUserForm = (props) => {

    const {
        role,
        assessment,
        handleSubmit,
        didUpdateFail,
        errorMessage,
        didUpdateSucceed,
        successMessage,
        isLoading,
        showEntity
    } = props;

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    const handleProceed = () => {
        setOpen(false)
        handleSubmit(assessment, data);
    }

    return (
        <>
            <Box sx={{ py: 3 }} >
                <Box mt={2}>
                    <Container maxWidth='md'>
                        {showEntity
                            ? (
                                <Formik
                                    initialValues={{
                                        email: '',
                                        entityName: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email('Must be a valid email').max(60).required('Email is required'),
                                        entityName: Yup.string().max(60),
                                        firstName: Yup.string().max(255),
                                        lastName: Yup.string().max(255),
                                    })}
                                    onSubmit={(data) => {
                                        setOpen(true)
                                        setData(data)
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="h4" >
                                                Transfer Ownership To:
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom variant="h5" >
                                                Enter new {role} for {assessment.displayCode}
                                            </Typography>
                                            <form onSubmit={handleSubmit}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    fullWidth
                                                    helperText={touched.email && errors.email}
                                                    label="Email Address"
                                                    margin="normal"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="email"
                                                    value={values.email}
                                                    variant="outlined"
                                                    autoComplete="username"
                                                />
                                                  <Typography sx={{ position: 'absolute', right: -280, bottom: 25 }} color="textSecondary" variant="body2">
                                                        Email to be used for this account
                                                    </Typography>
                                                </Box>
                                                {/* <TextField
                                                    error={Boolean(touched.entityName && errors.entityName)}
                                                    fullWidth
                                                    helperText={touched.entityName && errors.entityName}
                                                    label="Entity name (Company or Individual Name)"
                                                    margin="normal"
                                                    name="entityName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.entityName}
                                                    variant="outlined"
                                                    type="text"
                                                /> */}
                                                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                                <TextField
                                                    error={Boolean(touched.firstName && errors.firstName)}
                                                    fullWidth
                                                    helperText={touched.firstName && errors.firstName}
                                                    label="First Name"
                                                    margin="normal"
                                                    name="firstName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                    variant="outlined"
                                                    type="text"
                                                />
                                                </Box>
                                                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                                <TextField
                                                    error={Boolean(touched.lastName && errors.lastName)}
                                                    fullWidth
                                                    helperText={touched.lastName && errors.lastName}
                                                    label="Last name"
                                                    margin="normal"
                                                    name="lastName"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                    variant="outlined"
                                                    type="text"
                                                />
                                                 </Box>
                                                <Box sx={{ py: 2 }}>
                                                    <Button
                                                        color="primary"
                                                        disabled={isLoading}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Transfer
                                                    </Button>

                                                    {didUpdateFail && (
                                                        <Typography color="#f44335" pt={2} fontSize={15} >
                                                            {errorMessage}
                                                        </Typography>
                                                    )}
                                                    {didUpdateSucceed && (
                                                        <Typography color="#0bd17d" pt={2} fontSize={15} >
                                                            {successMessage}
                                                        </Typography>
                                                    )}
                                                </Box>

                                            </form>
                                        </Box>
                                    )}
                                </Formik>
                            ) : (
                                <Formik
                                    initialValues={{
                                        email: '',
                                        firstName: '',
                                        lastName: ''
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                        firstName: Yup.string().max(255),
                                        lastName: Yup.string().max(255),
                                    })}
                                    onSubmit={(data) => {
                                        setOpen(true)
                                        setData(data)
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                             <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="h4" >
                                             Identify Proposed Assessor:
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom variant="h5" >
                                                Enter new {role} for {assessment.displayCode}
                                            </Typography>
                                            <form onSubmit={handleSubmit}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                                    <TextField
                                                        error={Boolean(touched.email && errors.email)}
                                                        fullWidth
                                                        helperText={touched.email && errors.email}
                                                        label="Email Address"
                                                        margin="normal"
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="email"
                                                        value={values.email}
                                                        variant="outlined"
                                                        autoComplete="username"
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                                    <TextField
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        fullWidth
                                                        helperText={touched.firstName && errors.firstName}
                                                        label="First Name"
                                                        margin="normal"
                                                        name="firstName"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.firstName}
                                                        variant="outlined"
                                                        type="text"
                                                    />
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        fullWidth
                                                        helperText={touched.lastName && errors.lastName}
                                                        label="Last name"
                                                        margin="normal"
                                                        name="lastName"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.lastName}
                                                        variant="outlined"
                                                        type="text"
                                                    />
                                                </Box>
                                                <Box sx={{ py: 2 }}>
                                                    <Button
                                                        color="primary"
                                                        disabled={isLoading}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Assign
                                                    </Button>

                                                    {didUpdateFail && (
                                                        <Typography color="#f44335" pt={2} fontSize={15} >
                                                            {errorMessage}
                                                        </Typography>
                                                    )}
                                                    {didUpdateSucceed && (
                                                        <Typography color="#0bd17d" pt={2} fontSize={15} >
                                                            {successMessage}
                                                        </Typography>
                                                    )}
                                                </Box>

                                            </form>
                                        </Box>
                                    )}
                                </Formik>
                            )
                        }
                    </Container>
                </Box>

            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        In this beta version there is no automatic email notification sent to the recipient. You must notify the recipient outside of this system that this assessment is now available to them.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProceed} autoFocus>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

UpdateUserForm.propTypes = {
    role: PropTypes.string.isRequired,
    assessment: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    didUpdateFail: PropTypes.bool,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    didUpdateSucceed: PropTypes.bool,
    successMessage: PropTypes.string,
    showEntity: PropTypes.bool,
}
export default UpdateUserForm;