

import React, { useEffect } from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
//custom
import QuadMessage from 'src/components/reports/QuadMessage';
import PageHeader from './PageHeader';

const InterpretingPlotPage2 = (props) => {

    const {
        quadMessages,
        assessorData,
        item40
    } = props;

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Interpreting Your Vulnerable Capability
                </Typography>
            </Box>

            {/* banner */}
            <Box className={classes.brownBanner} py={0.5} mt={1}>
                <Typography variant="body1" style={{ fontSize: 19 }} m={1}>
                    <i>The smallest color plot is the indicator of your vulnerable capability.</i>
                </Typography>
            </Box>

            {/* static content */}
            <Box mt={8}>
                <Typography gutterBottom variant="h3" mb={2}>
                    Four Quadrants Represent Four Important Business Capabilities
                </Typography>
                {/* <Typography  gutterBottom variant="body1" style={{ fontSize: 18 }} mt={8}>
                    This assessment integrates Categories of Process ratings into maturity indicators of important capabilities your team or organization may already have, or may need to develop, to be successful. The size of each colored area on your plot indicates the relative maturity of each of these four capabilities:
                </Typography> */}
                <div className="content" dangerouslySetInnerHTML={{ __html: item40 }}></div>
            </Box>
            {/*  bullet list */}
            {/* <Box mt={4}>
                <Box className={classes.flexStart} mx={4}>
                    <ul>
                        <li>
                            <Typography  gutterBottom variant="body1" style={{ fontSize: 18 }} >
                                <b>Capability to develop an offering</b> ― <Box color='#D4831C' sx={{ display: 'inline' }}><b>gold</b></Box> ― can you integrate your processes (technology, products or services WITH your commercialization efforts) to create something new that achieves both your and your customer’s goals?
                            </Typography>
                        </li>
                        <li>
                            <Typography  gutterBottom variant="body1" style={{ fontSize: 18 }} >
                                <b> Capability to respond to market demand</b> ― <Box color='blue' sx={{ display: 'inline' }}><b>blue</b></Box> ― can you integrate your processes (technology, products or services WITH a deep understanding of your customers in your market) to produce what customers want?
                            </Typography>
                        </li>
                        <li>
                            <Typography  gutterBottom variant="body1" style={{ fontSize: 18 }} >
                                <b>Capability to generate revenue</b> ― <Box color='green' sx={{ display: 'inline' }}><b>green</b></Box> ― an you integrate your processes (deep understanding of your customers in your market WITH supporting business infrastructure housing operational capability) to make money and profit?
                            </Typography>
                        </li>
                        <li>
                            <Typography  gutterBottom variant="body1" style={{ fontSize: 18 }} >
                                <b> Capability to establish a business model</b> ― <Box color='red' sx={{ display: 'inline' }}><b>red</b></Box> ― can you integrate your processes (supporting infrastructure to house operational capability WITH your commercialization processes) to turn ideas, products or services into a viable product?
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Box> */}

            {/* Dynamic Content */}
            <Box mt={6}>
                <Typography color="red" gutterBottom variant="h3" mt={2} mb={6}>
                    Your Most Vulnerable Organizational Performance Capability
                </Typography>
                {/* display quad message */}
                <Box id="firstVulnMsg">
                    <div className="content" dangerouslySetInnerHTML={{ __html: quadMessages.content }}></div>
                    {/* <QuadMessage isFirstPage={true} />  */}
                </Box>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,
    quadMessages: state.takingAssessment.quadMessages,
})

export default connect(mapStateToProps)(InterpretingPlotPage2);
