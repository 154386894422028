import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './auth/Login'
import Code from './auth/Code'
import { verifyEmailReset } from 'src/redux/actions';
import { Link as RouterLink } from 'react-router-dom';
import Copyright from 'src/components/shared/Copyright';
import Footer from 'src/components/shared/Footer';

const LandingPage = () => {

    const needsRegistration = useSelector(state => state.auth.needsRegistration)
    const didVerifyPass = useSelector(state => state.auth.didVerifyPass)
    const didVerifyFail = useSelector(state => state.auth.didVerifyFail)
    const didEmailFail = useSelector(state => state.auth.didEmailFail)
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const errorCount = useSelector(state => state.auth.errorCount)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!isAuthenticated) {
            // window.history.pushState(null, null, document.URL);
            // window.addEventListener('popstate', function(event) {
            //   dispatch(verifyEmailReset())
            //   navigate('/', { replace: false });
            // });
    
            if (didVerifyPass || needsRegistration) {
                navigate('/register', { replace: false });
            }    
        }
       
    }, [window, didVerifyPass, needsRegistration, isAuthenticated])

    return (
        <>
            <Helmet>
                <title>Vector Reports | Home</title>
            </Helmet>
            <Box sx={{ minHeight: '92%' }} mt={8} mx={10}>
                <Container maxWidth='lg'>
                    <Box>
                        <Typography color="textPrimary" gutterBottom variant="h2">
                            <b>Welcome to Vector Reports </b>
                        </Typography>
                        <Typography color="textPrimary" variant="body1" fontSize={18}>
                            Your virtual consulting service for Business and Organizational Assessments
                        </Typography>
                    </Box>
                    <Box mt={8}>
                        <Typography color="textPrimary" gutterBottom variant="h1" >
                            Gateway
                        </Typography>
                    </Box>

                    <Box mt={10}>
                        {
                            ((didEmailFail || didVerifyFail) && errorCount < 4) && <Typography color="#f44335" gutterBottom variant="body2" mt={2}>
                                We do not recognize this entry in our system. Please provide a valid entry.
                            </Typography>
                        }
                        {
                            ((didEmailFail || didVerifyFail) && errorCount >= 4) && <Typography color="#f44335" gutterBottom variant="body2" mt={2}>
                                Please verify your entry credentials with the entity who provided your assessment.
                            </Typography>
                        }
                    </Box>

                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Login />
                        <Code />
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: 300 }}>
                            <Box sx={{ mb: 1 }}>
                                <Typography color="textPrimary" variant="h2" >
                                    Buy a Code
                                </Typography>
                            </Box>
                            <Box sx={{ py: 2, mt: 1 }}>
                                <Button
                                    component={RouterLink}
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    to="/buy"
                                    sx={{ width: 200 }}
                                >
                                    <span>Buy</span>
                                </Button>
                            </Box>
                        </Box>

                        <Box></Box>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    );
}

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }
});

export default LandingPage