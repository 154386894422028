import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [header, setHeader] = useState('');

  const menuChange = (text) => {
    setHeader(text)
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/app/mypanel':
        setHeader("Welcome")
        break
      case '/app/guide':
        setHeader('Guide')
        break;
      case '/app/help':
        setHeader('FAQ')
        break;
      case '/app/assign':
        setHeader('Action Page: Assign Assessor')
        break;
      case '/app/take':
        setHeader('Action Page: Take Assessment')
        break;
      case '/app/view':
        setHeader('Action Page: View Reports')
        break;
      case '/app/transfer':
        setHeader('Action Page: Transfer Assessment Ownership')
        break;
      case '/app/contact':
        setHeader('Contact Us')
        break;
      case '/app/information':
        setHeader('Prior to Take Assessment')
        break;
      default:
        setHeader('')
    }
  }, [location.pathname]);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        headerText={header}
      />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        onChange={menuChange}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
