//import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../Logo';
import { LogOut as LogOutIcon, /*Bell as BellIcon*/ } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import DevModeSwitch from 'src/components/shared/DevModeSwitch'

const DashboardNavbar = ({ onMobileNavOpen, headerText, ...rest }) => {
    //const [notifications] = useState([]);

    const navigate = useNavigate();


    return (
        <AppBar elevation={0} {...rest} >
            <Toolbar sx={{ display:'flex', justifyContent:'space-between' }}>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Hidden smDown>
                <Typography color="white" variant="h2" ml={20}>
                    <b>{headerText}</b>
                </Typography>
                </Hidden>
                <Box sx={{ flexGrow: 1 }} />
                <Hidden>
                    {/* <IconButton color="inherit">
                        <Badge
                        badgeContent={notifications.length}
                        color="primary"
                        variant="dot"
                        >
                        <BellIcon />
                        </Badge>
                    </IconButton> */} 
                    <DevModeSwitch />
                    <IconButton color="inherit" onClick={ () => navigate('/app/logout', { replace: true }) }>
                        <span style={{marginRight: 10}}>Sign Out</span> <LogOutIcon />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen} >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func,
    headerText: PropTypes.string
};

export default DashboardNavbar;
