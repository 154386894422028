import React, { useState, useEffect, useRef } from 'react'
//redux
import { connect } from 'react-redux';
import { getAccessCodes, createAccessCode } from 'src/redux/thunks';
//ui
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import {
    CircularProgress, Box, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow,
    TableContainer, Container, Paper, TextField, Toolbar, TableFooter, InputLabel, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//components
import AccessCodeTable from 'src/components/admin/AccessCodeTable';
// import AccessCodeCreate from 'src/components/admin/AccessCodeCreate';

const AccessCodes = (props) => {

    const {
        getAccessCodes,
        accessCodes,
        isCodesLoading,
        createAccessCode,
        reports
    } = props;

    const [reportType, setReportType] = useState({ id: 'via biz', type: 'VIA Biz' })

    const purchaserRef = useRef('adminpurch@vectorreports.com');
    const ownerRef = useRef('adminpurch@vectorreports.com');
    const assessorRef = useRef('');
    const costRef = useRef(0);

    const classes = useStyles();

    useEffect(() => {
        getAccessCodes()
    }, [])


    const onCreate = (e) => {
        e.preventDefault()
        // *** TO DO *** update first param with dropdown data
        createAccessCode(reportType, purchaserRef.current, ownerRef.current, assessorRef.current, costRef.current)
    }

    const handleSelectChange = (event) => {
        setReportType({
            id: event.target.value.toLowerCase(),
            type: event.target.value.toUpperCase()
        });
    }

    return (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        Access Codes
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        Manage access codes below
                    </Typography>
                </Container>
                <Box mt={4}>
                    {/* form */}
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <Toolbar>
                                <Typography variant="h4" id="historyLabel" color="textSecondary" >
                                    Create Access Code
                                </Typography>
                            </Toolbar>
                            <TableContainer>
                                <Table aria-label="purchaseTable" size={'medium'} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell id={'ast'} >
                                                <Typography variant="body1" id="historyLabel">
                                                    Assessment Type
                                                </Typography>
                                            </TableCell>
                                            <TableCell id={'pem'} >
                                                <Typography variant="body1" id="historyLabel">
                                                    Purchase Email
                                                </Typography>
                                            </TableCell>
                                            <TableCell id={'oem'}>
                                                <Typography variant="body1" id="historyLabel">
                                                    Owner Email
                                                </Typography>
                                            </TableCell>
                                            <TableCell id={'aem'}>
                                                <Typography variant="body1" id="historyLabel">
                                                    Assessor Email
                                                </Typography>
                                            </TableCell>
                                            <TableCell id={'aem'}>
                                                <Typography variant="body1" id="historyLabel">
                                                    Cost
                                                </Typography>
                                            </TableCell>
                                            <TableCell key={'cret'}>
                                                <Typography variant="body1" id="historyLabel">
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow height={100}>
                                            {/* *** TO DO ***  create dropdown and send real data */}
                                            {/* <TableCell><Typography >VSBA</Typography></TableCell> */}
                                            <TableCell>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                    <Select
                                                        value={reportType.type}
                                                        label="Type"
                                                        onChange={handleSelectChange}
                                                        width={200}
                                                    >
                                                        {reports && reports.length > 0 && (
                                                            reports.map((row, index) => {
                                                                return <MenuItem
                                                                    key={row.reportId}
                                                                    value={row.reportType}
                                                                >
                                                                    {row.reportType}
                                                                </MenuItem>
                                                            })
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <TextField label={purchaserRef.current} fullWidth onChange={e => purchaserRef.current = (e.target.value)} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField label={ownerRef.current} fullWidth onChange={e => ownerRef.current = (e.target.value)} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField label={assessorRef.current} fullWidth onChange={e => assessorRef.current = (e.target.value)} />
                                            </TableCell>
                                            <TableCell style={{ maxWidth: 100 }}>
                                                <TextField
                                                    label={'$' + costRef.current}
                                                    size="small"
                                                    onChange={e => costRef.current = (e.target.value)}
                                                    type="number"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button sx={{}} color="primary" onClick={onCreate}>
                                                    Create
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Container>
                    {/* Table */}
                    <AccessCodeTable
                        accessCodes={accessCodes}
                        isCodesLoading={isCodesLoading}
                    />
                </Box>
            </Box>
        </>
    )
}

const headCells = [
    { id: 'code', numeric: false, label: 'Access Code' },
    { id: 'date', numeric: true, label: 'Date' },
    { id: 'purchaser', numeric: false, label: 'Purchaser' },
    { id: 'owner', numeric: false, label: 'Owner' },
    { id: 'assessor', numeric: false, label: 'Assessor' },
    { id: 'cost', numeric: false, label: 'Cost' },
    { id: 'update', numeric: false, label: '' },
];

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', width: '100%'
    }
});

const mapStateToProps = state => ({
    accessCodes: state.adminReports.accessCodes,
    isCodesLoading: state.adminReports.isCodesLoading,
    reports: state.adminReports.reports,
});

const mapDispatchToProps = dispatch => ({
    getAccessCodes: () => dispatch(getAccessCodes()),
    createAccessCode: (assesment, purchaser, owner, assessor, cost) => dispatch(createAccessCode(assesment, purchaser, owner, assessor, cost)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessCodes);

