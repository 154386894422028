
import  { db, timestamp } from "../../services/firebase";

import { 
    getEvaluationRequest, getEvaluationSuccess, getEvaluationFailure,
    getGraphicRequest, getGraphicSuccess, getGraphicFailure,
    evalRequest, evalSuccess, evalFailure,
    getEvalRequest, getEvalSuccess, getEvalFailure,
} from '../actions'

export const getEvaluation = (assessmentId, accessCode ) => async dispatch => {

    // updateEvaluation();
    
    var errMsg = null;

    //check params
    if (!assessmentId || !accessCode) {
        console.log('getEvaluation: code is undefined');
        return;
    }

    let _id = (assessmentId === 'via biz' || assessmentId === 'via') ? 'vsba' : assessmentId
    
    //init request
    dispatch(getEvaluationRequest());

    try {
        const query = db.collection('assessmentEvaluations').doc(_id); 
        await query.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                //update evaluation
                dispatch(getEvaluationSuccess(data.categories, accessCode));
            } else {
                errMsg = 'Assessment Type not found'
                console.log("getEvaluation: ", 'Assessment Type not found');
                dispatch(getEvaluationFailure(errMsg));
            }
        })
        .catch(error => {
            errMsg = error.message;
            console.log("getEvaluation: ", error.message);
        dispatch(getEvaluationFailure(errMsg));
        });
    } catch(error) {
        errMsg = error.message;
        console.log("getEvaluation: ", error.message);
        dispatch(getEvaluationFailure(errMsg));
    }

    if (!errMsg) {
        return true;
    }            
}
//vsba grid image
export const getGridImage = params => async dispatch => {

    var errMsg;
    let url = 'https://plotgen-vector-reports.web.app/api/';
    let request = url + '525/525/26/thresholds/t/F5CA1B/3933FF/16930A/f44141';
    const token = 'd4760de3-3a6d-44e0-9177-204d98307e81';
    let positiveX = '';
    let negativeX = '';
    let positiveY = '';
    let negativeY = '';
    //api/525/525/26/thresholds/f/F5CA1B/3933FF/16930A/f44141/ 24/-15/15/-10/background/true
    if (!params) {
        console.log('getEvaluation: params are undefined');
        return;
    }

    // console.log('params: ', params)
    //init request
    dispatch(getGraphicRequest());

    try {
        //loop through array of categories and build url
        await params.forEach(async category => {
            //loop through each category and set averages
            if (category.category == 'Commercialization' || category.category == 'COMMERCIALIZATION' || category.category == 'COMMERCIALIZATION: Category of Processes') {
                positiveX = category.rawSum;
            }
            if (category.category == 'Market') {
                negativeX = '-' + category.rawSum;
            } 
            if (category.category == 'Product') {
                positiveY = category.rawSum;
            }
            if (category.category == 'Business') {
                negativeY = '-' + category.rawSum;
            }
        })

        request =  request + `/${positiveX}/${negativeX}/${positiveY}/${negativeY}/background/t`;

        fetch(request, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`, 
            },
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getGraphicSuccess(data.image));
            }).catch(function(error) {  
                errMsg = error.message;
                console.log('getGridImage Request failed', error)  
            }
        );
    } catch(error) {
        errMsg = error.message;
        console.log("getGridImage: ", error);
    }

    if (errMsg) {
        //dispatch error message
        dispatch(getGraphicFailure(errMsg));
    }
                
}

export const getCompletedAssessment = accessCode => async dispatch =>{
    dispatch(getEvalRequest())
    try {
        const assessmentHistory = db.collection('assessmentHistory');
        const query = assessmentHistory.where('accessCode', '==', accessCode).limit(1);
        
        await query.get().then(snapshot => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update assessment
                    const data = doc.data();
                    dispatch(getEvalSuccess(data));
                    // console.log('AH: ', data);
                } else {
                    console.log("assessmentHistory not found");
                    dispatch(getEvalFailure('assessmentHistory not found'));
                }
            });
        }).catch(error => {
            console.log('getCompletedAssessment error : ', error.message);
        });
        // const assessmentHistory = db.collection('assessmentHistory');
        // const query = assessmentHistory.where('accessCode', '==', accessCode).limit(1);

        // query.get().then(snapshot => {
        //     //store previous owner of assessment
        //     snapshot.forEach(doc => {
        //         if (doc.exists) { 
        //             const data = doc.data();
        //             dispatch(getEvalSuccess(data));
        //         } else {
        //             console.log('report not found')
        //         }
        //     });
        // }).catch(error => {
        //     console.log('Error: ', error.message);
        //     dispatch(getEvalFailure(error.message));
        // });

        // await query.get().then(doc => {
        //     //store previous owner of assessment
        //     if (doc.exists) { 
        //         const data = doc.data();
        //         dispatch(getEvalSuccess(data));
        //     } else {
        //         console.log('report not found')
        //         dispatch(getEvalFailure('report not found'));
        //     }
        // }).catch(error => {
        //     console.log('Error: ', error.message);
        //     dispatch(getEvalFailure(error.message));
        // });
    } catch(e) {
        console.log('getCompletedAssessment error: ', e.message)
        dispatch(getEvalFailure(e.message));
    }
} 
/*
 * CREATE
 */

//creates record in assessmentHistory
export const createAssessmentHistory =  (assessmentType, userId, accessCode, evaluation, assessorData, ownerEmail, reports)  => async dispatch => {
    // console.log(assessmentType, userId, accessCode, evaluation, assessorData, ownerEmail)
    //check params
    if(!assessmentType || !userId || !accessCode || !evaluation || !assessorData || !ownerEmail){
        console.log('saveTakenAssessment missing params');
        return;
    }
    
    try {
        dispatch(evalRequest())

        //check for existing record
        const purchaseHistory = db.collection('assessmentHistory');
        const query = purchaseHistory.where('accessCode', '==', accessCode).limit(1);
       
        let hasRecord = false;
        await query.get().then(snapshot => {
            //store previous owner of assessment
            snapshot.forEach(doc => {
                if (doc.exists) {
                    hasRecord = true
                }
            });
        }).catch(error => {
            console.log('Error: ', error.message);
        });

        if (!hasRecord) {
            //create new record
            await db.collection('assessmentHistory').add({
                accessCode,
                userId,
                assessorSortName: assessorData.assessor.name,
                assessor: assessorData.assessor,
                company: assessorData.company,
                dateAssessed: timestamp, 
                organizationAssessed: assessorData.company.name,
                owner: {
                    email: ownerEmail
                },
                evaluation,
                reports: [],
                project: assessorData.company.projectName,
                type: assessmentType,
            }).then((response) => {
                //update success message 
                // console.log('success response: ', response)
                dispatch(evalSuccess(response));
                dispatch(_markPurchaseCompleted(accessCode, assessorData.assessor))
                //update purchaseHistory to complete and add assessor email
            }).catch(error => {
                console.log('error: ', error.message);
                dispatch(evalFailure(error.message));
            });
        }

    } catch(error){
        console.log('error: ', error.message);
        dispatch(evalFailure(error.message));
    }
}

/*
 * LOCAL FUNCTIONS
 */


//update purchase history to mark as completed
const _markPurchaseCompleted = (accessCode, assessorEmail) => dispatch => {
    //check params
    if(!accessCode || !assessorEmail) {
        console.log('_markPurchaseCompleted missing params');
        return;
    }

    try {
        const purchaseHistory = db.collection('purchaseHistory');
        const query = purchaseHistory.where('accessCode', '==', accessCode).limit(1);
        
        query.get().then(snapshot => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update assessment
                    await doc.ref.update({
                            status: 'Complete',
                            isCompleted: true,
                        }).then(() => {
                            console.log('update purchase history success')
                        }).catch(error => {
                            console.log('_markPurchaseCompleted error3 : ',error.message);
                        });
                } else {
                    console.log("purchaseHistory not found");
                }
            });
        }).catch(error => {
        console.log('_markPurchaseCompleted error2 : ', error.message);
        });
    } catch(error) {
        console.log('_markPurchaseCompleted error1 : ', error.message);
    }
}
/*
 * update DB
 */

const updateEvaluation = () => {
    const evaluation = { 
        categories :[  
            {
                category: 'Product',
                axis: 'North',
                rawSum: 0,
                average: 0,
                didView: false,
                intro: {
                    header:  'Your Product Processes',
                    paragraphs:[
                        '<span style={{color: "red"}}>Pause, and shift your thinking!</span>',
                        '<b>Product:</b> What your customers pay you to do.',
                        'Your product or offering could be anything abstract from a theory or idea to development of a testable notion or an actual prototype or product. It could be something physical like hardware, digital like software or a website, or a service like providing labor, conducting research or delivering information. Throughout this Assessment, the word "product" is used as a shorthand term for any of these offerings for which your organization provides to a customer.',
                        '<span style={{fontWeight: 600, fontSize: 16}}>Frame of reference</span>',
                        'The frame of reference you use when rating processes is from what your customers think about your product offering! This section is NOT about what you think about your product. Please shift your thinking to an OUTSIDERs view that is looking IN at your offerings and making choices.',
                        'If you do not know that information right now, you might consider postponing this assessment until you actually know what your customers think about your product offering(s). Or, you might proceed with the assessment to learn what you ought to be seeking from your customers.',
                        'These eight processes are ALL about what customers could inform you about your own Product offerings from their perspective:',
                        '<ul><li>Customer identification and codification</li><li>Value of product line / service offering to the customer</li><li>Utility of the product / service offering to the customer</li><li>Function of your product line / service in the market</li><li>Form of the product / service offering</li><li>&quot;… ilities&quot; of your offerings have been put into effect</li><li>Performance of the product or service offerings</li><li>Standards the product offering achieves</li></ul>',
                        '<span style="font-weight: 600; font-size: 16">Proceed</span>',
                        'Remember, as you select your maturity Level Rating for each process that you read the criteria from the bottom-up. Then select the lowest level criteria description that the process fully satisfies. This means even if you have partial higher-level criteria satisfied, you should not select that higher level.',
                        'Click to begin rating processes:',
                    ]
                },
                processes: [
                    {
                        id: 1,
                        rank: 5,
                        selection: -1,
                        notes: '',
                        menuName: 'Customer Id',
                        processName: 'Customer identification and codification',
                        description: 'Given your product(s) and service(s) offerings you have a clear understanding of your ideal customer regarding their needs, wants, and perhaps even what they will want.  This includes knowing their idiosyncrasies about their preferences (including dis-satisfiers).',
                        maturityLevels: [
                            {description: "We anticipate customer wants before they know of them themselves.", level: 3 },
                            {description: "We know what categories and market segments our customers are within.", level: 2 },
                            {description: "We are defining the characteristics of our customers and their demographics.", level: 1 },
                            {description: "Our customers find us as opposed to us knowing where they are.", level: 0 }
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Many start businesses without really knowing their customer archetype.  Perhaps the business owner does not need to know this because they are essentially copying another business and customers simply appear right before their eyes.  Chances are that the gross level characteristics such as old or young, male or female, are obvious associations with what you offer to your customers.',
                            'Yet, that said, most businesses would like more customers.  ',
                            'To accomplish this you need to research your current customers and learn what causes them to make purchase decisions.  It is time to start collecting answers in a disciplined way, which facilitates later data analysis.  Sensitize your awareness as to which customer purchases what products or services, and why.',
                            'What are you looking for in your quest?  Consider collecting information about your customers in a way that leads to the development of an ideal customer or customer archetype (not about individuals):',
                            {unordered : [
                                'What are the demographic characteristics of individual buying customers?  Consider these (in no particular order):',
                                {unordered :[ 
                                    'age, gender, Zip code, wealth, condition, interests, projects',
                                    'needs, wants, challenges, family status, politics, religion, race,  lifestyle'
                                ]},
                                'What are the demographic characteristics of organizations [large/medium/small companies, agency, government, non-profit organization, location, industry, etc.] as customers?',
                                'What are the preferences of your buying customers?  Consider these (in no particular order):',
                                {unordered: [
                                    'features, style, newness, cost, performance',
                                    'quality, location, speed, associations.'
                                ]},
                                'What are the characteristics of those who do not buy? [Same as above]'
                            ]},
                            'After collecting data (and you are always collecting, right?) start analyzing it to learn something new.  Are there common factors of buyers and/or non-buyers?  Are there characteristic sensitivities such as price, distance, quality, etc.  These questions help reveal and define your current market.',
                            'Then ask the next-level questions: Does the data suggest there are other market segments you could target?  What should you do more of, and less of, in your marketing efforts?  What communication channels best access your customers?  Your answers indicate the marketing journey you should be engaged in.'
                        ]
                    },{
                        id: 2,
                        rank: 2,                    
                        selection: -1,
                        notes: '',
                        menuName: 'Customer Value',
                        processName: 'Value of product line / service offering to the customer',
                        description: 'You have discovered what makes a difference when influencing the choices your customers make - all of them tied to value delivery - and you use that to guide your customers to you.  At the end of the day, everyone says “…you and your offerings are a good value for the money.”',
                        maturityLevels: [
                            {description: "A clear value identity exists in our customer's mind and experiences with us.", level: 3 },
                            {description: "We know why our customers choose us over our competitors.", level: 2 },
                            {description: "We are defining and quantifying value to our customers.", level: 1 },
                            {description: "We are intuitive and trust our own experience about what customer want.", level: 0 }
                        ],
                        advicePageBreakIndex: 3,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'It is human nature to assume.  It is easy to say, “I think I know” when it actually is merely opinion.  We, as business people, tend to fall in love with the business we create, what we do, and our offerings.  We may even be right, once in a while, but how often are humans consistently “right” again and again over time?  Not often. Yet, there is a proven way to be “right” with what your customer needs, wants, or those who don\'t know yet that they want it.  Interested?',
                            'Step One: Get out of your own way.  Yes, you are smart.  Yes, you are accomplished (you started a business, after all).  And yes, you may have made some money along the way.  But all that says is that you were “right” once, maybe twice.  But will you be right the next time?  Or when technology changes again?  Or when your neighborhood changes next?  When the "school of hard knocks" is sending messages to you (e.g. reduced or level sales, complaints, requests for improvements), listen to the messages.  Go back to basics—go back to your customers to validate offerings and what might need to change. ',
                            'Step Two: Create a list of what your customers (present and future) judge you on.  Actually create the proof that shows you meet or achieve customer needs and wants.  Your written proof ought to address things like affordability, utility, satisfiers and dis-satisfiers, value, timing, accessibility, location, feel, advantages and much more.  And all of this for the right price.',
                            'Step Three: Repeat Steps One and Two on an ongoing basis.  This “discovery” about your customers and your offering(s) is a typical process accomplished early-on in business formation, but given how things change over time, doesn\'t it make sense to ensure that you continually build your customers into the value-discovery process as time passes and the world changes.',
                            'The basic question to answer is: "Does the offering(s) make the desired difference for the customer(s)?"',
                            {unordered:[
                                'If yes, then you have found “fit” with your customer\'s needs with what your product(s) or service(s) provide to them.  It serves as the foundation for the development of solid customer relationships.  ',
                                'If no, then you have found a gap in performance, understanding, need, wants, etc.'
                            ]},
                            'Value missteps with your customer causes questioning of the relationship itself.  And many things can disrupt value delivery to the customer.  What was working can stop working if the archetype as a group goes through change and you didn\'t respond to it with them.  Or you changed something that affected a differentiator without realizing it was a critical feature or characteristic.',
                            'All that said, customers can be quite forgiving if they believe you, as a business, care about them.',
                            'So, the final advice here is to focus on delivering upon customer value differentiators that you learn about through solid relationships.  Get to know your customers!'
                        ]
                    },{
                        id: 3,
                        rank: 3,
                        selection: -1,
                        notes: '',
                        menuName: 'Utility',
                        processName: 'Utility of the product / service offering to the customer', 
                        description: 'You have discovered the problems your customers need to fix.  Accordingly, you have created or offer product(s) and/or service(s) that deliver solutions to those problems.  These offerings keenly focus on solutions so well that customers say, “Go to _(you)__ for answers.”',
                        maturityLevels: [
                            {description: "The offering goes beyond utility to provide solutions to the customer.", level: 3 },
                            {description: "The offering delivers demonstrable utility to each customer.", level: 2 },
                            {description: "We are defining our offering\'s utility with our current and future customers.", level: 1 },
                            {description: "We are intuitive and trust our own experience about what customers need.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',                
                            'Some businesses offer products and services based upon what the owner wants to sell.  Sometimes owners are right and sometimes they are off target a bit.  It can be risky to spend funds on inventory and supplies if you are uncertain customers will actually need or want them.  If you are well-funded and have a vanity business because it is fun for you … well, good for you.  However, the rest of us need to make sure what we offer sells well.',                
                            'Utility is defined as “fitness for some purpose or worth to some end.”  ',               
                            'This business process says you should become the expert in offering the kinds of things or services that deliver solutions to the known problems your customers experience. ',                
                            'So, this is where you connect the dots:  Identified problems require solutions offered by businesses that are needed by specific customers who are experiencing those specific problems.   Map that out for your customer archetype.  Then deliver an offering that solves problems. This is how you know if you are delivering utility.  ',                
                            'Until you KNOW their problems, you are merely guessing and rolling the dice.',
                            'Once you know the field or type of business you are in, this generic advice leads to specific guidance.  The kind of expertise you have and your specific offerings (tools, process, methods, products, services) are directly tied to knowing the problem and its solution for your customer demographic.  For example, don\'t offer a multi-course filet mignon meal at McDonalds.  Yes, you fed your customer, but you likely ignored all the other characteristics that make up utility for that market.',               
                            'Your ability to delivery utility helps create: a positive reputation in the customer\'s mind, positive relationships with grateful customers, and customers who turn into advocates for you.  Failure to do this produces the opposite results.',               
                            'At the end of the day, you are not simply selling a product or service, but are instead selling solutions that create customer success--defined by your customer (a fully belly, satisfying art, response time, the right tool for the job, the right experience, ease of use, speed, quality, etc.) all to satisfy their goal. Solve their problems and they will keep coming back.',               
                            'Listen to what your customers are asking for--the hidden problem behind the request—that is the gold in your conversation with them. Work to solve the customer\'s problem in a way that says “… we solve those kinds of problems” and then teach them a bit about getting the utility they seek (if they seem to want or need it).  Otherwise, you are like an administrative process agent where you, get you in, get you out, and be done.  And those only work for required actions like the DMV.',               
                            'So, if you are not like that, then guide them along their purchasing process.  Help them know they are making a good decision to buy. Encourage them to ask questions and come back.'
                        ]
                    },{
                        id: 4,
                        rank: 4,
                        selection: -1,
                        notes: '',
                        menuName: 'Function / Solution',
                        processName: 'Function of your product line / service in the market', 
                        description: 'The offering does what your customers want or need it to do—and it works well!  You are the experts in your product line or service domain for functional solutions.  Your inventory or offerings are specially fitted for the action for which they exist to accomplish.  It produces reliable results.',
                        maturityLevels: [
                            {description: "The offering stands apart from all competition.  It promises--but delivers more.", level: 3 },
                            {description: "The offering function is competitive with what others offer.", level: 2 },
                            {description: "We are defining our offering\'s key functions and features with customers.", level: 1 },
                            {description: "We are intuitive and trust our own experience about what customers need.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',                
                            'Function ought to be easy to describe and give advice upon, but, surprisingly it is not.  There are so many different types of businesses and nearly an endless supply of existing or new “things” and “services” to be offered to a customer over time.  And that can be a problem.  ',                
                            'What does your business do in terms of supplying “function” to your customers?  ',                
                            {unordered: [
                                'Are you all things to all people?  ',
                                'Are you a niche specialty provider for a select segment of the market?  ',
                                'Do you cross multiple kinds of business as an aggregator?  ',
                                'Do customers wander in and spend time in the menagerie looking for inspiration or are they focused and looking for a specific solution.  '
                            ]},
                            'However, function is one of those things that when people see it, they know it.',
                            'This is the challenge of defining function in this context.  Is it the function of your business?  Or the function of your offering(s)?  Likely, both.  And how do you do that simply.  For example, ask someone you know, “What is it you do?”  Chances are you will get a long winded reply.  A better example is a car dealership owner who introduced himself to strangers, “My name is Jim and I sell cars.  Glad to meet you!”  Pretty clear and he already started building a relationship.  You need to do the same.  ',
                            'What is your basic function?  What is your verb and noun?',
                            'So, in that context, what does your product line or service do?  Is what it does clear?  Is it easy to understand its application or use?  Now, ask those same questions but from the perspective of the customer - what do they think the function is?',
                            'Find out from your customer if your product line or service is connecting with them and learn how they are actually using it.',
                            'It may turn out that what you thought the function of the offering was may be actually creatively being reused for an entirely different application.  It may be that customers are buying to be “cool” and not to use the thing or service for a practical purpose.  Or, perhaps, that creative and new use is the introduction to a new market.',
                            'Who knows?  In the end, it is the job of your business to know.',
                            'Most things change over time and some things stay the same.  You, as expert in listening to your customer, work to be on the leading edge of knowledge in both of those conditions.  While this is generic advice, following it will lead to specific guidance once you know the intended function / purpose the customer is trying to satisfy.'
                        ]
                    },{
                        id: 5,
                        rank: 6,
                        selection: -1,
                        notes: '',
                        menuName: 'Form',
                        processName: 'Form of the product / service offering', 
                        description: 'The design and characteristics of your product line / service(s) offering is directly shaped by your customer input.  You take customer discovery and inform your suppliers of changes, revisions, and features so you can offer only what are really desired.',
                        maturityLevels: [
                            {description: "Value chain (supplier to customer) is fully integrated and customer responsive.", level: 3 },
                            {description: "The design of the offering fits customer demand.", level: 2 },
                            {description: "We document what we hear from our customers.", level: 1 },
                            {description: "Our offering design “is what it is” and customers apparently like it.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',                
                            'The form or characteristics of your offering ought to be easy to describe and give advice upon, but in many cases, surprisingly, it is not.  There are so many different types of businesses and nearly an endless supply of existing or new “things” and “services” to be offered to a customer over time.  In addition, suppliers are regularly tweaking and updating their own offering and you realize there are likely too many changes to stock up on.  And if you are a manufacturer / provider of the offering, then you are also likely changing things to make sure you continue to be relevant in the market.',                
                            'Knowing what little tweak or update will make a difference to your customer can be a challenge for the business owner.  And stocking up on last year\'s featured items has its own problems (unless your business is designed to do that and your customers expect that).',               
                            'What does your business do in terms of designing the product line or services to your customers?',
                            'What form features make a difference for your customers?  ',
                            'What form features should you put on your shelves for the coming years?',
                            'Answering these questions requires you to have done your research and know where the state-of-the-art demarcation line exists for your offering.  And you may have to test new ideas with customers, or ask them “Would you like this feature if I offered it?”',               
                            'Generically, and in a non-intrusive and casual way, ask customers about what makes a difference for them:  ',               
                            {ordered: [   
                                'features, ',
                                'fit, ',
                                'use-cases, ',
                                'new applications, ',
                                'better performance, ',
                                'value proposition revisions, ',
                                'price, ',
                                'quality changes, ',
                                'response time, ',
                                'disposable / long lasting, ',
                                'green, ',
                                'cool, ',
                                'leading edge, ',
                                'rarely changing, ',
                                'reliable, and so on.'
                            ]},
                            'Then you can make more informed decisions about what form and features you should offer in the future.',
                            'Lastly, consider new contractual relationships with your suppliers that allow for flexibility and change.',
                            'In short, include customers in the design of your offerings.  This is a chance to create partnerships with your customers simply by how you communicate as you conduct your daily business.'
                        ]
                    },{
                        id: 6,
                        rank: 7,
                        selection: -1,
                        notes: '',
                        menuName: '… ilities',
                        processName: '"… ilities" of your offerings have been put into effect', 
                        description: 'You have full offering support: 1) capability, 2) compatibility, 3) reliability, 4) supportability, 5) testability, 6) profitability, 7) manufacturability, 8) supply-ability, 9) deliverability, 10) new technology-able, 11) acceptability (technically & peers), 12) irresistibility, 13) repeatability, 14) quality.',
                        maturityLevels: [
                            {description: "We can demonstrate more than 9 items from the list.", level: 3 },
                            {description: "We can demonstrate more than 7 to 8 items from the list.", level: 2 },
                            {description: "We can demonstrate more than 4 to 6 items from the list.", level: 1 },
                            {description: "We can demonstrate more than 0 to 3 items from the list.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',               
                            'In some cases a business has a product or service that they believe customers want and then supply it to them (sort as a middle connector).  Other owners may participate in the design of that product or service as part of an integrated supply chain.  And others essentially manufacture or create products and services in response to customer / market demands.  These approaches work.  ',               
                            'Now, the question to consider is, “What is your ability to deliver?”  ',
                            ' Does the product or service work?  ',
                            ' Can it be “on the shelf” or in the customer\'s hands on time?  ',
                            ' Is it at a quality level and price needed?',
                            'The way you answer these questions are associated with the “… illities” associated with the offering.   It means you actually have thought through commercialization design of the offering from inception to delivery.',
                            'Consider some of the many “ab-illities” that should be completely thought through and defined:  ',
                            {ordered: [
                                'capability, ',
                                'compatibility, ',
                                'manufacturability, ',
                                'supportability, ',
                                'testability, ',
                                'profitability, ',
                                'reliability, ',
                                'supply-ability, ',
                                'deliverability, ',
                                'new technology-able, ',
                                'acceptability (technically and peer groups), ',
                                'resistibility, ',
                                'repeatability, ',
                                'quality, and on and on.  '
                            ]},
                            'All these processs affect expenses along the way.  The answers to your offering\'s “ab-illities” affect price, profit, and customer receptiveness.  A plan is needed for them—even if to say this one or that one does not apply.  You don\'t need surprises.',
                            'This is a bit more than supply chain analysis.  It is more about expanding your relationship with, and participation in, the supply chain itself - and how decisions get made all along the way.  If you think about it, if you are the seller, then your suppliers depend upon you to know well what the customers value.',
                            'This analysis work and good supply chain decisions lead to less uncertainty.'
                        ]
                    },{
                        id: 7,
                        rank: 1,
                        selection: -1,
                        notes: '',
                        menuName: 'Performance',
                        processName: 'Performance of the product or service offerings', 
                        description: 'You have successfully integrated all the offering factors that make a difference, made great trade-offs, made good product / product line or service decisions along the way; and now your customer offerings actually work and deliver value as designed and promised.',
                        maturityLevels: [
                            {description: "Our offering(s) exceed our promises and our customer expectations.", level: 3 },
                            {description: "Our offerings consistently and reliably perform as the customer expects.", level: 2 },
                            {description: "We are working to identify performance issues in our offering(s).", level: 1 },
                            {description: "Our offering fails to perform fully as promised, but we have a good return policy.", level: 0 },
                        ],
                        advicePageBreakIndex: 3,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Sometimes you may make inadequate/ill-informed decisions, or perhaps fail to address important decisions, or maybe rely upon the word of your suppliers (or yourself if you create the product/service).  Sometimes there just isn\'t enough time in the day to go check what at first glance seems to be a minor detail.  Even when you are actively involved, mistakes still occur in the supply chain.  This happens to most businesses, and, as a result, they often have to create a great return policy to accommodate errors.  But a return-for-cause is a non-value adding (wasteful) activity that reduces profit.',
                            'Businesses advertise for customers to buy the product or service from them because they: are trusted, are reliable, have integrity, deliver on promises and deserve their reputation for a product that is faster, better, cheaper.  Your ability to deliver “proof in the pudding” offering to the customer tells customers whether you are a worthy entity to do business with or not.',
                            'So, how do you know that?  And how do you ensure performance so your claims are proved to be true?',
                            'While there is not enough space here to write all that could be done, here is a list of tools and process to get you started:',
                            {unordered: [
                                'Lean',
                                'Six-sigma',
                                'QFT and quality controls',
                                'Metrics tied to performance',
                                'Work culture analysis / how you treat accountability',
                                'Process design',
                                'Communication skills'
                            ]},
                            'Consequence management',
                            'Performance issues beg for a bit of research and root-cause analysis.  Here are some basic processes you can put to use:',
                            {unordered: [
                                'Pareto Chart',
                                '5 Whys',
                                'Fishbone Diagram',
                                'Scatter Diagram',
                                'Failure Mode and Effects Analysis (FMEA)'
                            ]},
                            'And beware that causes could be anywhere and anything along the value supply chain.  ',
                            'Once the research and analysis is done it will point to actions that can correct issues, and in turn, make things better.'
                        ]
                    },{
                        id: 8,
                        rank: 8,
                        selection: -1,
                        notes: '',
                        menuName: 'Standards',
                        processName: 'Standards the product offering achieves', 
                        description: "The criteria by which your offerings achieve competitive success is shown by your market's behavior, including; Customer approvals, competition exits, referrals, patents, regulations satisfied, fit with social norms.",
                        maturityLevels: [
                            {description: "It is the market leader: Our offerings achieve, exceed or are the benchmark.", level: 3 },
                            {description: "We are using our system to measure our offering(s) against a benchmark.", level: 2 },
                            {description: "We are building a system to track this kind information.", level: 1 },
                            {description: "We currently do not collect this kind of information.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Chances are you have struggled.  You have competition. Some of you are reactive and may be in a continuing crisis-management modality (almost as a way of doing business).  This is unpleasant and is likely not what you wanted to do in your business in the first place.  Maybe it isn\'t quite that bad, but still things are not great and money is not pouring in as desired.',
                            'Performance standards are important in two ways: ',
                            {ordered: [   
                                'they give you the rules, and ',
                                'they give you insight on how to properly break them  '
                            ]},
                            'The rules are like a definition of the state-of-the-art and there is often a market leader or expert or technology that holds the record.  But every now and then an athlete like Bob Beamon comes along and breaks the long-jump record by massive proportions.  You, your customers, and investors would all like that kind of performance because it would be a win for everyone.',
                            'This particular business process is almost like aggregating everything into a single grade.  You can tell what that grade is by the behaviors of people outside of your control.  So, this process recognizes how well you have responded to the other processes in this assessment.',
                            'Consider the following external behaviors you could observe.  Let\'s assume you have been so successful that:',
                           {unordered: [ 
                                'Customers are lining up at the door',
                                'You exceeded your server capacity on line',
                                'Other competitors leave and try to find another market space',
                                'You are a barrier-to-entry to new competitors',
                                'You are being studied as to what went well'
                            ]},
                            'If not, perhaps you have performance issues as compared to the alternatives customers have available to them, it begs you to pause and analyze what is actually happening versus what you hoped would happen.',
                            'Prepare to be humbled by what you may find in a root-cause analysis.  At the end of your research, it is likely that one of the processes in this assessment has been found to be “wanting.”  Now you know what to go do.'
                        ]
                    }
                ],
                testout: [
                    'The Vector Reports Recommendation for your Category of Product processes is as follows:',
                    {ordered: [
                        'Spend your time and resources improving any other process Categories you did not rate at full maturity before continuing to develop this PRODUCT Category of Processes. Then, leverage all your high-rated processes to assist your overall organizational development efforts.',
                        'When you have achieved a full “Foundational” performance level for all process Categories (all scores at a “three”) then consider working on higher levels of maturity for your business, or consider contacting a consultant / coach to envision what could come next in your journey.']},
                    'It is time to “lift your head up and see in a new way.”  And ask your team as it stands on this solid foundation of Product processes, “What\'s next?”',
                    'Here\'s a glimpse into two higher cmmi-like levels of development—Levels 4 and 5.  Both of them require expanding one\'s point of view from what “I” can do as a business to what “we” can do together.  And the “we” quickly grows to include entities outside of your business in “community.”',
                    'A Level 4 description for your Product Category might include behaviors such as:', 
                    {unordered: [
                        'Every process owner works to support the success of the other process owners in this and the other Categories of processes to transform from a collection of good processes to team-based success standards.  This might mean what you learn from customer interviews and feedback would be provided to the owners of the “…iliites” or “Performance” processes; or informing the process owners of the Market Category processes of “Competitors” or “Differentiators.”  All to build team (business) success.',
                        'Process owners in this Category might begin to work with external suppliers in new ways to better support your own customers—perhaps building and supporting pull systems throughout the value chain.']},
                    'A Level 5 description for your Product Category might include behaviors such as:', 
                    {unordered: [
                        'Your entire organization is focused on continual improvement. Your company is leading the industry.  And as proof of that you are being benchmarked, invited to speak at industry association sessions.',
                        'Your company is a full member of the community it operates within.  As proof of that you are a good and charitable corporate citizen, you help build business ecosystems that grow the economic pie.']},
                    'Vector Reports wishes you well in your continued journey.'
                ]
            },
            {
                category: 'Market',
                axis: 'West',
                rawSum: 0,
                average: 0,
                didView: false,
                intro: {
                    header:  'Your Market Processes',
                    paragraphs:[
                        '<span style={{color: "red"}}>Pause, and shift your thinking again!</span>',
                        '<b>Market:</b> What you know about, and how you learn from, your customers.',
                        'These Market processes focus on the environment in which you conduct (or could conduct) business. This is intended to help open your eyes to what is going on around your business as it relates to your customers, where they are, and choices they make.',
                        '<span style={{fontWeight: 600, fontSize: 16}}>Frame of reference</span>',
                        'The frame of reference you use when rating processes is from your perspective and understanding of your customer(s), where they are located, and your competition. It means this is about YOU and how you perform the work of interacting with and listening to your existing (and future) customers in the environment / space they are located, and why they make the choices they do.',
                        'Please shift your thinking back to YOU and the business environment you operate within and how that drives the choices YOU make.',
                        'If you do not know that information right now, you might consider postponing this assessment until you actually have defined your business operational environment. Or, you might proceed with the assessment to learn what you ought to be seeking about your market.',
                        'These eight processes are ALL about key market forces that should drive business connections to that market:',
                        '<ul><li>Market domain of the product or service offering</li><li>Opportunity discovery</li><li>Customer perception of your business&#39; value proposition</li><li>Market Type your business operates within</li><li>Competitor intelligence</li><li>Differentiation of business/offerings from competitors</li><li>Potential market and expansion</li><li>Channels to and from the market</li></ul>  ',
                        '<span style="font-weight: 600; font-size: 16">Proceed</span>',
                        'Remember, as you select your maturity Level Rating for each process that you read the criteria from the bottom-up. Then select the lowest level criteria description that the process fully satisfies. This means even if you have partial higher-level criteria satisfied, you should not select that higher level.',
                        'Click to begin rating processes:',
                    ]
                },
                processes: [
                    {
                        id: 1,
                        rank: 8,
                        selection: -1,
                        notes: '',
                        menuName: 'Market Domain',
                        processName: 'Market domain of the product or service offering',
                        description: 'You clearly understand the market space or domain your business and offerings are within.  You have identified your market; 1) segment, 2) characteristics, 3) customer type, 4) size & potential, 5) problems and solutions needed, 6) readiness, 7) behaviors.',
                        maturityLevels: [
                            {description: "We can demonstrate 6 to 7 items from the list.", level: 3 },
                            {description: "We can demonstrate 4 to 5 items from the list.", level: 2 },
                            {description: "We can demonstrate 2 to 3 items from the list.", level: 1 },
                            {description: "We can demonstrate 0 to 1 item from the list.", level: 0 }
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Some business owners start out with the idea of wanting to start a “_fill-in-the-blank_” kind of business.  Other owners may need to make money now and respond to an opportunity to buy/build an existing business.  For whatever reason you started your business—good for you.',
                            'But, anyone can start a business.  The challenge is to build a business that lasts.  Some critical thinking needs to occur when starting a business, or when addressing problems in an existing and/or struggling business.',
                            'Entrepreneurs, who are a bit different from long-term business owners, seek disruption, differentiation from competition, strategic advantage, accessibility to a large and untapped market, and so on.  Using this thinking one would not open another seafood restaurant next door to three others just like it, right?',
                            'Those who have been in business for awhile seek sustainment, growth in market share, and defeating the competition.',
                            'In both business-stage situations you need to know your market and your target market segment; which means you know your customer (in the abstract—knowing their characteristics).  This is so you can create something special in your market that clearly identifies you (and you alone, if you can).  ',
                            'Learn what your market characteristics are, which ones are good to know versus critical to know, and what that market needs that it doesn\'t really have access to now, or what differentiates you from other competitors.  ',
                            'Then go deeper and learn your ideal customer (as an archetype) and their characteristics so that you can respond to their needs with your offerings.   ',
                            'This knowledge guides what you offer to them (product, features, functionality, price, etc.).  It guides you to relate with your market in a way that the market readily understands.   For example, what if an offering called “~6qb!” is EXACTLY what the market may need.  But what the heck is … ~6qb! … ?  ',
                            'Your innovation, your better mousetrap needs to be something that the market finds recognizable. If may be something the customer may actually need, but it is too far in the future, too hard to understand, or too hard to learn today, for the customer to relate to it and respond the way you know they will someday—this needs to be now!  ',
                            'Connect the dots between your offering(s) and customer need.  ',
                            'Work to find your fit in the market space you operate within.  Help your customers know you, know your product, and know what it can do for them that makes their life … better.  Then, with all that at hand, create a business identity around it.'
                        ]
                    },{
                        id: 2,
                        rank: 2,
                        selection: -1,
                        notes: '',
                        menuName: 'Opportunity Discovery',
                        processName: 'Opportunity Discovery',
                        description: 'You always have your eyes open to see the “old” in a new way, or see something no one else sees, but you know is true—in a business context of discovering what\'s next in terms of what you might be able to accomplish.  We seek moments when we say "Ah-ha!  I could build a business around that.”',
                        maturityLevels: [
                            {description: "Discovery is \"built in\" and our market awareness leads to insights.", level: 3 },
                            {description: "We apply a discovery process as needed to design new offerings.", level: 2 },
                            {description: "We are defining a discovery process with our customers and our team.", level: 1 },
                            {description: "We occasionally encounter new opportunity \"Ah-ha\" moments.", level: 0 }
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Often we are swamped by the urgency of the day and become lost inside working the current challenge or solving problems from the past.  Of course there are times when you must do this.',
                            'However, it only takes a synaptic connection to have an “A-ha!” moment that recognizes possibilities for the future.  It is a mindset, that even while you are solving the problems of today, you are always ready to receive information about how and what you may do differently in the future to make money.  That business mindset, or opportunity-recognition mindset, is about both awareness and openness.  That is what allows or facilitates the mind\'s eye to see things out of the context in which it was observed, and instead, see it in the context of future business.',
                            'This is not about establishing a structured approach that says, “Today at 4 PM we are all going to sit down and be creative and see the future.”  Instead, this operates mostly at the subconscious level and unexpectedly emerges as an “A-ha!” at any time and unexpected place. But, that said, it is recommended to listen to your employees because they are the ones interacting with the customers and have the greater chance for insights (be sure that it is everyone\'s role to listen to the customer when approached by them).',
                            'Opportunity discovery is like a mental muscle that needs thought-exercise to perform better.  ',
                            'Try this thought exercise: Tell your mind you want to grow your business; turn off your inner critic (sometimes those inner voices are called \'chattering monkeys\'); open yourself to see existing things and existing behaviors in a new context; then, give your subconscious permission to work on it and to then send a message whenever it\'s ready to your conscious mind.  ',
                            'It may sound silly, but how else do you open your mind to new possibilities … unless you actually open your mind?  Some people go for a run, let go of the struggle for the moment and perform another task, all to quiet the mind and distract your mental judge and jury.',
                            'Some of your early subconscious thought-work may produce really dumb, silly, or impossible ideas.  But here is the trick - DO NOT DISMISS THEM.  You may laugh at them, judge them, or say that won\'t work, but the subconscious saw something you did not.  Take these ideas and place them on either a mental or physical shelf as notes (the latter is better). Later, take a moment to ask yourself what is the message common to these ideas, and now you are on your way to creativity and opportunity recognition.',
                            'Another exercise is empathy. Figure out your customer use cases for your offerings (why they buy them).  Mentally imagine being in their world. Know your customer (problems, needs, wants, interests, solutions) and determine how they judge value.  ',
                            'And lastly, cross-pollinate environments.  Nearly every problem has been solved, metaphorically or in reality, in other disciplines.  Outsiders frequently can easily "see" a new answer that you are blind to.  Ask yourself, “How did they do that in that world?”',
                            'Now combine that with the ideas on the shelf, and just perhaps, something will emerge you would have never thought of before.'
                        ]
                    },{
                        id: 3,
                        rank: 1,
                        selection: -1,
                        notes: '',
                        menuName: 'Perceptions',
                        processName: 'Customer perception of your business value proposition', 
                        description: 'You know both your customer opinions and choices regarding both your business and your offerings: 1) value, 2) rarity, 3) utility, 4) inimitability, 5) non-substitutablilty, and 6) perceptions ―which facilitates your smart entry into adjacent markets for growth.',
                        maturityLevels: [
                            {description: "We can demonstrate more than 5 items from the list.", level: 3 },
                            {description: "We can demonstrate 4 items from the list.", level: 2 },
                            {description: "We can demonstrate  2 to 3 items from the list.", level: 1 },
                            {description: "We can demonstrate  0 to 1 items from the list.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Chances are you consider that your offerings (products or services) to your customers are good.  If you know this is what your customers also think, great.  If you are unsure of what your customer\'s perceptions of your offerings are, or about your business as an entity, then you will need to do some research and find out what is true.',
                            'You are providing a value proposition by being in business.  But, is it the right one that attracts the maximum number of customers for your market?  If modified a bit, could you capture more market share?',
                            'There are characteristics built into your offering, some typically important ones are: ',
                            {ordered: [   
                                'Value (good return for their money), ',
                                'Rarity (can be obtained only from your business), ',
                                'Inimitability (can\'t be copied--no one else has your secret formula), ',
                                'Non-substitutablilty (nothing else can really replace--only yours satisfies), ',
                                'Perceptions (best thing since sliced bread), and ',
                                'Utility (you deliver desired performance).'
                            ]},
                            'But what does your customer think?  How would they respond to the above characteristics regarding your offering(s)?  ',
                            'How would they respond to the following questions?',
                            {unordered: [
                                'What if you incur high expenses to enhance a particular characteristic, but customers don\'t care about it?  ',
                                'What if you improved / perfected another side characteristic that turned out to be critical in customer buying choices?',
                                'What is it about your offerings that customers like or don\'t like?'
                            ]},
                            'There are at least two benefits from conducting this kind of characteristic research about your customer\'s choices regarding your offering\'s value proposition.  ',
                            {unordered: [
                                'You can create efficacy on how you spend money to deliver your product or service.  ',
                                'You may discover other adjacent markets you could enter and serve by modifying the offering a bit.'
                            ]},
                            'Your product line and services are factors that apply to your business identity as well.',
                            'This path allows you to gather information that helps increase the number of customers, increase market share, possibly allow the entry into another market, and supplies information about product/service developments and your improvement process.'
                        ]
                    },{
                        id: 4,
                        rank: 7,
                        selection: -1,
                        notes: '',
                        menuName: 'Market Type',
                        processName: 'Market Type your business operates within', 
                        description: 'You fully understand your market type (new, existing, niche, mature, emerging).  You know your business fits that type because it guides a strategic approach; to your actions, how you advertise to your market, and how you relate with both your existing and new customers.  ',
                        maturityLevels: [
                            {description: "Understood to the point that it guides our strategic efforts and actions.", level: 3 },
                            {description: "We know our market type and fit: It guides our marketing efforts.", level: 2 },
                            {description: "We know our market type and how we fit within it.", level: 1 },
                            {description: "Uncertain of our market type and fit and how to use that information.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Some business owners will shotgun their advertising dollars to the general community to raise awareness.  Sometimes that works, particularly in small communities.  However, it is not hard to see that knowing your customer well allows you to target your marketing messages to those who have an increased likelihood of responding to your advertising campaign.  Dollars spent on that kind of advertising becomes more effective. ',
                            'Knowing your market type also informs you about what kind of advertising works and what doesn\'t.  It informs you about your competitors and how to distinguish your business from them.  It can also shape your actions to better serve your existing customers (to increased business, and to help prevent losing them to competitors).  ',
                            'Here are some simple comments about some market types (Which one is yours?):',
                            {unordered: [
                                'New: Advertise to help customers know you exist, and perhaps even teach customers about this new offering and how it makes a difference for them',
                                'Existing: Demonstrate how you are faster, better, cheaper than competitors',
                                'Niche: Target a special / unique set of customers describing what makes you amazing for them',
                                'Mature: Show the world what makes you different and how you can make things better for your customers',
                                'Emerging: Advertise to help customers know it is not risky to try your offering'
                            ]},
                            'Then for the market type your business falls into, research and identify the answers to the following questions:',
                            {ordered: [
                                'Size: How big is the market potential for your business?',
                                'Competitors: Who are you competing with (should you be?), what are they doing, and how do you distinguish yourself from them?',
                                'Your approach: What is the method that works best to reach your customers in this market?',
                                'Location: Do you have a distance monopoly?  Are your customers near you or away?',
                                'Your fit with it: Do you have a business that is scaled / sized appropriately for this market?',
                                'Regulatory: Are you complying with the “rules of the road” for that market?'
                            ]},
                            'As you analyze your customer characteristics and identify your market type you will come up with other actions you need to take, customized to your market, to improve your business performance.'
                        ]
                    },{
                        id: 5,
                        rank: 6,
                        selection: -1,
                        notes: '',
                        menuName: 'Competitors',
                        processName: 'Competitor intelligence', 
                        description: 'You know your market, which means you also know your competitors.  You can project their operating costs and challenges, supplier costs, know what types of customers they attract, and exactly how they compete with you - all of which helps you shape your strategic plan and pursue / exit business decisions.',
                        maturityLevels: [
                            {description: "Competitor intelligence, as described, guides our decisions.", level: 3 },
                            {description: "We are beginning to create informed competition strategies.", level: 2 },
                            {description: "We are gathering some of this information now.", level: 1 },
                            {description: "Currently we do not have nor collect competitor intelligence.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Existing business owners might sometimes get complacent with the status quo.  Then when a new competitor opens shop down the street they either ignore them or sometimes get angry at them.  This approach ignores wisdom about how to handle business competition. ',
                            'If you have loyal customers they may tell you about the competition in the spirit of spurring you to action to make sure you stay in business (and not intended to anger you or raise your stress level).  Competition is the nature of the beast in business (and often true in people).  Typically, a person\'s first thought is how to beat the competition.  While not thought of often enough cooperation can be a successful strategy.  ',
                            'Analyzing the competition, combined with knowing your market space, gives you an advantage when making decisions about future actions.  If you are that new competitor. It informs you of market constraints and opportunity potential.  If you are the existing business, it informs you how to better respond to keep your customers.',
                            'Competitor intelligence is often simply cold-hearted data analysis that can help you make decisions about your competition.  ',
                            'So, let\'s say you\'re in the game.  ',
                            'Since the rules of the game suggest the better player wins and the other goes home, here are some of the scoring criteria that you must know about yourself and your competitors:',
                            {unordered: [
                                'Strengths',
                                'Weaknesses',
                                'Threats',
                                'Opportunities',
                                'Advantages',
                                'Offering strengths and differences',
                                'Strategy being utilized',
                                'Position in the market',
                                'Supplier and customer relationships'
                            ]},
                            'And this information can lead to better pursue, pivot, pass, or exit business decisions.',
                            'The standard thinking is to be better than the competition in every one of them.  Instead, consider being smarter than the competition.  Let them do the heavy lifting and then take the customer when they see the comparison—like getting a free walk to first base.',
                            'Don\'t find yourself burning daylight worrying about your competition while they are already moving forward.',
                            'Get going yourself.  Make your own path.'
                        ]
                    },{
                        id: 6,
                        rank: 4,
                        selection: -1,
                        notes: '',
                        menuName: 'Differentiators',
                        processName: 'Differentiation of business/offerings from competitors', 
                        description: 'You know your competition well.  And you can easily identify the differences between your business and your competitors (all the way to the product level).  This knowledge has manifested in both your offering\'s characteristics and your marketing efforts.  You know what makes a difference!',
                        maturityLevels: [
                            {description: "We stand apart―and everyone knows it.", level: 3 },
                            {description: "We use differentiation (offerings and ourselves) as a marketing strategy.", level: 2 },
                            {description: "We are defining differences we have, and need to have, to succeed.", level: 1 },
                            {description: "We pursue improved customer-value based upon our goals.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Even if you don\'t know what sets you apart from your competition … your customers do or will in time!  To survive you may need to build a “better mousetrap” or at least produce better advertising that points out the better mousetrap you already built. ',
                            'Gathering competitor intelligence (both organizational and offering levels) and making comparisons to what you offer is the stuff of future change! ',
                            'If you are already “faster, better, and cheaper” then make sure your customers know it.  Consider these marketing/advertising factors to test your offering\'s demand response:',
                            {unordered: [
                                'Product - Show fit with customers wants and needs',
                                'Place - Show the offering where your target market can find it easily',
                                'Price - Show good value for money',
                                'Promotion - Show it to your customers and intended customers (advertise)',
                                'People - Show you build relationships',
                                'Processes -Show how it works for them',
                                'Physical Evidence - Show an end result',
                                'Productivity & Quality - Show the difference your offering makes for customers'
                            ]},
                            'Marketing will help inform you just how responsive your offering is in the market place.  If the results are not what was expected then you may need to look at your offering again and consider new features or something customers will respond to.',
                            'If you not “faster, better, and cheaper” then you have to improve your offering(s) so it is at least one of these factors better (before your customers learn the competitor is better).',
                            'Also consider that it might not be a problem with your offering … it may be a problem with your business.  Ouch!  There are business organizational characteristics that can set you apart or set you back.  Consider differences in your business as compared to other competitive businesses in the following factors: ',
                            {ordered: [
                                'Social, ',
                                'Political, ',
                                'Technological, ',
                                'Natural, ',
                                'Legal, and ',
                                'Identity in your community.'
                            ]},
                            'These softer, subtler, and sometimes unseen factors can be both difficult to change and take longer to change, but they can be changed.',
                            'You\'ve got some data collection and analysis to do!'
                        ]
                    },{
                        id: 7,
                        rank: 5,
                        selection: -1,
                        notes: '',
                        menuName: 'Potential',
                        processName: 'Potential market and expansion', 
                        description: 'You have designed your business to optimally capture the revenue generation potential of the market you are in, will be in, or at least the path to capture it.  You know your market\'s customer demographics and characteristics; and, most importantly, that the market wants your offerings.',
                        maturityLevels: [
                            {description: "We know what\'s next.", level: 3 },
                            {description: "We have solid market capture plans and can demonstrate its application.", level: 2 },
                            {description: "We have some information to use in a market capture plan.", level: 1 },
                            {description: "We have not taken this approach to market capture yet.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Not everyone plans “what\'s next.”  Some business owners are happy with their business position and its performance - good for them.  These people will be okay until something changes and impacts them such as―Economics, competition, customer demographics, or other unanticipated change (seismic or small).',
                            'However, others want to grow their business beyond what that current state is today—good for them.  And it is not simply a matter of increasing the number of customers, because even when businesses possess the highest levels of market-share capture, their owners can think about and can ask, “What can I do next to expand and grow?” Or "How do I transform my business to the next level?"',
                            'You likely know your costs of doing business and, yes, you could work to reduce expenses and increase profit.  That is a good thing to do, but is not the subject of this topic.  This is about your potential, how do you quantify it, where are those future customers, what do they want, and what would it take to capture a share of that larger market? ',
                            'After you have collected your market and customer demographics, do some spreadsheet work to create a new pro forma (that includes revenue models and predictions) as though you were starting a new business.  And that “as if” thinking is critical for this analysis.  You have to let go of today and think about what could be.',
                            'You will discover and can then prepare the right channels to reach your market\'s customers.  Then make plans to grow your company organically to the envisioned larger company.',
                            {unordered: [
                                'What is your Total Market (TM)?',
                                'What is your Total Accessible Market (TAM)?',
                                'What is your Serviceable Accessible Market (SAM)?',
                                'What adjacent markets might your offering fit with?',
                                'What changes in your offering might be attractive to other markets?',
                                'What market types support a change in your business size?',
                                'What messaging channels fit and will reach these new market / customer demographics?'
                            ]},
                            'But what about inorganic growth?  ',
                            'There are all kinds of other formations, for example: Teaming, partnerships, collaborations, mergers, acquisitions.  If the potential in the market is there, perhaps you might find efficiencies and opportunities in not going it alone.  Strategic alliances, even with competitors, can be designed in a win-win manner.',
                            'After answering those questions, build your pro forma spreadsheet.  Create the future on paper, so-to-speak, and then create a plan to close the gap from today to that newly designed future.  ',
                            'The results of that effort will become a mini-business plan for expansion.  And in that plan create progress milestones, defined and characterized with appropriate metrics, which will indicate performance and progress to your imagined future.  A cogent plan, with proof, will attract capital to fund your growth plan.'
                        ]
                    },{
                        id: 8,
                        rank: 3,
                        selection: -1,
                        notes: '',
                        menuName: 'Channels',
                        processName: 'Channels to and from the market', 
                        description: 'When reaching out and connecting with your customers you know what works, what doesn\'t, how effective and efficient your messages are, in the channels you use to reach, convince and obtain new and retain existing customers.',
                        maturityLevels: [
                            {description: "We use our $ spent per customer capture ratio to efficiently reach them.", level: 3 },
                            {description: "We  demonstrate effective use of channels and an increase in customers.", level: 2 },
                            {description: "We have a design for communication channels we will use in the future.", level: 1 },
                            {description: "We currently do not have an overall channel design.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'No doubt you already have communication and marketing channels for your existing customers.  You may be using a shotgun approach, or perhaps simply make a bigger sign.  It all helps a bit.',
                            'And there is a marketing saying that essentially says “If your customers don\'t know about your business, then your business doesn\'t know their customers.”  And if they don\'t know about you, or can\'t find you, then it might be hard to make payroll.',
                            'The question becomes: “In a money-scarce world (and arguably even in a plentiful world), how efficient are your advertising dollars being spent?”  Some businesses use a ratio to measure effectiveness and efficacy of their advertising budget as cost-per-newly-acquired-customer.  This metric can help you decide which capture efforts work best for your target market.  ',
                            'For the moment let\'s ignore the telephone, mail, email, TV, radio, web based messaging - why? - because you have to have the fundamentals down first!  ',
                            'Think about it. If you market an insufficient product or service … people will talk … and that is a longer and more arduous road to change perceptions.',
                            'Here are some ways to connect with your customers:',
                            {ordered: [
                                'Store-front presence - literally a physical store with a big sign (per regulations) [a nice to have]',
                                'Web presence - today if you don\'t have a website it is as if you are not really real or exist [a must-accomplish item]',
                                'Customers selling for you - this is a freebie that is incredibly powerful when done well [a must-accomplish item]',
                                'Natural monopolies - only one in the community that offers what is needed [luck, but maybe not for long?]',
                                'Affinity groups - creating groupies! [tied to #3]',
                                'Identity - who you are in the community, your reputation, social status, and the difference you make to both the market and as member in your community [a must-accomplish item]',
                            ]},
                            'Some thoughts about #3 and #6 are things that happen as a result of you being friendly, helpful, focused on customer success, and helping the larger community when/where you can.',
                            'When you create positive customer relationships and experiences they will often “go to bat” for you, cheer for you, comment on-line for you, tell their friends about you, warn you of competition, share new ideas with you … all because they feel a psychological affinity with you, both personally, and organizationally. ',
                            'When you have ideas exhausted the things you can do on your own get help from a marketing professional (either as a new employee with that experience or as a purchased service).'
                        ]
                    }
                ],
                testout: [
                    'The Vector Reports Recommendation for your Category of Market processes is as follows:',
                    {ordered : [
                        'Before you develop this Category much further, spend your resources improving any other process Categories you did not rate at full maturity. Then, leverage these high-rated processes to assist your overall organizational development efforts.',
                        'When you have achieved a full “Foundational” performance level for all process Categories (all scores at a “three”) then consider working on higher levels of maturity for your business, or consider contacting a consultant / coach to envision what could come next in your journey.']},
                    'It is time to “lift your head up and see in a new way.”  And ask your team as it stands on this solid foundation of Market processes, “What\'s next?”',
                    'Here\'s a glimpse into two higher cmmi-like levels of development—Levels 4 and 5.  Both of them require expanding one\'s point of view from what “I” can do as a business to what “we” can do together.  And the “we” quickly grows to include entities outside of your business in “community.”',
                    'A Level 4 description for your Market Category might include behaviors such as:', 
                    {unordered : [
                        'Every process owner works to support the success of the other process owners in this and the other Categories of processes to transform from a collection of good processes to team-based success standards.  This might mean what you learn from customer interviews and feedback from the owners of the “Opportunity Discovery” or “Perceptions” processes is provided to “Channels”; or informing the process owners of the Business Category processes of “Decision-making” or “Future Focus.”  All to build team (business) success.',
                        'Process owners in this Category might begin to work with external suppliers in new ways to better support your own customers—perhaps building and supporting pull systems throughout the value chain.']},
                    'A Level 5 description for your Market Category might include behaviors such as:', 
                    {unordered : [
                        'Your entire organization is focused on continual improvement. Your company is leading the industry.  And as proof of that you are being benchmarked, invited to speak at industry association sessions about where the future is headed.',
                        'Your company is a full member of the community it operates within.  As proof of that you are a good and charitable corporate citizen, you help build business ecosystems that grow the economic pie.']},
                    'Vector Reports wishes you well in your continued journey.',
                ]
            },
            {
                category: 'Business',
                axis: 'South',
                rawSum: 0,
                average: 0,
                didView: false,
                intro: {
                    header:  'Your Business Processes',
                    paragraphs:[
                        '<span style={{color: "red"}}>Pause, and shift your thinking <b>again!<b></span>',
                        '<b>Business:</b> The organizational infrastructure utilized to support your endeavors.',
                        'These Business processes focus on activities you perform that support an entity capable of conducting business. These are the business processes that enable your organization to have a Product that responds to a Market.',
                        '<span style={{fontWeight: 600, fontSize: 16}}>Frame of reference</span>',
                        'The frame of reference you use when rating processes is from your perspective and understanding of yourself. It means this is about YOU and your ability to create an organizational entity—that can do something, that can act, and can make decisions that lead to your goals.',
                        'Please shift your thinking to working “on” your business from working “in” your business. It is easy for leaders to get caught up in the daily challenges and delay or neglect the important work of making the business successful. Consider how leadership, your designs, future thinking, the time frame of choices and more interact in a way that enables you to run your business from now into the desired future.',
                        'If you do not know that information right now, you might consider postponing this assessment until you actually have defined your OWN operational environment. Or, you might proceed with the assessment to learn what you ought to be seeking from yourself about your business.',
                        'These eight processes are ALL about key business topics that should drive business decisions:',
                        '<ul><li>Decision-making for business operations</li><li>Profit model and planning</li><li>Organizational infrastructure</li><li>Partner Plan for performance and growth</li><li>Future focused performance</li><li>Capital Planning and Growth Process</li><li>People and skills needed for success</li><li>Work performance metrics</li> </ul>',
                        '<span style="font-weight: 600; font-size: 16">Proceed</span>',
                        'Remember, as you select your maturity Level Rating for each process that you read the criteria from the bottom-up. Then select the lowest level criteria description that the process fully satisfies. This means even if you have partial higher-level criteria satisfied, you should not select that higher level.',
                        'Click to begin rating processes:',
                    ]
                },
                processes: [
                    {
                        id: 1,
                        rank: 3,
                        selection: -1,
                        notes: '',
                        menuName: 'Decision Making',
                        processName: 'Decision-making for business operations',
                        description: 'You have made decisions ranging from the small / insignificant to the large / critical. Your decisions were informed by metrics, research and disciplined process.  You have looked for bigger patterns, how they affect the future and then made your choices … and when in doubt you run it by your board.',
                        maturityLevels: [
                            {description: "Board reviews & systemically analyzed data-driven decisions.", level: 3 },
                            {description: "We use data  for decisions & are starting a guidance board.", level: 2 },
                            {description: "We use decision tools to help choices & have board candidates.", level: 1 },
                            {description: "No guidance board yet and decisions tend to be without metrics.", level: 0 }
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'There are many examples of business leaders making enteric decisions (gut calls).  This is the process people use when in an information vacuum. But these decisions are inherently filled with risk and made worse when the severity of consequence of being wrong is significant.  Guessing is not the optimal solution.  Some seek guidance or form advisory boards (which is recommended) populated with experienced individuals who can prevent you from falling into pitfalls. But also be wary of only using past experience to make a decision about the future—"driving forward by looking through the rear view mirror." ',
                            'Today, information is critical to good decision-making.  But it is not simply about having a lot of data--it is more important to have specifc data that lends itself to consequence, evidence trails, and meaning.',
                            'Solutions fit inside a larger context and so does decision-making.',
                            'A common mistake once you do start to get data is to become derailed from the task by focusing upon the research, the interpretations, or getting more data.  But what is important is to have sufficient (not all) data to make an informed choice.  Early on, consider expanding your view and framing the structure of the information needed to provide or suggest answers consistent with a larger perspective.  Take the time to understand the larger context.',
                            'Once that data window is open you can see there are business fundamentals and tools that can help guide your actions.  Utilize proven analysis and productivity tools such as: ',
                            {ordered: [
                                'SWOT (strength, weakness, opportunity, threat) analysis, ',
                                'project/process mapping (for scheduling and sequences), ',
                                'QFD (quality function deployment) for finding relative priorities among options, ',
                                'TRIZ, ',
                                'Kepner-Trego analysis, ',
                                'decision trees, and more.  '
                            ]},
                            'These tools, and others, help you understand the larger context, competition, market and customer demographics, trends, impossible decisions, and more.  Using these tools can help structure your data into information that guides your decisions and subsequent action.  ',
                            'Now, while that is all fine and dandy, businesses are not all cold-minded machinery focused like automatons.  You must also balance your head (data/logic side) with your heart (sense of community, empathy) because the latter is what better attracts customers.'
                        ]
                    },{
                        id: 2,
                        rank: 4,
                        selection: -1,
                        notes: '',
                        menuName: 'Profit Model',
                        processName: 'Profit model and planning',
                        description: 'You have analyzed various business models and selected the optimal one to reach your target customers to provide value to them and to your business.  Your plans, and progress, lead to modifications, pursuits, pivots, or exits as the market and economic conditions change and evolve.',
                        maturityLevels: [
                            {description: "Our profit and business models are aligned with our market.", level: 3 },
                            {description: "We are testing models and plans for optimal results.", level: 2 },
                            {description: "We are learning about profit and business models now.", level: 1 },
                            {description: "We follow our “gut” and existing norms about making money.", level: 0 }
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Many people start a business based upon what their life experience was regarding what it means to be “in business.”  Sometimes this experience is right on target and other times not.  Some business school graduates think they know how to run a business and run smack into the “school of hard knocks.”  ',
                            'The point here is that it isn\'t what you know, but rather how fast can you can learn and pivot to the right model when appropriate—learning what business and revenue model best fits with your target market today that allows for flexibility tomorrow.  These all lead to decisions to pursue, pause, pivot or exit.  And a good exit plan is often overlooked.  Not only for individuals exiting, but also when it may make sense to sell the business itself.',
                            'Consider the following business models:  ',
                            '1) Manufacturer, 2) Distributor, 3) Retailer, 4) Franchise, 5) Brick-and-mortar, 6) eCommerce, 7) Bricks-and-clicks, 8) Nickel-and-dime, 9) Freemium, 10) Subscription, 11) Aggregator, 12) Online Marketplace, 13) Advertisement with free stuff, 14) Data Licensing / Data Selling, 15) Agency-Based, 16) Affiliate Marketing, 17) Dropshipping (version of eCommerce), 18) Network Marketing, 19) Crowdsourcing, 20) Peer 2 Peer Catalyst/Platform, 21) Blockchain, 22) SAAS & IAAS & PAAS “as service” models, 23) High Touch (typically services), 24) Low Touch, and so on.',
                            'And then consider the following revenue models: ',
                            '1) Production, 2) Manufacturing, 3) Construction model, 4) Rental or leasing, 5) Advertising, 6) Sponsored ranking, 7) Commission, 8) E-commerce, 9) Fee-for-service, 10) Licensing, 11) Software licensing, 12) Shareware, 13) Donationware, 14) Nagware, 15) Crippleware, 16) Freemium, 17) Markup, 18) Wholesale, 19) Retail, 20) Brick and mortar, 21) Mail order, 22) E-tail, 23) Subscription model, 24) Long-tail.',
                            'Which ones apply to you?  Which one should you be considering that you currently are not?  Match up the business and revenue models that work for you and then make plans accordingly.  And those plans are “what if” spreadsheet pro forma examples “as if” you were using various pairs of business and revenue models.  Then, you put on your strategic thinking hat and exercise your decision-making skills to choose ones that fit.  ',
                            'And if those models fail - maybe you can create a hybrid or something entirely new.  Then, when the world changes again, be prepared to pivot to another model as needed (temporarily or for the future) until things change again.'
                        ]
                    },{
                        id: 3,
                        rank: 6,
                        selection: -1,
                        notes: '',
                        menuName: 'Organization',
                        processName: 'Organizational infrastructure', 
                        description: '“No man is an island” and this is true for business.  You have built a business infrastructure (processes, functions and means) that is integrated with your employees, your supply chain, and with your community that demonstrates your partnership in the whole system.  ',
                        maturityLevels: [
                            {description: "We are fully integrated with, and contribute to the whole system.", level: 3 },
                            {description: "We are developing roles and partnerships with external entities.", level: 2 },
                            {description: "We have an org. structure (not connected to the whole system).", level: 1 },
                            {description: "We are focusing on ourselves right now.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Organizational infrastructure is one of those things a business needs, but you can\'t really point to it and say, “See, there it is.”  Yet, you really cannot operate without it—or if you do, chances are you\'re heading to mistakes, lawsuits, waste, increased expenses, and possibly loss of the business itself.',
                            'It is not the people, but they use it.  It is not money, but it is spent on it.  It is not your product or service, but it guides what it is and how to deliver it.  And organizational infrastructure has three levels to it: Process, Structures, and Interdependence.',
                            'The process level consists of the systems, protocols, and methods that give structure to the organization, support its key functions, and embed routine practice—the rules of the road to your employees on how to conduct business:',
                            {unordered: [
                                'Job / Work Analysis: defines what needs to be done and who will do it',
                                'Organizational Design: defines the management and reporting structure',
                                'Records Management: defines how information is stored digitally / physically',
                                'Facility Planning: defines how workspace is functionally configured',
                                'Product / Service Delivery Process: defines how your core operations work ',
                                'Processes and procedures: define and document how to perform, measure and improve key processes'
                            ]},
                            'The structure level consists of the foundational systems the business organization uses to enable key functions to operate-the tools of the trade your employees utilize to conduct business:',
                            {unordered: [
                                'Business planning: defines your vision, mission, tactics, goals, metrics and consequences ',
                                'Organizational functions: HR, IT, Manufacturing, Legal, Accounting, etc.',
                                'Governance or Advisory Boards',
                                'Platforms: provide the tools to do the work (payroll systems, email, equipment standards, etc.)',
                                'Change processes: models or methods associated with continuous improvement',
                                'Code of conduct: defines desired behaviors and helps deliver consistent customer value'
                            ]},
                            'The interdependence level consists of external systems the business organization relies upon to conduct business (Government, regulations, fellow businesses, services) all working together.',
                            'Are you missing any of the levels or activities in those levels?  If yes, then you know the work ahead of you.',
                            'As your business grows and matures add elements of each level until you are self-sufficient as an interdependent member of your larger business and customer community.'
                        ]
                    },{
                        id: 4,
                        rank: 7,
                        selection: -1,
                        notes: '',
                        menuName: 'Partners',
                        processName: 'Partner Plan for performance and growth', 
                        description: 'It is not what you know; rather it is who you know and your relationships with them that leads to success.  You have worked to build a network of business-to-business partnerships that facilitates business growth in your ecosystem.  This includes suppliers, complimentary businesses, investors and your community.',
                        maturityLevels: [
                            {description: "We have vertical and horizontal business network relationships.", level: 3 },
                            {description: "We are building relationships that enable performance and growth.", level: 2 },
                            {description: "We are identifying people, entities, roles for needed relationships.", level: 1 },
                            {description: "We are new to our field and know we need to establish a network.", level: 0 },
                        ],
                        advicePageBreakIndex: 3,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'It can be frustrating sometime, but it is true that “It is not what you know; but who you know (and your relationship with them), that leads to success."  Every business has problems to solve.  Doing it all on your own, bootstrapping it, being utterly self-reliant, while sometimes being seen socially as a virtue can actually become a hindrance to future growth.  We live and we work in interdependent communities.',
                            'When you enter the business world you are entering a community of networked relationships.  The value proposition, product or notion functionality, all serve to be merely the key to open the door.  The rest is building relationships, creating business deals, and finding previously unseen opportunities—many of which come from your business connections.',
                            'One strategy or approach to help build these relationships is to build your future supply chain and future Business-to-Business (B2B) partners into your plan for growth.  If you think about it, there is no need to compete with everyone.  In fact, you might find businesses who offer products and service that compliment your own.  If you build solid relationships with them you can establish a mutual customer referral norm.  If you are independently wealthy then you do not need a banker or outside investors, but if you are not that lucky then you need “friends in the business” that you can rely on for help (and vice versa).',
                            'You can even formalize partnerships or merge or acquire other businesses, when appropriate, to help you achieve your forecast for future performance and growth.  This expands to government and regulatory institutions as well.  Think of what could be accomplished if you could help shape governance.',
                            'When the day comes for you to exit the business, your network can help you there as well.',
                            'Give these other business and organizational entities access to you, give them a voice and influence with you as well … as that is how you gain influence with others.'
                        ]
                    },{
                        id: 5,
                        rank: 5,
                        selection: -1,
                        notes: '',
                        menuName: 'Future Focus',
                        processName: 'Future focused performance', 
                        description: 'You have built a time reference into all your business plans.  Everything you do is guided by your vision.  Essentially; 1) you envision the future, 2) you act in the present to build that future, while, 3) solving problems of the past in a way that prepares for the future.',
                        maturityLevels: [
                            {description: "We track our performance against our future focused plans.", level: 3 },
                            {description: "Our vision, strategy and plans are future focused & guide actions.", level: 2 },
                            {description: "We have a business plan, but not all of it is future focused.", level: 1 },
                            {description: "We have more hopes and dreams than plans and strategies.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'It is natural behavior to respond to the task at hand.  But is that the right thing to do right now?',
                            'The only way to really know the answer is to have a strategy and a plan and implement it.  As has been said, “Don\'t let the urgent distract you from the important.”  And typical advice to leaders, “Don\'t work IN the business, rather, work ON the business.”  Yet, most of us simply respond to the perceived crisis of the moment when we do not have an actionable vision, a plan to implement it, and tracking performance metrics.  ',
                            'Most everything you do should be part of a larger strategy, perhaps a step in your growth plan; for you, your employees and your business.  Some say I don\'t have time (and that could be true in the moment), but really, if you 1) envision the future, 2) act in the present to build that future, and, 3) solve the problems of the past in a way that prepares for the future, then you are building for the future as you solve today\'s problems.',
                            'When vision, mission, strategy, goals, metrics and implementation plans exist then the win-or-loss-of-the-day becomes merely a stepping stone, or a lesson learned, along your way.  Most businesses know they need these kinds of plans ... but do they actually have them?  It is easier (lazier) to keep things in mind and not on paper.  Yet, the discipline of going through each plan, thinking about them, making decisions, and then documenting them so they can be tracked somehow gets ignored.  ',
                            'Most businesses fail because they do not have these strategic-to-detailed plans.   If you don\'t have them ... go back and build them!',
                            'Here are some basic plans needed by every business:',
                            {unordered: [
                                'Vision',
                                'Strategic plan',
                                'Tactical or business implementation plan',
                                'Capital acquisition and use plans',
                                'Marketing, sales and revenue plans',
                                'Human resource plans',
                                'Living metrics (they change as you learn which ones influence change)',
                                '… and all the above contribute to pro formae'
                            ]},
                            'These become evidence that tell others (bankers, investors, partners, suppliers) that you know what you are doing and your know how you are going to achieve it.  ',
                            'If you don\'t have these elements defined and documented yet—go build them now.'
                        ]
                    },{
                        id: 6,
                        rank: 1,
                        selection: -1,
                        notes: '',
                        menuName: 'Capital and Growth',
                        processName: 'Capital Planning and Growth Process', 
                        description: 'You use thoughtful forecasts and a well designed organization to attract capital (private, loans, grants, equity) to fund both future work performance and growth.  You understand capital utilization efficiency and efficacy.  Your plans to leverage funds for growth are written in clear plain-speak language.',
                        maturityLevels: [
                            {description: "Our efficient capitalization record facilitates funding as needed.", level: 3 },
                            {description: "Our cogent capital plan is designed to facilitate growth.", level: 2 },
                            {description: "Our preliminary capital plan ties growth with funding sources.", level: 1 },
                            {description: "Funding efforts are tactical and urgent vs. strategic and planned.", level: 0 },
                        ],
                        advicePageBreakIndex: 6,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests your immediate attention to the following:',
                            'It is quid pro quo, or “this for that.”  ',
                            'Your capital planning efforts should be as simple as that: ',
                            {unordered: [
                                'If you are very early in the process you need a plan for capitalization (friends and family, seed rounds, equity sharing, etc.) to fund infrastructure development',
                                'If you are later in the process you need a plan for capitalization (private or business loans, stock sales, etc.) to help fund operations and to support revenue generation capability development'
                            ]},
                            'In the end these funds help you attract the customers that in turn deliver value to the equity positions or pay back loans.  What is important is to show HOW you attract customers, and HOW you earn revenues.  This means you have to do your homework to understand your market, your potential, your customers, their receptiveness to the new offering, pricing for profit, and so on.',
                            'Business and capital planning are not hard—getting past emotion, bias, blinders, and hopes and dreams is actually the hardest thing to do.  Cogent, logical, evidenced-based plans tend to get funding (depending upon economic conditions and other caveats).',
                            'Go open up the Small Business Administration (SBA) website and take a look at business plan examples.  ',
                            'Those and other examples contain sections on:',
                            {unordered: [
                                'Vision documents',
                                'Market space',
                                'Business model',
                                'Products / services',
                                'Commercialization process & business development',
                                'Operating agreement',
                                'Business structure',
                                'Financials and pro formae',
                                'Organizational roles and decision-making',
                                'Legal, and more'
                            ]},
                            'These are elements of evidence that tell others (bankers, investors, partners, suppliers) that you know what you are doing and how you are going to get it done.  Get your future defined now and then go build it.  Those cogent well-defined and well-imagined paper plans and correct market timing can lead to capital.',
                            'The planning side this process is different that providing proof to others you are worthy of receiving money.  Instead, this is about understanding your market, your niche, product fit, competition, and supply chain.  And then building a strategy around that to reach more customers.  Some actions to be taken are things you can do yourself.  For example, creating operational efficiencies, creating organic growth & sales, and your strategy for market penetrations, change and growth with what you already have.  Other actions to be taken require collaboration with others.  For example, connecting with other organizations to share things (resources, equipment, talent), partnering with other companies, forming teams or consortiums, or even merging with another company.  This is about change and growth with what you don\'t already have. Again, this is tied to your strategy.',
                            'If you are not thinking this way … then that is the work before you.'
                        ]
                    },{
                        id: 7,
                        rank: 2,
                        selection: -1,
                        notes: '',
                        menuName: 'People',
                        processName: 'People and skills needed for success', 
                        description: 'You have staffed and incentivized the right people at the right time with the right skills & experience to perform work toward your business aspirations.  You have aligned the right work culture, shared vision, ethics and values, reputation, individual metrics, and capabilities to succeed in your community.',
                        maturityLevels: [
                            {description: "Team (clear roles, accountability, power, control) delivers results.", level: 3 },
                            {description: "Our team is focused upon owning results as opposed to tasks.", level: 2 },
                            {description: "We are beginning to empower people in role.", level: 1 },
                            {description: "We are beginning to divide and delegate work.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Recommendation Report suggests the following for your immediate attention.',
                            'You don\'t need to have the perfect team in place, a good one will do the job.  ',
                            'People can grow into roles and develop skills today that will be needed in the future.  Sure, once in awhile, there is a critical position that only an experienced individual can provide.  But in time, as processes and technology change, unless they grow with it, they will no longer be the perfect person in that role.',
                            'Plan for the future, design the work, hire-for-fit to that work, and then train for skill.  While that makes sense, almost no one does it.  Think about it for a moment.  Which is harder to achieve: 1) Asking a person to change to do the work, or 2) Designing the work to fit the person?  Work design and work alignment processes can be impressive—use them!',
                            'Then there is another example that touches this concept at The Walt Disney Corporation where they have been quoted, “Hire the attitude and train the rest.”',
                            'This process is about building a good work culture that values and sustains: a powerful vision, learning, change, performance, a sense of belonging, and a sense of identity for working there--all in your ecosystem and community.',
                            'Some say, “I can\'t afford the person I need to hire” and in the moment that can be true.  But that said, after funding a living wage, not everyone works for more pay.  Studies have shown job satisfaction is just as important as pay in those conditions as a differentiator.  ',
                            'And, by the way, there is always an equity path that could be created for a good team of employees.',
                            'So, create your standards and make it fun and satisfying to work:',
                            {unordered: [
                                'Generate a powerful vision / mission that people want to be a part of',
                                'Create learning opportunities (educational or on the job) to release future potentials',
                                'Model ethics and operating norms so work is valued and people feel a sense of belonging with your business',
                                'Show a shared purpose and interdependence for success so people know they are needed',
                                'Demonstrate and reward participative management so people know they have influence'
                            ]},
                            'Just like you listen to your customers, you also need to listen to your employees.  They are the face of your business and they are the ones most likely to be interacting with the customer.',
                            'The planning side of things are a bit different that providing proof to others you are worthy of receiving money.  Instead, this is about understanding your market, your niche, product fit, competition, and supply chain.  And then building a strategy around that to reach more customers.  ',
                            'Some actions to be taken are things you can do yourself.  For example, creating operational efficiencies, creating organic growth & sales, and your strategy for market penetrations, change and growth with what you already have.',
                            'Other actions to be taken require collaboration with others.  For example, connecting with other organizations to share things (resources, equipment, talent), partnering with other companies, forming teams or consortiums, or even merging with another company.  This is about change and growth with what you don\'t already have. Again, this is tied to your strategy.',
                            'If you are not thinking this way … then that is the work before you.'
                        ]
                    },{
                        id: 8,
                        rank: 8,
                        selection: -1,
                        notes: '',
                        menuName: 'Metrics',
                        processName: 'Work performance metrics', 
                        description: 'Your business processes and operational efficiencies utilize visual controls.  You and your employees walk into the business and almost immediately know the performance of the day; and are empowered to take action based on that [non]performance to make things better.',
                        maturityLevels: [
                            {description: "Results focused using systemic metrics to ensure value delivery.", level: 3 },
                            {description: "Implementing a system of metrics with visual controls and lean.", level: 2 },
                            {description: "We have processes that can be measured.", level: 1 },
                            {description: "We are beginning to design work.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Recommendation Report suggests the following for your immediate attention.',
                            'Many businesses find out what they have accomplished at the end of each month.  Some try to reconcile inventory with receipts, which is sometimes a mind-numbing task when they are not organized. Others don\'t care--they figure loss/theft is just part of the business expense process and look at what\'s in the till.  These approaches are mostly de-motivating to employees since they cannot connect what they do to the management-desired results … so, who cares!',
                            'However, there are other ways to help measure work performance.  One example is using lean and visual controls.  Consider the lean/visual processes below for possible application to your business:',
                            {unordered: [
                                'Leader Standard Work—when the leader follows his/her standard work effectively, the rest of the lean management system has a good chance of operating effectively, powered by the engine of leader standard work. This is the first line of defense for the focus on process.',
                                'Visual Controls—translate performance of every process into expected results vs. actual.  It converts the driving force of leader standard work into traction, and grants leaders the ability to quickly spot and move to action where performance is not what is expected.',
                                'Daily Accountability Process—through daily accountability, a leader can steer and set direction for improvement activity in an area.',
                                'Leadership Discipline—none of this will work without discipline—most importantly, leader discipline. Leader Standard Work, Visual Controls and Daily Accountability won\'t amount to anything without the discipline to execute these elements.'
                            ]},
                            'Visual Management—build a workplace which is self-explaining, self ordering, and self improving:',
                            {unordered: [
                                'The employees see themselves as a true group or team, in charge of: Production status (supply needs), Inventory levels (what to show), Method availability (the tools of conducting business)',
                                'The group begins to develop a group consciousness or knowing, in charge of their work: Delivery commitments, Goals and schedules, Management of the day',
                                'The group transforms to an interdependent team, in charge of: Developing and agreeing to rules & objectives, Role assignments, Involvement in improvement activities'
                            ]},
                            'Delegation of this sort allows the business to develop higher-level performance metrics (a few shown here): ',
                            {ordered: [
                                'financials, ',
                                'burn rate, ',
                                'customer acquisition cost ratio, ',
                                'performance to plan (earned value), ',
                                'on time deliveries,',
                                'customer indicators, and ',
                                'employee development tracking.'
                            ]}
                        ]
                    }
                ],
                testout: [
                    'The Vector Reports Recommendation for your Category of Business processes is as follows:',
                    {ordered : [
                        'Before you develop this Category much further, spend your resources improving any other process Categories you did not rate at full maturity. Then, leverage these high-rated processes to assist your overall organizational development efforts.',
                        'When you have achieved a full “Foundational” performance level for all process Categories (all scores at a “three”) then consider working on higher levels of maturity for your business, or consider contacting a consultant / coach to envision what could come next in your journey.']},
                    'It is time to “lift your head up and see in a new way.”  And ask your team as it stands on this solid foundation of Business processes, “What\'s next?”',
                    'Here\'s a glimpse into two higher cmmi-like levels of development—Levels 4 and 5.  Both of them require expanding one\'s point of view from what “I” can do as a business to what “we” can do together.  And the “we” quickly grows to include entities outside of your business in “community.”',
                    'A Level 4 description for your Business Category might include behaviors such as: ',
                    {unordered: [
                        'Every process owner works to support the success of the other process owners in this and the other Categories of processes to transform from a collection of good processes to team-based success standards.  This might mean what you learn from customer interviews and feedback from the owners of the “Partners” or “Future Focus” processes is provided to “Capital and Growth”; or informing the process owners of the Commercialization Category processes of “Market Testing” or “Innovation.”  All to build team (business) success.',
                        'Process owners in this Category might begin to work with external suppliers in new ways to better support your own customers—perhaps building and supporting pull systems throughout the value chain.']},
                    'A Level 5 description for your Business Category might include behaviors such as: ',
                    {unordered: [
                        'Your entire organization is focused on continual improvement. Your company is leading the industry.  And as proof of that you are being benchmarked, invited to speak at industry association sessions about where the industry is headed.',
                        'Your company is a full member of the community it operates within.  As proof of that you are a good and charitable corporate citizen, you help build business ecosystems that grow the economic pie.']},
                    'Vector Reports wishes you well in your continued journey.',
                ]
            },
            {
                category: 'Commercialization',
                axis: 'East',
                rawSum: 0,
                average: 0,
                didView: false,
                intro: {
                    header:  'Your Commercialization Processes',
                    paragraphs:[
                        '<span style="color:red">Pause, and <b>integrate</b> your thinking!</span>',
                        '<b>Commercialization:</b> The integration, direction and effect of your entity’s assets and abilities.',
                        'These Commercialization processes focus on how you make money in your business. All your work to build a business, to respond to customers and opportunities, with value-adding and desired products, lose their impact if you cannot integrate them together in an aligned way to make enough money to deliver the desired profit or deliver the goal state.',
                        '<span style={{fontWeight: 600, fontSize: 16}}>Frame of reference</span>',
                        'The frame of reference you use when rating processes is from the “WHY” perspective. You started, or have, a business that is intended to achieve something. Go to a higher perspective, a sort of meta level, that allows for the collection of information that supports the “Go” or “No Go” business decision. This is about if YOU can implement the alignment of Product, Market and your Business infrastructure for this product, project or the business as whole.',
                        'Please shift your thinking to the WHY and HOW of your business, and getting things done. Consider how leadership engages in creating the desired future.',
                        'If you do not know that information right now, you might consider postponing this assessment until you actually have defined your OWN “why.” Or, you might proceed with the assessment to learn what goal you ought to be seeking.',
                        'These eight processes are ALL about key business topics that should drive commercialization decisions:',
                        '<ul><li>Innovation, new improvement idea capture process</li><li>New product or service validation of fit with business</li><li>Business Analysis of what offering brings to the business</li><li>New offerings market testing</li><li>New offer implementation</li><li>Price analysis and plans</li><li>Marketing &amp; Sales for the product line / service offerings</li><li>Customer support</li></ul> ',
                        '<span style="font-weight: 600; font-size: 16">Proceed</span>',
                        'Remember, as you select your maturity Level Rating for each process that you read the criteria from the bottom-up. Then select the lowest level criteria description that the process fully satisfies. This means even if you have partial higher-level criteria satisfied, you should not select that higher level.',
                        'Click to begin rating processes:',
                    ]
                },
                processes: [
                    {
                        id: 1,
                        rank: 1,
                        selection: -1,
                        notes: '',
                        menuName: 'Innovation',
                        processName: 'Innovation, new improvement idea capture process',
                        description: 'In your work you are always listening to the customer and thinking about how to make things better for them and your business.  Additionally, almost everything you encounter is considered as a source of new ideas that, if adapted in an innovative way, could become valuable IP (protected) in the future.',
                        maturityLevels: [
                            {description: "Always respond with a systemic connection of problem with opportunity.", level: 3 },
                            {description: "We have a process to capture and engage in future possibilities. ", level: 2 },
                            {description: "Changing from “this is what is” to “this is what could be” in our actions.", level: 1 },
                            {description: "We're turning complaints into valuable feedback.", level: 0 }
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'There are many reasons businesses become distracted away from the future.  Simply solving daily problems can fill a day\'s work.  ',
                            'Improving this process is not about stopping what you are doing to perform thought-work about the future―instead; it is about creating an ongoing awareness about possibilities and seeing with new eyes all the time.  We all shut out the noise, but let\'s not shut out opportunity while doing so.  Change your mental filters to become ever-mindful of the business environment you are operating within while keeping an open mind to see what is going on around you, your competitors and your industry.  Things change, particularly technology, and noticing that change early can spark innovation.',
                            'Another source of opportunity and needed change is your customer.  If you have a positive relationship with your customers they will care about you.  They show their care through conversation (feedback and suggestions) which is how they let you know “what they have seen” because in the end they, too, want you to succeed.',
                            'So, in business jargon you need to have, build or refine an idea-capture process called “Discovery” —deliberate and ongoing efforts to notice what has happened, what is happening, and what will likely happen (whether you take action or not).  ',
                            'Evaluate what you have learned and determine if it is an opportunity you want to act upon, or not.  ',
                            'This idea capture process may include:',
                            {unordered: [
                                'Being aware of your market trends',
                                'Obtaining customer feedback',
                                '“Gemba” meaning “going to see” what is new',
                                'Connecting to people inside your network and listening to what they say about you, your product or service, and your competitors',
                                'Connecting to people outside your network and listening to what they “see” might be possible',
                                'Learning why customers are currently buying (or not)',
                                'Learning what customer needs are today (and tomorrow)',
                                'Recognizing problems (wherever they show up)',
                                'Interpreting a problem as if it were an opportunity',
                                'Evaluating an opportunity for its application to you and possible profit potential'
                            ]},
                            'And if those evaluations suggest something good, then capturing, documenting and protecting that new idea or thing as intellectual property is critical.',
                            'And lastly, there are internal processes where you set aside the work of the day to imagine the future, where all things are possible, and think about the implications of that future. “Day-in-the-life” is a process where you imagine yourself in your future and observe what is occurring in that future - if you like what you see, bring that future back to actions you can take today.  ',
                            'There are other ideation processes but the most important one is developing a positive relationship with your customer so they feel comfortable telling you their problems in terms of what products or services you provide.'
                        ]
                    },{
                        id: 2,
                        rank: 8,
                        selection: -1,
                        notes: '',
                        menuName: 'Fit Validation',
                        processName: 'New product or service validation of fit with business',
                        description: 'When evaluating a new notion or opportunity you can easily validate its application for the future.  You quickly perform a disciplined check against important criteria and can assess how well it fits with your business (operationally) and determine if it is feasible not ― and then pursue or pivot.',
                        maturityLevels: [
                            {description: "We have a solid validation process that emulates the statement.", level: 3 },
                            {description: "Building processes to analyze and test new ideas and opportunities.  ", level: 2 },
                            {description: "Learning about the criteria that helps with “go” and “no go” decision.", level: 1 },
                            {description: "We trust our gut to decide to go forward or not.", level: 0 }
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'The ability to quickly assess opportunity and actually take a promising idea to fruition is important.  You may have done this by using a “gut feel” or there may have been new ideas that had obvious potential.  But the former is often luck, and the latter is usually rare.  Neither represents a disciplined approach to determining if new ideas have merit and/or apply to you.',
                            'Perhaps you have walked into a business and thought “I could do this better by … [doing it in a different way]!”  This snap judgment can come from your experience, knowing what works and what does not work, and how much effort it would require to actually “do it better” yourself.  Consider the evaluation criteria your mind raced through to make that decision. That criteria needs to get outside of your mind and turned into a process others can follow in your business.  This is something you, and key employees, need have as a fully exercised mental muscle that your business can flex.  This is the same kind of thinking, almost dispassionately, to appy to your own product or service development efforts and your analysis of it. ',
                            'If you don\'t have a lifetime of experience or you don\'t have trust in your own gut feel then there are tools and processes that you can use to assess and develop new ideas into offerings—products and/or services.  ',
                            'These essentially boil down to the ability to deliver customer value at an efficient cost.  ',
                            'This starts with customer research and validation (see list below):',
                            {unordered: [
                                'Identify typical or ideal customers for this new thing or service',
                                'Specify what the value proposition is in the eyes of the customer',
                                'Try out potential minimal viable product (MVP) versions as test cases for customers to see',
                                'Talk to future customers and identify their response to your new offering, and why: ',
                                {unordered: [
                                    'Do they like it',
                                    'Do they want it',
                                    'Do they reject it',
                                    'What do they say you should do'
                                ]},
                                'Listen to feedback and revise your design (as appropriate)',
                                'Consider characteristics and features needed to succeed',
                                'Consider production costs to obtain the “thing” or to deliver the “service” to the market',
                                'Consider marketing and sales costs',
                            ]},
                            'Then, depending upon what you have learned, decide to pursue, partner, pause, pivot, exit, etc. based on the value proposition to your customer AND to your business.  Following a disciplined analysis process will confirm if your decision to proceed or to reject development of the new offering was a good one.  Be agile and speedy in these learning situations.',
                            'The challenge is that everyone essentially says, “I do that” in some form or another.  People take shortcuts, risks, and make assumptions that typically bite later.  Don\'t do that.  Instead, do the research work to really know that whatever the new thing is, it will work for you.'
                        ]
                    },{
                        id: 3,
                        rank: 2,
                        selection: -1,
                        notes: '',
                        menuName: 'Business Analysis',
                        processName: 'Business Analysis of what offering brings to the business', 
                        description: 'When opportunities come your way or you have an Aha! Moment for something new that your business might offer to customers you can quickly run a Reality Check.  You quickly perform a disciplined review on what it will take to make money with it to determine if it is worth a pursuit or not.',
                        maturityLevels: [
                            {description: "Solid potential “revenue/profit” review process emulating the statement.", level: 3 },
                            {description: "Building processes to analyze and test potential earnings and profits.", level: 2 },
                            {description: "Learning about the criteria that helps with “go” and “no go” decision.", level: 1 },
                            {description: "We trust our gut to decide to pursue or not.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps you have walked into a business and wondered “How are they making money?”  Given you\'ve asked that question, chances are the answer is “… they are not.”  Now apply these inquisitive and critical thinking skills to examine your own business endeavors.',
                            'Some business owners make business decisions by using a “gut feel” or implementing ideas that seemed to have obvious potential.  Okay, but let\'s not rely on luck.  Neither represents a disciplined approach to making a decision to pursue the development and implementation of a new idea, product or service.  If you think about it a bit you get information the hard way or easy way.  The easy (lazy) way is making a decision and then seeing what the data says.  The harder (disciplined) way is to let the data guide you toward the decision to be made.  Ask, yourself, which sequence is more costly in the long run?',
                            'If you don\'t have a lifetime of experience or you don\'t have trust in your own gut feel, there are tools and processes that you can use to assess and develop new ideas into products and/or services.  ',
                            'These essentially boil down to the ability to deliver customer value at an efficient cost.  This process focuses upon business analysis after you have done your research:',
                            {unordered: [
                                'Analyze characteristics of the product or service that provide information for making decisions',
                                'Identify market applicability of the new thing or service',
                                'Discover customers who would want it'
                            ]},
                            {unordered: [
                                'Determine potential market size: Total market, total accessible market, obtainable market share',
                                'Estimate costs to design and produce it: fabricate (make) or procure (buy)',
                                'Estimate costs to sell, market and deliver the thing or service',
                                'Determine affordability / profitability price points & price sensitivities',
                                'Determine competitive advantage over others and financial risks',
                                'Create a pro forma',
                                'Think about legal & any regulatory issues, logistics and more …'
                            ]},
                            'Uniformed decisions tend to be foolish (fooling ourselves).  With this kind of analysis and information, you will be making a better decision through an informed choice process.  ',
                            'Gut feel decision-making (only applicable when there is no information to be obtained) rarely happens in the business world.  ',
                            'Do the work.  Get smart.  Analyze the data.  Make informed decisions.'
                        ]
                    },{
                        id: 4,
                        rank: 7,
                        selection: -1,
                        notes: '',
                        menuName: 'Market Testing',
                        processName: 'New offerings market testing', 
                        description: 'When new offerings make sense (right market and profit opportunity) you have a robust and rapid way to demonstrate prototypes to future customers to obtain feedback.  You have little uncertainty about what the customer response will be when launching something new.',
                        maturityLevels: [
                            {description: "Solid “market response” review process emulating the statement.", level: 3 },
                            {description: "Building processes to analyze / test offering(s) potentials in the market.", level: 2 },
                            {description: "We are learning about criteria that help analyze the market.", level: 1 },
                            {description: "We focus on our offering(s) and are not seeking anything new now.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps you have launched offerings (products or services) in the past with little idea about how well your customers would respond. Some may have done well and others not so well.',
                            'Some business owners decide to launch a new offering by using a “gut feel” or perhaps recognizing it was simply obvious that it would work.  Neither represents a disciplined approach to making the decision to launch something new.  Instead of relying upon luck, what if you could go look behind each door like “Let\'s Make A Deal" and see what\'s there before you select door number 1, number 2 or number 3. This recommendation is about increasing certainty and reducing risk.',
                            'If you don\'t have a lifetime of experience, or you don\'t have trust in your own gut feel, there are tools and processes that you can use to determine if the new offering will be received well by future customers.   Essentially, you will want to shift a decision based in uncertainty to one of high probabilities by obtaining advance knowledge of customer receptiveness.  ',
                            'This process focuses upon testing the new ideas or offerings prior to simply launching it.  If you are not utilizing some of the actions below, it is time to start doing so before you risk spending time and money launching your your next offering.  ',
                            'A more disciplined approach to “offering testing” includes: ',
                            {unordered: [
                                'Developing a cadre of select customers you trust to evaluate new product(s) or service(s) and to give you valuable feedback',
                                'Beginning with a test model of cost, market size and capture, and price points for later comparison of your eventual offering',
                                'Using an MVP (minimal viable product) to demonstrate the new thing / service',
                                'Setting up feature testing among different groups to A/B test value differences',
                                'Rapidly redesign or adjust the mockup or MVP to validate the change with a new group',
                                'Evaluating offering characteristics / features and their cost versus financial business targets',
                                'Comparing similar offerings in the market and determining if and how your offering differentiates from those others'
                            ]},
                            'Then decide, using this kind of information at hand, to reduce risk and costly mistakes.'
                        ]
                    },{
                        id: 5,
                        rank: 3,
                        selection: -1,
                        notes: '',
                        menuName: 'Implementation',
                        processName: 'New offer implementation', 
                        description: 'When you decide to make product changes, or to go forward with new products or services, you utilize your robust abilities to implement it into your market.  You are confident in the quality and reliability of your suppliers and marketing efforts.  You find it easy to attract funds to do so.',
                        maturityLevels: [
                            {description: "We have a solid “product launch” process that emulates the statement.", level: 3 },
                            {description: "Some processes for launch. Funders always investigate us before help.", level: 2 },
                            {description: "We are learning about product launch implementations and their costs.", level: 1 },
                            {description: "We can only bootstrap our product launch efforts at this time.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps everything about your new product or service offering screams “… customers want me.”  It may seem like a “no-brainer” to launch it.  However, depending upon the degree of difference from existing products and services, you may or may not have a difficult time actually delivering it to your customer.  ',
                            'Welcome to the world of logistics—it is a world of processes, tools and methods.  Bottom line—you want your offering to show up “on the shelf” or “at the doorstep” of your customer from your supplier. ',
                            'Assuming everything else is ready (product, market, business) what remains is managing implementation plans and preparing for what can go wrong.  This is risk management work that is tied to the design and methods associated with your offering.  The world is full of risk, particularly risk you cannot control, so your thought work should move from control to mitigation.  As your offering is designed, tested, produced, transported and marketed, risk should be addressed as choices are made for each one of these unknowns.',
                            'Listed are some of the areas where awful failures could occur—beyond your control and in no particular order:  1) Finances, 2) Supply chain, 3) Disasters (any type), 4) Security breaches, 5) Asset failures, 6) Technology failure, 7) Regulatory changes (e.g. safety rules), 8) Labor / strikes, 9) Training (your team, your customers) for the offering, 10) Storage, 11) Material availability, 12) Crossing borders, 13) Societal changes  … etc.',
                            'Then ask and answer the following questions?',
                            {unordered: [
                                'What mitigations can be designed now that reduces the pain of a risk occurrence?',
                                'Insurance can be obtained (cost tradeoffs need to be considered) for some of these risks.  ',
                                'How did you make your early decisions on this offering?:',
                                {unordered: [
                                    'What is it made of?',
                                    'Who will deliver it and what skills are needed?',
                                    'Do my supplies cross borders?',
                                    'Is there a single point of failure in the chain (anywhere)?',
                                    'Where is it produced / serviced / maintained / trained?',
                                    'Do we have the technical know-how to price it, build it, supply it—on time? ',
                                    'Do we have quality and performance measures in place?'
                                ]}
                            ]},
                            'Again, this all ties back to your design and supply chain.',
                            'Design the logistics and your implementation processes and methods.  Make a plan.  But then, instead of implementing that plan right away use risk analysis to evaluate the likelihood of occurrence and the size of the consequence of all the things that can go wrong (and be sure to couple it with offsetting rewards and mitigations). One example is to run a Failure Modes and Effects Analysis (FMEA) to determine all the different ways that your offering could fail and what would the effects of those failures upon your supply chain, customers, and your business?',
                            'Then you decide how and whether you move forward or if you need to change your plans.'
                        ]
                    },{
                        id: 6,
                        rank: 4,
                        selection: -1,
                        notes: '',
                        menuName: 'Price',
                        processName: 'Price analysis and plans', 
                        description: 'Your products and services are providing you the optimal return for your effort to be in the market.  This means you know what you sell covers everything associated with the offering and it becomes the means to achieve business goals, wealth or service, and peace of mind.',
                        maturityLevels: [
                            {description: "Achievable plan for profit for the offering(s) for this market environment.", level: 3 },
                            {description: "The price elasticity for our offering(s) supports our goals.", level: 2 },
                            {description: "Learning to analyze costs, expenses, revenue, risks of our offering(s).", level: 1 },
                            {description: "At this time we are bootstrapping all of our launch efforts.", level: 0 },
                        ],
                        advicePageBreakIndex: 4,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps everything about your new product or service offering screams “… customers want me” and you think “… it may be time to raise the price a bit” and see what happens.  Or, on the other end of the experience, the product is not moving or the service is not being requested so you think “… it may be time to lower the price a bit” and see what happens.',
                            'Some business owners decide to set priced by using their “intuitive feel” or perhaps felt it was simply obvious that the price should work.  Neither represents a disciplined approach to making the decision to set or to change your price.  Guesses lead to sub-optimized returns and risky (particularly subject to unanticipated) results.',
                            'If you don\'t have a lifetime of experience, or you don\'t have trust in your own intuitive knowledge, there are tools and processes that you can use to determine price for new products and/or services.   Essentially, there is work to be done to measure price sensitivities or price elasticity of demand.  This is the classic supply-and-demand model, but instead of traditional straight lines they are curved lines.  Meaning a price change produces results different than what a linear or straight ratio might predict.',
                            'This work boils down to knowing two things: 1) your true total cost, and 2) customer price sensitivities.  While other processes address the true cost of doing business, this process addresses pricing.  ',
                            'However, for your pricing model to work you still need to know all the costs associated with your offering including the soft costs, hidden costs, and unanticipated costs of loss or disruptions.  Getting customer responses requires price testing with customers who are representative of the larger market.',
                            'While there is no formula that works every time, there are methodologies you can utilize in researching price sensitivities that are associated with how well you know: your customer, your market, and your product.',
                            'Knowing that, consider the following price analysis methods: ',
                            {ordered: [
                                'A / B or C testing (offering the same product to similar customers, but with different prices), ',
                                '% over your actual total costs to deliver it, ',
                                'Comparison to other similar offerings (by region, type, or industry), ',
                                'Substitutes and limitability of the product in the market, ',
                                'Value of use (e.g. customer cost savings by using it), ',
                                'Customer type (some like paying more!), ',
                                'Any combination of the above.'
                            ]},
                            'Use that analysis when considering your business model, for example:',
                            {unordered: [
                                'Single sales and/or in the on-line marketplace',
                                'Membership sales',
                                'Volume sales discount house',
                                'Freebies referral program',
                                'Commissioned sales',
                                'Pricing by customer type',
                                'Subscription',
                                'Freemium, permanently or for a specified time period'
                            ]},
                            'These methods of selling/making money dramatically affect what your price needs to be.',
                            'Once again, this effort is for data / information collection and research.  As you learn more you can adjust your offering\'s price over time … all towards learning what the market will bear.'
                        ]
                    },{
                        id: 7,
                        rank: 5,
                        selection: -1,
                        notes: '',
                        menuName: 'Sales',
                        processName: 'Marketing & Sales for the product line / service offerings', 
                        description: 'You are experiencing the positive effects of your marketing and sales design implementations.  It has been optimized to produce predicable results—meaning you know your cost to acquire a new customer for each channel, and as such you adjust it for your growth plans.',
                        maturityLevels: [
                            {description: "Solid plan for marketing / sales for the offering(s) for our market.", level: 3 },
                            {description: "The cost to acquire each customer by channel supports our goals.", level: 2 },
                            {description: "Learning to analyze our offering(s) marketing / sales costs, benefits, risks.", level: 1 },
                            {description: "We can't back up our marketing and sales decisions by data today.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps you started your business in a robust economy and did not need to worry about acquiring customers because, through the wisdom of crowds, they were already there and found you.  Or perhaps you are just starting out and no one knows about you yet - and hopefully you are a gem in the community ready to be discovered.  ',
                            'In either case your actions could be described as passive, which is a recipe for failure.',
                            'There is a world of tools, methods and processes associated with marketing and sales - too many to mention here.  But the main message is for you to become active in helping your customers FIND YOU!',
                            'In a macro sense, you must know your ideal / typical customer first.  That information leads you to demographics that help you find and target them with the kind and type of messaging they prefer to receive.  This information will also suggest what messaging / marketing channels may be more effective / efficient for you.  ',
                            'In this digital age, it is not recommended to use a “shotgun” approach and then wait to see what happens.  That is an expensive way to learn.  Although too long to detail here, you must examine the method you make money from customers (your business model or other models above as comparisons) and that helps inform your channel strategy.  ',
                            'Then collect other information, such as your industry, location, market type and segment.  And, then, your business model should suggest marketing channels and sales designs for growth.  ',
                            'Consider the following list of ideas and methods … and then build a plan to achieve market awareness of you, your offering, and how you connect (marketing channels, and sales designs):',
                            {unordered: [
                                'Organic growth',
                                'Word of mouth',
                                'Customer advocates and sells for you (they fall in love with the offering)',
                                'Direct on-line sales',
                                'Sales employees,',
                                'Commission sales',
                                'Affiliate partners ',
                                'Integration with existing complimentary organizations'
                            ]},
                            'Bottom line - be aware of how you are reaching your customers.  Make a plan and implement it!'
                        ]
                    },{
                        id: 8,
                        rank: 6,
                        selection: -1,
                        notes: '',
                        menuName: 'Support',
                        processName: 'Customer support', 
                        description: 'Your customers know you do all that you can to help them be happy with you (they are satisfied, they received the value as promised, their issues/problems are properly corrected).  As such you can say you have turned customers into friends and a kind of family that are loyal to you.',
                        maturityLevels: [
                            {description: "Most of our customers sell for us (new customers are referrals)!", level: 3 },
                            {description: "The preponderance of our  customers love us! ", level: 2 },
                            {description: "We love our customers (we go the extra mile with our support prog.)!", level: 1 },
                            {description: "Our customers learning about us.", level: 0 },
                        ],
                        advicePageBreakIndex: 5,
                        advice: [
                            'To help you achieve a more balanced capabilities maturity, your VSB Assessment Recommendation Report suggests  your immediate attention to the following:',
                            'Perhaps you started your business with keen interest in your product or service.  Perhaps your offerings are cool or could make a difference in people\'s lives.  Or at least, it did at one time.',
                            'However, in time, offerings do not have a heart nor care about the customers that utilized them - you, as a human being, demonstrate that care (or you don\'t care).  If you don\'t care, your business likely will not last long, because people talk.  If you do, the opposite is true for the same reason that “people talk.”  In fact, your reputation is out in the community walking around right now making impressions through other people.  You have to ask yourself, what is your reputation saying about you through other people\'s mouths?',
                            'Yes, there are a thousand and one things to worry about, or to accomplish, or solve - and you will work on them all in time.  Yet, if you take care of what\'s important, what\'s important will take care of you.  And what could be more important than fellow human beings; customers, suppliers, employees, contractors, and partners?',
                            'Given this has shown up in your report, you may need something from a gentle nudge to a kick in the butt to really consider what is important in your business relationships.  Remind yourself that one of the best things about being in business is that you can develop wonderful relationships with your customers.  You know them well, and your customers know that you know them.  ',
                            'And all of that is on top of simply taking the time to solve your customer\'s problems when they show up.',
                            'Find out their needs, confirm that your offerings can satisfy, be respectful (to them and yourself), with good boundaries, let them know you appreciate them (saying thank you\'s, providing a freebie now and then, letting them know of better deals coming, etc.), and listen to them even when facing pressures of your own.',
                            'If you do this chances are customers will eventually become your best advocates:',
                            {unordered: [
                                'Send friends, family and acquaintances to you - new customers',
                                'Post positive stories about you',
                                'Agree to participate in marketing',
                                'Fight for you when someone else suggests a competitor',
                                'Inform you of things to improve, or what competitors are doing that you are not, but should be doing'
                            ]}
                        ]
                    }
                ],
                testout: [
                    'The Vector Reports Recommendation for your Category of Commercialization processes is as follows:',
                    {ordered: [
                        'Before you develop this Category much further, spend your resources improving any other process Categories you did not rate at full maturity. Then, leverage these high-rated processes to assist your overall organizational development efforts.',
                        'When you have achieved a full “Foundational” performance level for all process Categories (all scores at a “three”) then consider working on higher levels of maturity for your business, or consider contacting a consultant / coach to envision what could come next in your journey.']},
                    'It is time to “lift your head up and see in a new way.”  And ask your team as it stands on this solid foundation of Commercialization processes, “What\'s next?”',
                    'Here\'s a glimpse into two higher cmmi-like levels of development—Levels 4 and 5.  Both of them require expanding one\'s point of view from what “I” can do as a business to what “we” can do together.  And the “we” quickly grows to include entities outside of your business in “community.”',
                    'A Level 4 description for your Commercialization Category might include behaviors such as: ',
                    {unordered: [
                        'Every process owner works to support the success of the other process owners in this and the other Categories of processes to transform from a collection of good processes to team-based success standards.  This might mean what you learn from customer interviews and feedback from the owners of the “Market Testing” or “Price” processes is provided to “Sales”; or informing the process owners of the Product Category processes of “Customer Value” or “Function / Solution.”  All to build team (business) success.',
                        'Process owners in this Category might begin to work with external suppliers in new ways to better support your own customers—perhaps building and supporting pull systems throughout the value chain.']},
                    'A Level 5 description for your Commercialization Category might include behaviors such as: ',
                    {unordered: [
                        'Your entire organization is focused on continual improvement. Your company is leading the industry.  And as proof of that you are being benchmarked, invited to speak at industry association sessions about where the customer is headed.',
                        'Your company is a full member of the community it operates within.  As proof of that you are a good and charitable corporate citizen, you help build business ecosystems that grow the economic pie.']},
                    'Vector Reports wishes you well in your continued journey.',
                ]
            },
        ]
    }
    


    console.log('updateEvaluation');
    //temp 
    let query = db.collection('assessmentEvaluations');//.doc('vsba');
    query = query.where('storeId', '==', 'vsba');
        
    query.get().then(snapshot => {
        //get assessment
        snapshot.forEach(async doc => {
            console.log(doc.exists);
            if (doc.exists) {
                let categories = evaluation.categories;
                //update assessment
                await doc.ref.update({categories}).then(() => {
                        console.log('successss');
                    }).catch(error => {
                        // The document probably doesn't exist.
                        console.log(error.message);
                    });
            } else {
                console.log("Assessment not found");
            }
        });
    }).catch(error => {
        console.log(error.message);
    });
}

