//import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../LogoDark';
import { LogOut as LogOutIcon, /*Bell as BellIcon*/ } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import DevModeSwitch from 'src/components/shared/DevModeSwitch'

const AdminNavbar = ({ onMobileNavOpen, ...rest }) => {
    //const [notifications] = useState([]);

    const navigate = useNavigate();


    return (
        <AppBar elevation={0} {...rest} sx={{backgroundColor:'white', borderBottom: '1px solid #DCDCDC'}}>
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box sx={{ flexGrow: 1 }} />
                <Hidden>
                    {/* <IconButton color="inherit">
                        <Badge
                        badgeContent={notifications.length}
                        color="primary"
                        variant="dot"
                        >
                        <BellIcon />
                        </Badge>
                    </IconButton> */} 
                    <DevModeSwitch />
                    <IconButton color="primary" onClick={ () => navigate('/admin/logout', { replace: true }) }>
                        <LogOutIcon />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="primary" onClick={onMobileNavOpen} >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

AdminNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default AdminNavbar;
