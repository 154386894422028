import { Helmet } from 'react-helmet-async'
import { Box, Container, Card, CardContent, Typography, List, ListItem, Table, TableContainer, TableBody, TableRow, TableCell } from '@material-ui/core'
import ArrowRight from '@material-ui/icons/ArrowRightAlt'
import CircleIcon from '@material-ui/icons/Circle'
import Footer from 'src/components/shared/Footer'

const About = () => {
    return (
        <>
            <Helmet>
                <title>Vector Reports | About</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 8 }}>
                <Container maxWidth="md">
                    <Card width={80}>
                        <CardContent>
                            <Box m={6}>
                                <Typography color="textPrimary" gutterBottom variant="h2" sx={{ justifyContent: 'center' }}>
                                    ABOUT
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="h5" py={2}>
                                    HISTORY & MISSION
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Both of Vector Reports’ founders were fortunate to have had their careers in what is today a Fortune 20 company. Every day the population of a small city would come to work in a challenging and rewarding environment. Because of its vast employee population, typically, once a direction was established, large amounts of financial, time, and human resources would be unleashed to make it happen. In this atmosphere of resource consumption, programs evolved to identify, simplify, reduce and eliminate systemic waste that can arise and accumulate in large siloed operations. Within those change management initiatives our Founders flourished. After their retirements they wished to continue their involvement as external lean consultants in the “real” world and found both a lack of standardization in the consulting fields as well as a lack of available internal resources within the organizations that needed help the most.
                                </Typography>
                                <Typography color="textSecondary" pb={2} mt={2}>
                                    They created Vector Reports&trade;as a vehicle to design and deliver low-cost virtual consulting services. Their goal is to give startups and very small businesses the benefits of professional consultants at a fraction of the time and costs of typical consulting encounters. Often, for much less than the price of a typical consultant’s round-trip plane ticket, and in only an hour or so, a Vector Reports Assessment gathers a host of critical information from internal experts, analyzes it based on generally agreed upon best business principles, and delivers a set of prioritized recommendations to guide organizations in mitigating their most vulnerable conditions.
                                </Typography>
                                <Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
                                    Purpose of Assessments
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Vector Reports’ founders cut their professional teeth on the systemic study and application of lean engineering, manufacturing and support systems. Vector Reports operates from the philosophy that identifying, reducing and even eliminating waste in organizational systems pays benefits well beyond simply the cost savings. If accepting wasteful processes (or ignoring necessary processes) is built into a business model, employees and team members are encouraged to grossly exaggerate estimates and to increase their cycle times, inventories, and work in process. This spawns disingenuous relationships which, in turn, tend to hide problems and reduce trust up and down the supply chain.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    For very small individual businesses, Vector Reports&trade;’ recommendations and solutions don’t let perfection get in the way of good progress. All recommendations are based upon generally accepted best business practices—the absence of which can derail fledgling companies and very small businesses. We believe the best value in hiring a skilled consultant comes from targeting their applied expertise on solutions, not on lengthy get-to-know-you research. We submit that there’s no more cost-effective way for a small organization to apply the 80 / 20 improvement rule than using the combination of an expert consultant / mentor PLUS a recent Assessment Report.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Vector Reports Assessments are a cost-effective means to rapidly obtain a set of prioritized recommendations that, if understood and implemented, will help an organization to deploy and benefit from much more mature business processes. Vector Reports has kept its costs low which allows all organizations equal access to opportunity and quality—especially those with limited resources. Historically underserved communities—who, in the past may have confronted biases and inequities due to ethnicity, race, gender, nationality, size, or other factors—can now easily avail themselves of world-class virtual consulting support.
                                </Typography>
                                <Typography color="textSecondary" pb={5}>
                                    Even before getting the detailed Recommendations, the act of taking an Assessment itself proves to be a learning experience. Assessors have remarked during their Assessment, <i>“We’re going to have to look at _____________. We’re missing this!”</i>
                                </Typography>

                                <Typography color="textSecondary" pb={2}>
                                    For consultants and mentors, the Assessment helps create client openness and quickly clarify vulnerabilities at a fraction of the time to conduct interviews and research of the client entity. While one might suggest this is a drag on revenue for consultants at the end of the effort it is all about accuracy and speed of the diagnosis—which can quickly turn into further engagements.
                                </Typography>

                                <Typography color="textSecondary" pb={2}>
                                    For agencies and other support organizations, the Assessment helps create efficiencies for internal/external consultants and mentors to develop and help businesses and entrepreneurs. It is almost always a case of limited funding and how to get the best utility out of those funds.
                                </Typography>

                                <Typography color="textSecondary" pb={2}>
                                    The Assessment helps networks of consulting/mentoring professionals to better support those entities to quickly clarify client vulnerabilities and often create a mindset for a “readiness to change” at a fraction of the time to conduct standard interviews. When you think of that, in the aggregate, meaning scoring issues across many business entities, the data that can be collected and analyzed can lead to the identification of shared challenges and that, in turn, can help focus attention and facilitate the efficient use of improvement funds.
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
                                    Taking an Assessment
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    The time required to complete an Assessment depends upon how knowledgeable the assessor(s) are about business processes used at the entity being evaluated, or how well that entity knows themselves. In a one-on-one session, the Assessment can be completed in as little as 20 minutes. A group session tends to generate active discussions among process holders and can take up to 2 hours.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Vector Reports&trade; designed the Assessment to be completed in one session with the same assessor(s). Most Assessments are conducted with one, perhaps two, leader(s) of the company as assessor(s) who march through the statements in 30 to 40 minutes. Conducting a single Assessment over multiple sessions with different assessors is not supported as this could easily lead to potential inaccuracies from incorporating conflicting multiple viewpoints with changes over time and perspective.
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
                                    Selecting Maturity-Level Ratings
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Assessor(s) read the instructions and go on to rate all 32 processes (level selections) that best reflect their current state. Read all the Level descriptions from the bottom up before choosing the one you believe accurately represents your current state. This “bottom-up” design helps assessors understand the statement more completely and it also teaches a bit about what is needed to demonstrate increasing maturity in each process.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    In most cases the four maturity Level statements build upon one another—an exception is when a Level zero states, <i>“We currently do not … ”</i>. The organization must have accumulated all of the positive accomplishments of the lower Levels before they can select a higher Level as their rating. While you may think you are a Level 2 or 3, if the organization under evaluation has issues with meeting an aspect of Level 2, you must rate that process at a Level 1. Assessor(s) should have in mind specific organizational evidence supporting each of their selected ratings such as: process documentation, internal expertise, certifications, awards, results of reviews or audits, and access to established networks, etc.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Honesty in rating is important—&quot;gaming” the system by stretching the truth or
                                    misrepresenting a current state (even unwittingly) will only slow down improvement
                                    journeys.
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
                                    Reviewing a Report
                                </Typography>
                                <Typography color="textSecondary" pl={2}>
                                    A Vector Reports Assessment generates a comprehensive, customized, prioritized Report
                                    targeted to improving your most vulnerable processes. The main parts of a Report:
                                </Typography>
                                <List>
                                    <ListItem> <CircleIcon sx={{ fontSize: 8, marginTop: -0.1 }} /><Typography color="textSecondary" pl={2}>Help you learn about your business capabilities </Typography></ListItem>
                                    <ListItem> <CircleIcon sx={{ fontSize: 8, marginTop: -0.1 }} /><Typography color="textSecondary" pl={2}>Display the resultant maturity ratings for all of your 32 processes</Typography></ListItem>
                                    <ListItem> <CircleIcon sx={{ fontSize: 8, marginTop: -0.1 }} /><Typography color="textSecondary" pl={2}>Provide recommendations on how to improve your less mature processes</Typography></ListItem>
                                    {/* <ListItem><ArrowRight /><Typography color="textSecondary" pl={2}>Section on how to interpret your most vulnerable capability quadrant</Typography></ListItem>
                                    <ListItem><ArrowRight /><Typography color="textSecondary" pl={2}>Recommendations on process improvements for your most vulnerable processes</Typography></ListItem> */}
                                </List>

                                <Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
                                    Next Steps
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Vector Reports recommends businesses being evaluated to use the Report review process for level-setting and creating alignment and balance across your organizational team. Let any gaps, imbalances or low scores in the results serve as an “attention-focusing device” for change now and in the future.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Discuss your understandings of current-state conditions with coworkers and team members, and with your client if you are a consultant or mentor. Subsequent change agents should understand that sometimes even all the decision makers in an organization are not aware of what others may think about the organization. As such, the implications of what some of them “think they already know” about the organization could simply be wrong or at least hold incomplete information; consequently, the Report content contains details that encourage improvement conversations and suggests methods to help plan for next steps within the organization.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    When determining what to do next, Vector Reports&trade; suggests you consider the two approaches below:
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Follow the influence drivers—often there is a natural sequence to work. The Assessment identifies essential processes that must be in place to succeed. If those are immature, then improve what influences the most. The Report makes a first cut at this selection.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Improve lowest process scores—nothing good comes of not knowing or not using good practices that other processes depend upon.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    A rising tide lifts all boats—if you receive a Report that shows an imbalance that makes sense to you, then leverage your strengths to buy time to fortify those processes that are not. Improving your lowest scores can help prevent unforeseen impediments from emerging at all.
                                </Typography>

                                <Typography color="textPrimary" gutterBottom variant="h5">
                                    Founders
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Both founders hold MBAs and share many personal values. They both enjoy helping others succeed, dislike waste and unnecessary cost, like delivering value to the customer, and care about learning no matter one’s age.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Throughout both their careers, whether working together or in different organizations, they have always brought a lean perspective on the daily activities of work and living life. And it may be a flaw or a feature, but both find themselves working to find the faster, better, less expensive way to work, to perform tasks, and create success—in their own work, and helping others to do the same.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    There was a sign on the office that said, <i>“We specialize in Fast, Efficient, Self-service!”</i> While that may seem a funny way to advertise the avoidance of work, but what it really means is a philosophy that individuals and teams who are doing the work actually know more about the challenges than someone from the outside coming in to tell them what they probably already know.
                                </Typography>

                                <Typography color="textSecondary" pb={2}>
                                    What often happens are one of two things when a challenge becomes evident; 1) They were too busy solving the problems of the moment to see the bigger problem lurking just ahead, or 2) They were focusing on priorities that make somewhat less of a difference.  Our founders recognized this in the very small business world—and wanting to build a system of fast, efficient, self-service have incorporated their knowledge and values into this methodology to help you find problems faster so you can correct them before the bigger problem becomes too big to handle.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    So, they are change agents!
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    And as such built their philosophy into a system to serve the very small business entities. Their intent: 1) Better—Recommendations are based in solid business practices, but are designed into a system that is balanced to address priorities (unemotionally, rationally, and consistently); 2) Faster—there is little out there that can give you business consulting answers as fast, 3) Cheaper—Good luck trying to find this national class level of recommendations that are customized to your specific situation and status for the price.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    Bottom line, our founders want you to succeed, but to do so without spending a fortune on consultants, education, coaches, or other improvement methods without educating yourself first on the questions to ask of them.
                                </Typography>
                                <Typography color="textSecondary" pb={2}>
                                    See!  We can’t even introduce ourselves without talking about what we do!
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
                {/* <Box m={2} />
                <Container maxWidth="md" mt={4}>
                    <Card width={80}>
                        <CardContent>
                            <Box m={6}>
                               
                            </Box>
                        </CardContent>
                    </Card>
                </Container> */}
            </Box>
            <Footer />
        </>
    );
}

export default About;