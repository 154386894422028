import { useEffect } from 'react';
import {
    NavLink as RouterLink,
    matchPath,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { updateCategoryCounter, updateProcessCounter, updateShowMainIntro } from 'src/redux/actions';
import EvaluationSidebar from 'src/components/assessment/EvaluationSidebar';
import { Divider } from '@mui/material';
import { Button, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText'


const AssessmentSidebar = (props) => {

    const {
        onMobileClose,
        openMobile,
        categories,
        globalCategoryCounter,
        globalProcessCounter,
        updateGlobalCategoryCounter,
        updateGlobalProcessCounter,
        didSubmit,
        showMainIntro,
        updateShowMainIntro,
        hideSidebar,
    } = props;

    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const updateCategoryCounter = (index) => {
        if (Number.isInteger(index)) {
            //sets category and resets process to display intro
            updateGlobalCategoryCounter(index)
            updateGlobalProcessCounter(-1)
        }
    }
    const updateProcessCounter = (index) => {
        if (Number.isInteger(index)) {
            updateGlobalProcessCounter(index)
        }
    }
    //hide initial intro page
    const updateIntro = (bool) => {
        updateShowMainIntro(bool)
    }

    const content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '1px solid #c0d0ed' }} >
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2, py: 4 }} >
                <img height={30} alt="Logo" src="/static/images/VIA-logo.png" />
            </Box>
            <Box sx={{ p: 2, borderTop: '1px solid #c0d0ed' }}>
                {!hideSidebar && (
                    <EvaluationSidebar
                        categories={categories}
                        categoryCounter={globalCategoryCounter}
                        processCounter={globalProcessCounter}
                        updateCategoryCounter={updateCategoryCounter}
                        updateProcessCounter={updateProcessCounter}
                        didSubmit={didSubmit}
                        showMainIntro={showMainIntro}
                        updateIntro={updateIntro}
                    />
                )}
                <Divider />
                <ListItem disableGutters style={{ width: 220 }} >
                    <Button
                        disabled={false}
                        component={'a'}
                        sx={{
                            fontWeight: 'medium', justifyContent: 'flex-start', letterSpacing: 0,
                            py: 1.25, textTransform: 'none', width: '100%', '& svg': { mr: 1 }
                        }}
                        target={'_blank'}
                        href={"https://business.vectorreports.com/glossary/"}
                    // to={href}
                    // onClick={loadData}
                    >
                        <ListItemText>
                            <Typography style={{ marginLeft: 10 ,fontWeight: 19, color: 'black', fontSize: 18 }}>Glossary</Typography>
                        </ListItemText>


                    </Button>
                </ListItem>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={1}>
                <Typography color="textPrimary" variant="body2">Copyright Vector Reports © {moment().format('YYYY')}</Typography>
            </Box> */}
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile}
                    variant="temporary" PaperProps={{ sx: { width: 256 } }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer anchor="left" open variant="persistent"
                    PaperProps={{ sx: { width: 256, top: 64, height: 'calc(100% - 64px)' } }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

AssessmentSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

AssessmentSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

const mapStateToProps = state => ({
    categories: state.takingAssessment.evaluation,
    globalCategoryCounter: state.takingAssessment.categoryCounter,
    globalProcessCounter: state.takingAssessment.processCounter,
    didSubmit: state.takingAssessment.didSubmit,
    showMainIntro: state.takingAssessment.showMainIntro,
    hideSidebar: state.takingAssessment.hideSidebar,

})

const mapDispatchToProps = dispatch => ({
    updateGlobalCategoryCounter: index => dispatch(updateCategoryCounter(index)),
    updateGlobalProcessCounter: index => dispatch(updateProcessCounter(index)),
    updateShowMainIntro: bool => dispatch(updateShowMainIntro(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSidebar);

