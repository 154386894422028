import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    VERIFY_REQUEST, VERIFY_SUCCESS, VERIFY_FAILURE,
    REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE,
    RESET_REQUEST, RESET_SUCCESS, RESET_FAILURE,
    USER_SUCCESS, RESET_AUTH_MSG, UPDATE_DEVMODE,
    UPDATE_ADMIN,
    VERIFY_CODE_REQUEST, VERIFY_CODE_SUCCESS, VERIFY_CODE_FAILURE, VERIFY_CODE_RESET,
    VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_RESET,
    NEEDS_REGIS, SET_CODE, SET_EMAIL, SET_WELCOME_ID, SET_NAVIGATION, SET_NEW_ASSETS, SET_PASSWORD, RESET_NEW_ASSETS, SET_RESET_LOGIN_FIELDS, SET_RESET_CODE_FIELDS
} from '../actions/';



const authState = {
    user: {},
    userInfo: {},
    isAuthenticated: false,
    newAssets: [],
    //VERIFY
    isVerifying: false,
    verifyingError: false,
    errorCount: 0,
    //LOGIN
    isLoggingIn: false,
    didLoginFail: false,
    loginErrorMessage: '',
    //LOGOUT
    isLoggingOut: false,
    didLogoutFail: false,
    logoutErrorMessage: '',
    //REGISTER
    isSigningUp: false,
    didSignupFail: false,
    registrationErrorMessage: '',
    //RESET
    isResetting: false,
    didResetSend: false,
    didResetFail: false,
    resetErrorMessage: '',
    //setings
    inDevMode: false,
    //admin
    isAdmin: false,
    //verify access code
    isVerifyUpdating: false,
    didVerifyVerifyFail: false,
    didVerifyPass: false,
    verifyMessage: '',
    accessCode: null,
    //verify email
    isEmailVerfying: false,
    isEmailDoneLoading: false,
    didEmailFail: false,
    didEmailPass: false,
    emailMessage: '',
    email: '',
    password: '',
    needsRegistration: false,
    welcomeID: 0,
    navigation: [],
    newUser: false,
    resetLoginFields: false,
    resetCodeFields: false
};

//reducer function
const auth = (state = authState, action) => {
    //switch to handle actions
    switch (action.type) {
        //login
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                didLoginFail: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                loginErrorMessage: '',
                errorCount: 0,
                user: action.user
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                didLoginFail: true,
                loginErrorMessage: action.message,
                errorCount: state.errorCount + 1,
            };
        //logout
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true,
                didLogoutFail: false
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                accessCode: null,
                user: {},
                userInfo: {},
                isAuthenticated: false,
                //VERIFY
                isVerifying: false,
                verifyingError: false,
                //LOGIN
                isLoggingIn: false,
                didLoginFail: false,
                loginErrorMessage: '',
                //LOGOUT
                isLoggingOut: false,
                didLogoutFail: false,
                logoutErrorMessage: '',
                //REGISTER
                isSigningUp: false,
                didSignupFail: false,
                registrationErrorMessage: '',
                //RESET
                isResetting: false,
                didResetSend: false,
                didResetFail: false,
                resetErrorMessage: '',
                //setings
                inDevMode: false,
                //admin
                isAdmin: false,
                //verify access code
                isVerifyUpdating: false,
                didVerifyVerifyFail: false,
                didVerifyPass: false,
                verifyMessage: '',
                //verify email
                isEmailVerfying: false,
                isVerifyingEmail: false,
                isEmailDoneLoading: false,
                didEmailFail: false,
                didEmailPass: false,
                emailMessage: '',
                email: '',
                password: '',
                needsRegistration: false,
                navigation: [],
                newAssets: [],
                newUser: false,
                resetLoginFields: false,
                resetCodeFields: false
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false,
                didLogoutFail: true,
                logoutErrorMessage: action.message
            };
        //verify
        case VERIFY_REQUEST:
            return {
                ...state,
                isVerifying: true,
                verifyingError: false,
            };
        case VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false,
                isAuthenticated: true,
            };
        case VERIFY_FAILURE:
            return {
                ...state,
                isVerifying: false,
                isAuthenticated: false,
            };
        //register
        case REGISTER_REQUEST:
            return {
                ...state,
                isSigningUp: true,
                didSignupFail: false
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isSigningUp: false,
                isAuthenticated: true,
                password: '',
                email: '',
                registrationErrorMessage: '',
                user: action.user,
                newUser: true
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                isSigningUp: false,
                isAuthenticated: false,
                didSignupFail: true,
                registrationErrorMessage: action.message
            };
        //reset
        case RESET_REQUEST:
            return {
                ...state,
                isResetting: true,
                didResetFail: false
            };
        case RESET_SUCCESS:
            return {
                ...state,
                isResetting: false,
                didResetSend: true,
                didResetFail: false,
                resetErrorMessage: '',
            };
        case RESET_FAILURE:
            return {
                ...state,
                isResetting: false,
                didResetSend: false,
                didResetFail: true,
                resetErrorMessage: action.message,
            };
        //user
        case USER_SUCCESS:
            return {
                ...state,
                userInfo: action.userInfo,
            }
        case UPDATE_DEVMODE:
            return {
                ...state,
                inDevMode: action.bool
            }
        //reset msg
        case RESET_AUTH_MSG:
            return {
                ...state,
                didLoginFail: false,
                loginErrorMessage: '',
                didLogoutFail: false,
                logoutErrorMessage: '',
                didSignupFail: false,
                registrationErrorMessage: '',
                didResetFail: false,
                resetErrorMessage: '',
            }
        case UPDATE_ADMIN:
            return {
                ...state.didLoginFail,
                isAdmin: action.bool
            }
        //verify access code
        case VERIFY_CODE_REQUEST:
            return {
                ...state,
                isVerifyUpdating: true,
                didVerifyFail: false,
                didVerifyPass: false,
                verifyMessage: '',
            }
        case VERIFY_CODE_SUCCESS:
            return {
                ...state,
                isVerifyUpdating: false,
                didVerifyPass: true,
                verifyMessage: 'Access Code verified',
                accessCode: action.data
            }
        case VERIFY_CODE_FAILURE:
            return {
                ...state,
                errorCount: state.errorCount + 1,
                isVerifyUpdating: false,
                didVerifyFail: true,
                verifyMessage: action.message
            }
        case VERIFY_CODE_RESET:
            return {
                ...state,
                errorCount: 0,
                isVerifyUpdating: false,
                didVerifyFail: false,
                didVerifyPass: false,
                verifyMessage: '',
            }
        //verify email
        case VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                isEmailVerfying: true,
                isEmailDoneLoading: false,
                didEmailFail: false,
                didEmailPass: false,
                emailMessage: '',
                needsRegistration: false,
                email: ''
            }
        case VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isEmailVerfying: false,
                isEmailDoneLoading: true,
                didEmailPass: true,
                emailMessage: 'Email verified',
                email: action.data.toLowerCase()
            }
        case VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                errorCount: state.errorCount + 1,
                isEmailVerfying: false,
                isEmailDoneLoading: true,
                didEmailFail: true,
                emailMessage: action.message,
            }
        case VERIFY_EMAIL_RESET:
            return {
                ...state,
                errorCount: 0,
                isAuthenticated: false,
                isEmailDoneLoading: false,
                didEmailFail: false,
                didEmailPass: false,
                emailMessage: '',
                needsRegistration: false,
                email: '',
                accessCode: null
            }
        case NEEDS_REGIS:
            return {
                ...state,
                needsRegistration: action.data
            }
        case SET_EMAIL:
            return {
                ...state,
                email: action.data.toLowerCase()
            }
        case SET_PASSWORD:
            return {
                ...state,
                password: action.data
            }
        case SET_CODE:
            return {
                ...state,
                accessCode: action.data
            }
        case SET_NEW_ASSETS:
            return {
                ...state,
                newAssets: [...state.newAssets.filter(asset => asset.accessCode !== action.data.accessCode), action.data]
            }
        case RESET_NEW_ASSETS:
            return {
                ...state,
                newAssets: [],
                accessCode: null
            }
        case SET_NAVIGATION:
            return {
                ...state,
                navigation: action.data
            }
        case SET_RESET_LOGIN_FIELDS:
            return {
                ...state,
                resetLoginFields: action.data
            }
        case SET_RESET_CODE_FIELDS:
            return {
                ...state,
                resetCodeFields: action.data
            }
        default:
            return state;
    }
}

export default auth;