import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { makeStyles } from '@material-ui/styles'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
//custom
import Footer from 'src/components/reports/Footer';
import PageHeader from './PageHeader';


let firstBlock = 'The Report provides initial suggestions on how the entity can improve on its own as well as what guidance might be asked of a consultant, mentor or coach. Vector Reports&trade; strongly suggests entities include all four approaches below:'
let secondBlock = 'VIA·Business assessments and their Reports were designed to align with your organization’s needs and goals in mind. No matter which improvement methodology you use when helping your own or another organization get better: Spiral development, Six Sigma, Total Quality Management, Lean / Agile, Business Process Management, Plan-Do-Check-Act, improve most vulnerable, or your own proprietary process, we support these goals:'

const list = () => (
    [
        {
            text: "<b>Prepare to implement report recommendations on selected processes</b>—the processes identified in the Report were selected, sequenced and presented to you based upon your current-state vulnerabilities. We recommend making improvements in the order suggested (1st, 2nd and so on). Essentially, you hold in your hands a roadmap for initial improvements: Read it! Discuss it! Get the facts about it! Act on it! Then check for progress!",
            text1: "Results should NEVER be used as a hammer to shame or punish. Instead, it should be used to prioritize assistance, resources, and guidance to build up both the process and process holder. This can build enthusiasm and good will. Actions should be visible. If a leader does nothing, after learning about a vulnerability, it sends a negative message to those process owners. Inaction diminishes good will and leads to cynicism. So, no matter what, leaders need to say what they are going to do with the results, and then go it."
        },
        {
            text: "<b>Improve lowest-scored process</b>—the Report helped you prioritize what to work on 1st, 2nd, and so on. After your improvements have been made it is natural to want to address other low-scoring / lower maturity processes—that’s ok—the Report bar charts display other low-scoring processes.",
            text1: 'Some say, “Leverage your strengths to augment a weakness.” The assessment approach does NOT recommend this common misunderstanding. For example, you could have a fantastic product (or another strength) and choose to make it even better and inadvertently sacrifice needed improvements in marketing, or commercialization; To then discover that decision led to other larger problems that your newly improved product strength simply cannot address.'
        },
        {
            text: '<b>Follow the influence drivers</b>—the reported low-score processes all have larger “bang for the buck” investment returns than spending resources to improve higher-scored processes. But which ones should you begin with in the absence of another assessment being taken.',
            text1: 'The general rule is to work on the lowest scoring process toward the left side of the bar charts. This is because assessment processes were designed to follow a natural sequence that builds one upon the next. Improving lower scored processes to the left has more inherent value than the same score on the right. However, current-states are fleeting and a different prioritization may be more accurate than this general rule and taking another assessment is advised. '
        },
        {
            text: '<b>Listen to the people who are doing the work</b>—that should be an axiom that is always followed! However, people often see what they expect to see instead of what is actually happening. The experts on what is “actually happening” are those who are actually doing the work itself. They know all about how well, or how poorly, the processes operate. These Reports—along with management, mentors, or consultants—help give those experts a voice in the matter.'
        }
    ]
)

const ClosingAdvicePage = (props) => {

    const {

    } = props;

    const classes = useStyles();

    return (
        <>
            {/* header */}
            <PageHeader />
            <Box mt={4}>
                <Typography variant="h2" sx={{fontSize: 30}}>
                    Next Steps
                </Typography>
                <Typography color="textSecondary" sx={{fontSize: 22}} variant="h4">
                    Implementing the Report Recommendations
                </Typography>
            </Box>

            {/* first content block */}
            <Box mt={3}>
                <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    sx={{mb: 4, fontSize: 20}}
                    dangerouslySetInnerHTML={{ __html: firstBlock }}>
                </Typography>

                {list().map((item, index) => {

                    return <Container sx={{ display: 'flex', mb: 1, mt: 2 }}>
                        <Box sx={{ mr: 2 }}>
                            <Typography variant="body1">
                                {`${index + 1}) `}
                            </Typography>
                        </Box>
                        <Box>
                            {Object.keys(item).map(key => (
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    gutterBottom
                                    sx={{mb: 1, fontSize: 20}}
                                    dangerouslySetInnerHTML={{ __html: item[key] }}>
                                </Typography>
                            ))}
                        </Box>
                    </Container>
                })}
            </Box>

            {/* second content block */}
            <Box mt={3}>
                <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    sx={{fontSize: 20}}
                    dangerouslySetInnerHTML={{ __html: secondBlock }}>
                </Typography>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
    whiteBanner: {
        borderBottom: '3px solid black',
        borderTop: '3px solid black',
    },
})

export default ClosingAdvicePage;

