import  { db, timestamp } from "../../services/firebase";

import {
    assessmentHistoryRequest,assessmentHistorySuccess, assessmentHistoryFailure,
    purchaseHistorySuccess, purchaseHistoryRequest, purchaseHistoryFailure,
    ownershipDataSuccess, ownershipDataRequest, ownershipDataFailure,
    assignedSuccess, assignedRequest, assignedFailure,
    storeItemsSuccess, storeItemsRequest, storeItemsFailure,
    updateAssessorRequest, updateAssessorSuccess, updateAssessorError, hideAssessorNotification,
    updateOwnerRequest, updateOwnerSuccess, updateOwnerError, hideOwnerNotification,
    claimCodeRequest, claimCodeSuccess, claimCodeFailure, claimCodeReset, setLoadingAllData,
} from '../actions';

/*
 * READ
 */


export const loadAllAssessmentData = (email) => async dispatch => {
    if (!email) {
        console.log('assessment: email is undefined');
        return;
    }

    dispatch(setLoadingAllData(true))
    await dispatch(getAssessmentHistory(email))
    await dispatch(getOwnershipData(email))
    await dispatch(getAssignedAssessments(email))
    dispatch(setLoadingAllData(false))
} 

//get assessment history
export const getAssessmentHistory = (email) => async dispatch => {
    if (!email) {
        console.log('assessment: email is undefined');
        return;
    }
    
    dispatch(assessmentHistoryRequest());

    try {
        //query 1. is assessor and not owner
        //query 2. is owner and not assessor
        //query 3. is owner and assessor
        //result : it wont show duplicates when owner is assessor
        
        const historyRef = db.collection("assessmentHistory");
        const query = historyRef.where('assessor.email', '==', email).where('owner.email', '!=', email);
        const query2 = historyRef.where('owner.email', '==', email).where('assessor.email', '!=', email);
        const query3 = historyRef.where('owner.email', '==', email).where('assessor.email', '==', email);
        var history = [];

        //assessor query1
        await query.get().then(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    history.push(doc.data());
                }
            });
        }).catch(error => {
            console.log("Error getting documents: ", error);
        });

        //owner query2
        await query2.get().then(snapshot2 => {
            snapshot2.forEach(doc2 => {
                if (doc2.exists) {
                    history.push(doc2.data());
                }
            });
        }).catch(error => {
            console.log("Error getting documents: ", error);
        });

        //both query3
        await query3.get().then(snapshot3 => {
            snapshot3.forEach(doc3 => {
                if (doc3.exists) {
                    history.push(doc3.data());
                }
            });
        }).catch(error => {
            console.log("Error getting documents: ", error);
        });

        //dispatch results from both queries 
        dispatch(assessmentHistorySuccess(history));
    } catch(error) {
        console.log("Error: ", error);
        dispatch(assessmentHistoryFailure());
    }
}
//get purchase history : Purchase History
export const getPurchaseHistory = (email) => async dispatch => {
    if (email !== undefined) {
        console.log('email is undefined');
    }

    dispatch(purchaseHistoryRequest());
    try {
        
            const historyRef = db.collection("purchaseHistory");
            const query = historyRef.where('purchaserEmail', '==', email).where('ownerEmail', '!=', email);
            const query2 = historyRef.where('purchaserEmail', '!=', email).where('ownerEmail', '==', email);
            const query3 = historyRef.where('purchaserEmail', '==', email).where('ownerEmail', '==', email);

            var history = [];
            
            //get data as purchaser only
            await query.get().then(snapshot => {
                snapshot.forEach(async doc => {
                    let data = {};
                    if (doc.exists) {
                        data = doc.data();
                        data.relationship = 'purchaser';
                        data.status = 'Transferred';
                        //get first owner after purchaser (2nd owner)
                        data.nextOwnership =  await _getOwnershipRecord(data.accessCode, 2);
                        data.displayCode = '.... ' + data.accessCode.slice(-5);

                        history.push(data);
                    }
                });
            }).catch(error => {
                console.log("Error getting documents: ", error.message);
                dispatch(purchaseHistoryFailure());
            });

            //get data as owner, set specific values
            await query2.get().then(snapshot => {
                snapshot.forEach(doc => {
                    let data = {};
                    if (doc.exists) {
                        data = doc.data();
                        data.relationship = 'owner';
                        data.cost = '-';
                        data.paymentMethod = 'Transfer';
                        data.displayCode = '.... ' + data.accessCode.slice(-5);

                        history.push(data);
                    }
                });
            }).catch(error => {
                console.log("Error getting documents: ", error.message);
                dispatch(purchaseHistoryFailure());
            });

            //get data as both
            await query3.get().then(snapshot => {
                snapshot.forEach(doc => {
                    let data = {};
                    if (doc.exists) {
                        data = doc.data();
                        data.relationship = 'both';
                        data.displayCode = '.... ' + data.accessCode.slice(-5);
                        
                        history.push(data);
                    }
                });
            }).catch(error => {
                console.log("Error getting documents: ", error.message);
                dispatch(purchaseHistoryFailure());
            });
                
            dispatch(purchaseHistorySuccess(history));

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(purchaseHistoryFailure());
    }
}
//get ownership data : Transfer Ownership, Assign Assessor
export const getOwnershipData = (email) => async dispatch => {
    
    if (!email) {
        console.log('Error: email is undefined');
    }

    dispatch(ownershipDataRequest());

    try {
        const historyRef = db.collection("purchaseHistory");
        const query = historyRef.where('owner.email', '==', email).where('isCompleted', '==', false);
        const query2 = historyRef.where('proposedOwner', '==', email).where('isCompleted', '==', false);
        var ownership = [];
        //get data
        await query.get().then((snapshot) => {
        //query.onSnapshot(snapshot => {
            snapshot.forEach((doc) => {
                if (doc.exists) {
                    let data = {};
                    data = doc.data();
                    //truncate access code for display
                    data.displayCode = '.... ' + data.accessCode.slice(-5);
                    ownership.push(data);
                }
            });
        })
        await query2.get().then((snapshot) => {
            //query.onSnapshot(snapshot => {
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        let data = {};
                        data = doc.data();
                        //truncate access code for display
                        data.displayCode = '.... ' + data.accessCode.slice(-5);
                        ownership.push(data);
                    }
                });
            })

        dispatch(ownershipDataSuccess(ownership));
        // .catch((error) => {
        //     console.log("Error getting documents: ", error.message);
        //     dispatch(ownershipDataFailure());
        // });

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(ownershipDataFailure());
    }
}
//get store items
export const getStoreItems = () => dispatch => {
    dispatch(storeItemsRequest());

    try {
        let query = db.collection('store').orderBy('type').orderBy('quantity');

        query.get().then(snapshot => {
            var data = [];
            snapshot.forEach(doc => {
                if (doc.exists) {
                    data.push(doc.data());
                }
            })
            dispatch(storeItemsSuccess(data));
        })

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(storeItemsFailure());
    }
}
//get available assessments : Take
export const getAssignedAssessments = (email) => async dispatch => {

    if(!email){
        console.log('No email getAssignedAssessments');
        return;
    }

    dispatch(assignedRequest());

    try {
        let query = db.collection("purchaseHistory");
        query = query.where('assessor.email', '==', email).where('isCompleted', '==', false);
        //get data
        await query.get().then(snapshot => {
            //store available assessments
            let assessments = [];
            snapshot.forEach((doc) => {
                if (doc.exists) {
                    let data = {};
                    data = doc.data();
                    //truncate access code for display
                    data.displayCode = '.... ' + data.accessCode.slice(-5);
                    assessments.push(data);
                };
            });
            //dispatch available assessments
            dispatch(assignedSuccess(assessments));

        }).catch(error => {
            console.log("Error getting documents: ", error.message);
            dispatch(assignedFailure());
        });

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(assignedFailure());
    }
}


/*
 * UPDATE
 */

//verify user and update field assessor
export const updateAssessor = (accessCode, info) => dispatch => {
    if (!accessCode) {
        dispatch(updateAssessorError('Missing Access Code'));
        dispatch(_timeout(hideAssessorNotification()));
        return;
    }
    // if (!info || !info.email) {
    //     console.log('misssingings')
    //     dispatch(updateAssessorError('Missing User Info'));
    //     dispatch(_timeout(hideAssessorNotification()));
    //     return;
    // }

    dispatch(updateAssessorRequest());

    try {
        const history = db.collection('purchaseHistory');
        const query = history.where('accessCode', '==', accessCode).limit(1);
        
        query.get().then(snapshot => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update assessment
                    await doc.ref.update({
                            assessor: {
                                email: info === '' ? '' : info?.email.toLowerCase(),
                                name: info === '' ? '' : info.firstName + ' ' + info.lastName,
                                isClaimed: false
                            },
                            status: 'Assigned',
                            //assessorEmail: info === '' ? '' : info?.email, 
                        }).then(() => {
                            dispatch(updateAssessorSuccess());
                        }).catch(error => {
                            // The document probably doesn't exist.
                            dispatch(updateAssessorError(error.message));
                            console.log(error.message);
                        });
                } else {
                    dispatch(updateAssessorError("Assessment not found"));
                    console.log("Assessment not found");
                }
            });
        }).catch(error => {
            dispatch(updateAssessorError(error.message));
        console.log(error.message);
        });

    } catch(error) {
        dispatch(updateAssessorError(error.message));
        console.log(error.message);
    }

    //reset notificatiion values 
    dispatch(_timeout(hideAssessorNotification()));
}
//verify user and update ownership
export const updateOwnership = (accessCode, info, userEmail, undo) => dispatch => {
    if (!accessCode) {
        dispatch(updateOwnerError('Error: Missing Access Code'));
        dispatch(_timeout(hideOwnerNotification()));
        return;
    }
    if (!info || !info.email) {
        dispatch(updateOwnerError('Error: Missing Info'));
        dispatch(_timeout(hideOwnerNotification()));
        return;
    }
    if (!userEmail) {
        dispatch(updateOwnerError('Error: Missing userEmail'));
        dispatch(_timeout(hideOwnerNotification()));
        return;
    }

    dispatch(updateOwnerRequest());
    //update ownerEmail and status
    try {
        const history = db.collection('purchaseHistory');
        const query = history.where('accessCode', '==', accessCode).limit(1);
        
        query.get().then((snapshot) => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update assessment 
                    let data
                    if(undo) {
                        data = {
                            proposedOwner: '',
                            proposedName: ''
                        }
                    } else {
                        data = {
                            proposedOwner: info?.email.toLowerCase(),
                            proposedName: `${info?.firstName} ${info?.lastName}`,
                        }
                    }

                    await doc.ref.update(data).then(function() {
                            //remove assessor
                            // dispatch(
                            //     updateAssessor(
                            //         accessCode, 
                            //         {
                            //             email: '', 
                            //             firstName: '',
                            //             lastName : ''
                            //         }
                            //     )
                            // )
                            //create new ownership record
                            dispatch(_createOwnershipRecord(accessCode, info.email, 'owner', userEmail));
                        }).catch(function(error) {
                            // The document probably doesn't exist.
                            dispatch(updateOwnerError("Error updating document: ", error));
                            //reset notificatiion values
                            dispatch(_timeout(hideOwnerNotification()));
                        });
                } else {
                    dispatch(updateOwnerError("Assessment not found"));
                    //reset notificatiion values
                    dispatch(_timeout(hideOwnerNotification()));
                }
            });
        }).catch((error) => {
            dispatch(updateOwnerError(error.message));
            //reset notificatiion values
            dispatch(_timeout(hideOwnerNotification()));
        });
    } catch(error) {
        dispatch(updateOwnerError('Error: ', error.message));
        //reset notificatiion values
        dispatch(_timeout(hideOwnerNotification()));
    }
}
//claim access code feature
export const claimCode = (code, email) => dispatch => {

    if (!code) {
        dispatch(claimCodeFailure('Missing access code param'));
        dispatch(_timeout(claimCodeReset()));
        return;
    }

    dispatch(claimCodeRequest());

    try {
        //pull the record 
        const query = db.collection('purchaseHistory').where('accessCode', '==', code).limit(1);
        query.get().then(async querySnapshot => {

            if(!querySnapshot.empty) {
                const purchase = querySnapshot.docs[0].data(); 
                // console.log('purchasehistory: ', purchase)
                //update the email if field is empty

                if (purchase?.isClaimed === false) {
                // if (purchase.assessorEmail.length < 6 || purchase.assessorEmail === 'adminPurch@vectorreports.com') {
                    await querySnapshot.docs[0].ref.update({ assessorEmail: email.toLowerCase(), status: 'Assigned' })
                        .then(() => {
                            dispatch(claimCodeSuccess());
                        }).catch(error => {
                            // The document probably doesn't exist.
                            dispatch(claimCodeFailure("Error: ", error));
                        });

                } else {
                    console.log('This Access Code is already assigned');
                    dispatch(claimCodeFailure('This Access Code is already assigned'));
                }
            } else {
                console.log('This Access code does not Exist');
                dispatch(claimCodeFailure('This Access code does not exist'));
            }
        }).catch(error => {
            console.log(error.message);
            dispatch(claimCodeFailure(error.message));
        });
        //create record to test 
    } catch (error) {
        console.log(error.message)
        dispatch(claimCodeFailure(error.message));
    }

    //reset notificatiion values 
    dispatch(_timeout(claimCodeReset()));
}

/*
 * CREATE
 */

//creates record in purchaseHistory
export const createPurchase =  (user, purchase)  => async dispatch => {

    if(!user.email){
        console.log('No user createPurchaseRecord');
        return;
    }
    if(!purchase.quantity || !purchase.type || !purchase.price || !purchase.id){
        console.log('No purchase createPurchaseRecord');
        return;
    }
    
    try {
        //iterate through quantity of assessments purchased
        for (let i = 0; i < purchase.quantity; i++) {
            const cost = purchase.price / purchase.quantity;
            //create purchase record
            const document = await db.collection('purchaseHistory').add({
                accessCode: '',
                assessorEmail: '', //todo: update on completion
                cost: cost.toFixed(2),
                date: timestamp, 
                isDiscounted: purchase.quantity > 1 ? true : false, 
                ownerEmail: user.email,
                paymentMethod: 'Credit Card', //todo: figure out other options
                purchaserEmail: user.email,
                status: 'Available',
                storeId: purchase.id, 
                type: purchase.type,
                isExpired : false,
                isCompleted: false,
            });
            //TODO: return error dispatch
            if (!document.id){
                console.log('No document.id returned');
                return; 
            }

            //get document and set accesscode
            db.collection('purchaseHistory').doc(document.id).update({accessCode: document.id});

            //create ownership document
            dispatch(_createOwnershipRecord(document.id, user.email, 'purchaser'));
            
            //1. set up stripe
            //2. create store success and error messages for payment

        }

    } catch(error){
        console.log('error: ', error.message);
    }
}

/*
 * HELPER FUNCTIONS
 */


const _timeout = (action) => dispatch => {
    setTimeout(() => {
        dispatch(action);
    }, 10000);
}
//creates new ownershipHistory record
const _createOwnershipRecord = (accessCode, email, type, userEmail) => async dispatch => {

    if(!accessCode){
        console.log('No accessCode _createOwnershipRecord');
        return;
    }
    if(!email){
        console.log('No email _createOwnershipRecord');
        return;
    }
    if(!type){
        console.log('No type _createOwnershipRecord');
        return;
    }
    if(!type){
        console.log('No userEmail _createOwnershipRecord');
        return;
    }

    try {
        const ownershipHistory = db.collection('ownershipHistory');
        switch(type){
            case 'purchaser':
                //create first record
                await ownershipHistory.add({ 
                    accessCode,
                    dateTime: timestamp,
                    email,
                    count: 1, //if record exists else is new record
                    previousOwner: '',
                });
                break;
            case 'owner':
                //get previous and create next record;
                const query = ownershipHistory.where('accessCode', '==', accessCode).orderBy('count', 'desc').limit(1);

                query.get().then(async snapshot => {
                    var previousOwner = {};
                    previousOwner.count = 0;
                    //store previous owner of assessment
                    snapshot.forEach(doc => {
                        if (doc.exists) {
                            previousOwner = doc.data();
                        }
                    });

                    //create new record
                    await ownershipHistory.add({ 
                        accessCode,
                        dateTime: timestamp,
                        email,
                        count: previousOwner.count + 1, //if record exists else is new record
                        previousOwner: userEmail,
                    }).then(() => {
                        dispatch(updateOwnerSuccess());

                        dispatch(_timeout(hideOwnerNotification()));
                    });

                }).catch(error => {
                    console.log('Error: ', error.message);
                });
                break;
            default:
                break;
        }

    } catch(error){
        console.log('Error: ', error.message);
    }
}
//get most recent ownership record for access code based on count
const _getOwnershipRecord = async (accessCode, count) => {

    if (!accessCode) {
        console.log('no access code get ownership record');
        return;
    }
    if (!count) {
        console.log('no count get ownership record');
        return;
    }

    //get top record for access code
    let query = db.collection('ownershipHistory').where('accessCode', '==', accessCode).where('count', '==', count).limit(1);
    
    var owner = {};

    await query.get().then(snapshot => {
        //store previous owner of assessment
        snapshot.forEach(doc => {
            if (doc.exists) {
                owner = doc.data();
            }
        });
    }).catch(error => {
        console.log('Error: ', error.message);
    });
    
    return owner;
}




