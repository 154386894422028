import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';

const Cancel = () => {

    return (
        <>
            <Box my={8} mx={4} sx={{ minHeight: '100%', py: 3 }} >
                <Container maxWidth='lg'>
                    <Typography variant="h2" gutterBottom>
                        Oops! Something went wrong
                    </Typography>
                    <Typography sx={{mb: 2}} variant='subtitle1' gutterBottom>
                        Your purchase information provided to our payment processor failed in one or more ways
                    </Typography>
                    <Typography sx={{mb: 4}} variant='subtitle1' gutterBottom>
                        We would be delighted to sell you an assessment, but please know we do not retain any information
                        from the processor. We encourage you to check your card numbers and carefully enter those again.
                    </Typography>

                    <Button component={Link} to="/" variant="contained">Home</Button>
                </Container>
            </Box>
        </>
    )
}

export default Cancel

