import React, { useEffect, useState } from 'react';
import { connect  } from 'react-redux';
import { getPurchaseHistory } from 'src/redux/thunks';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Toolbar, TableSortLabel, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import TableSpinner from 'src/components/shared/TableSpinner';


const PurchaseHistory = (props) => {
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('desc');
    const [purchaseTotal, setPurchaseTotal] = useState(0);

    const classes = useStyles();
    
    //redux variables 
    const { 
        userInfo, 
        purchaseHistory,
        isPurchaseLoading,
        getList
    } = props;
    
    useEffect(() => {
        //get purchase history: on refresh, on nav click
        getData();
    }, [userInfo])

    useEffect(() => {
        calculateTotal();   
    }, [purchaseHistory])

    const getData = () => {
        //get purchase history
        getList(userInfo.email);
    }
    const calculateTotal = () => {
        try {
            let total = 0;
            //loop through history and add up purchases
            purchaseHistory.map((row) => {
                if (row.paymentMethod != 'Transferred' &&  !isNaN(row.cost)) {
                    total += parseFloat(row.cost);
                }
            });

            //update state
            setPurchaseTotal(total.toFixed(2));
            
        } catch(error) {
            console.log('ph error caught');
        }
    }
    

    const formatDate = (seconds) => {
        if(!isNaN(seconds)) {
           const date = moment(seconds * 1000).format('MM/DD/YYYY');
           return date;
        }
        return 0;
    }

    const handleRequestSort = (property) => {
        setOrderBy(property);
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    
    return (
        <>
            <Helmet>
                <title>Vector Reports | Purchase</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth={false}>
                    <Typography color="textPrimary" variant="h2" >
                        Purchase History
                    </Typography>
                    <Typography color="textSecondary" gutterBottom  variant="body2" >
                        View your purchases
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth={false}>
                        <Paper sx={{ width: '100%', marginBottom:2 }}>
                            <Toolbar>
                                <Typography variant="h4" id="historyLabel" color="textSecondary" > 
                                    Assessment Purchases
                                </Typography>
                            </Toolbar>
                            <TableContainer sx={{pl:1.2}}>
                                <Table aria-label="historyTable" size={'medium'}>
                                    <caption >
                                        <Box sx={{display: 'flex', flexDirection:'column', justifyContent:'flex-start',}}>
                                            <Typography color="textPrimary" variant="h3" >
                                                ${purchaseTotal}
                                            </Typography>  
                                            <Typography color="textSecondary" gutterBottom  variant="body2" >
                                                paid this calendar year
                                            </Typography>
                                        </Box>
                                    </caption>
                                    {/* Table Header - Sort */}
                                    <TableHead>
                                        <TableRow >
                                            {   
                                                headCells.map((headCell) =>{  
                                                    return (
                                                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                                            <TableSortLabel 
                                                                active={orderBy === headCell.id}
                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                onClick={() => handleRequestSort(headCell.id)}
                                                            >
                                                                { headCell.label }
                                                            </TableSortLabel>
                                                        </TableCell>
                                                )})
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {/* Table Body */}
                                    <TableBody>
                                        { purchaseHistory && purchaseHistory.length > 0 
                                            ? (
                                                stableSort(purchaseHistory, getComparator(order, orderBy))
                                                .map((row, index) => {
                                                    const key = `history-${index}`;
                                                    const cost =  row.relationship == 'owner' ? row.cost : `$${row.cost}`;
                                                    const statusColor = row.status == 'Available' ? '#4caf50' : ''
                                                    const statusWeight = row.status == 'Available' ? '600' : ''
                                                    
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell>{row.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                                            <TableCell>{cost}</TableCell>
                                                            <TableCell>{formatDate(row.date.seconds)}</TableCell>
                                                            <TableCell>{row.isDiscounted ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell>{row.paymentMethod}</TableCell>
                                                            { row.status == 'Transferred'
                                                                ?   (<TableCell>
                                                                        <Box className={classes.center}>
                                                                            {row.status}
                                                                            <Box pl={1} className={classes.center}>
                                                                                <Tooltip title={row.nextOwnership.email} >
                                                                                    <InfoOutlinedIcon sx={{ fontSize:18}} />
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>)

                                                                :   <TableCell  sx={{ color:statusColor, fontWeight: statusWeight }} >{row.status}</TableCell>
                                                            }
                                                            <TableCell>{row.displayCode}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                               <TableSpinner isLoading={isPurchaseLoading} colSpan={headCells.length}/>
                                            )
                                        }
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <Toolbar></Toolbar> */}
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

const useStyles = makeStyles({
    center: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'left', 
        alignItems: 'center',
    }
});

const headCells = [
    { id: 'type', numeric: false, label: 'Name' },
    { id: 'cost', numeric: true, label: 'Cost' },
    { id: 'date', numeric: true, label: 'Date' },
    { id: 'isDiscounted', numeric: false, label: 'Discount' },
    { id: 'paymentMethod', numeric: false, label: 'Method' },
    { id: 'status', numeric: false, label: 'Status' },
    { id: 'displayCode', numeric: false, label: 'Code' },
];

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    purchaseHistory: state.assessment.purchaseHistory,
    isPurchaseLoading: state.assessment.isPurchaseLoading,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getPurchaseHistory(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseHistory);