

import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
import PageHeader from './PageHeader';

const VulnerableProcessPage = (props) => {

    const {
        assessorData
    } = props;

    //variables
    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Vulnerable Process Recommendations
                </Typography>
            </Box>

            {/* banner */}
            <Box className={classes.blueBanner} py={0.5} mt={1}>
                <Typography variant="body1" style={{ fontSize: 18 }} m={1}>
                    <i>The following pages contain specific recommendations you, your team or organization ought to address.
                        They were identified by your assessed low-rated, yet important, processes. These recommendations
                        contain generally accepted good business and organizational practices.</i>
                </Typography>
            </Box>

            {/* static content */}
            <Box mt={8}>
                <Typography gutterBottom variant="h4">
                    Introduction to Process Recommendations: General Guidance
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={8}>
                    Your customized report focuses attention on a few prioritized process recommendations for your
                    specific status. Correcting these imbalances and process immaturities could make the biggest difference
                    for you at this time.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    The messages contained within each process recommendation help identify where vulnerabilities reside
                    and name a few generally accepted business practices that are applicable for that particular process. In
                    some cases, you may be using those processes already, but perhaps, not as well as you could be.
                </Typography>
                <Typography variant="body1" style={{ fontSize: 21 }} mt={4}>
                    In general, process identified herewith will also affect other processes as they are interdependent.
                    Consider the rating result, the identified tools and methods, and then read what your current-state level
                    recommendation suggests you should be building toward in the next level.
                </Typography>
            </Box>

            <Box mt={8}>
                <Box style={{ display: 'flex' }}>
                    <Box mr={1.5}>
                        <img width={40} src="/static/images/3graphic.png" id="3" />
                    </Box>
                    <Typography gutterBottom variant="body1" style={{ fontSize: 21 }}>
                        A Level 3 rating means only one (or none) of the
                        processes in this Category are vulnerable and
                        can be used to support other processes.
                        Congratulations!
                    </Typography>
                    <Box style={{marginTop: -40}}>
                        <img width={300} src="/static/images/vsba/stair-with-arrow.png" id="stair-arrow" />
                    </Box>
                </Box>
                <Box style={{ display: 'flex' }} mt={2}>
                    <Box mr={1.5}>
                        <img width={40} src="/static/images/2graphic.png" id="3" />
                    </Box>
                    <Typography variant="body1" style={{ fontSize: 21 }}>
                        A Level 2 rating likely means that although you are using certain process tools and methods to
                        accomplish work, you may not be able to completely depend upon them to produce reliable
                        results. If a process is not integrated with other processes to produce a standard that can be
                        controlled and measured, you need to build reliability into it so that it supports other processes
                        to ensure systemic performance.
                    </Typography>
                </Box>
                <Box style={{ display: 'flex'}} mt={3}>
                    <Box mr={1.5}>
                        <img width={40} src="/static/images/1graphic.png" id="3" />
                    </Box>
                    <Typography variant="body1" style={{ fontSize: 21 }}>
                        A Level 1 rating likely means you are performing only some of the work called for in the process.
                        If you are suffering from immature, unreliable or uncontrolled results, or the process is labor
                        intensive (which almost always introduces unintended errors), you need to develop the nascent
                        potential of the identified tools and methods to create the ability to perform.
                    </Typography>
                </Box>
                <Box style={{ display: 'flex' }} mt={2}>
                    <Box mr={1.5}>
                        <img width={40} src="/static/images/0graphic.png" id="3" />
                    </Box>
                    <Typography variant="body1" style={{ fontSize: 21 }}>
                        A Level 0 rating likely means you are not performing the work or using the tools called for in this
                        process, or, you are just initiating efforts to create them. If you are being surprised by
                        unexpected negative discoveries as you progress, you need to identify, install and build these
                        tools and methods inside your organization.
                    </Typography>
                </Box>
            </Box>
            <Typography variant="body1" mt={4} style={{ fontSize: 21 }}>  Your custom recommendations follow: </Typography>
            {/* outro + graphics */}
            {/* <Box mt={22} className={classes.splitRow}>
                <Box mt={20} width='70%'>
                    <Typography variant="h4">  Individual Vulnerable Process </Typography>
                    <Typography variant="h4">  Recommendations </Typography>
                    <Typography variant="h4">  follow NEXT </Typography>
                </Box>
                <Box>
                    <Box ml={-10} sx={{ position: 'abolute', transform: 'rotate(-40deg)' }} >
                        <img width={200} src="/static/images/vsba/stair-arrow-hori.png" id="stair-arrow" />
                    </Box>
                    <Box sx={{ position: 'relative', marginTop: 4 }} >
                        <img width={500} src="/static/images/vsba/stairs.png" id="stairs-graphic" />
                    </Box>
                </Box>
            </Box> */}
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    leftCenteredColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    blueBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#B8CCE4',
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,
})

export default connect(mapStateToProps)(VulnerableProcessPage);
