import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Box, Typography, TextField, Link } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { NavLink as RouterLink, useLocation } from 'react-router-dom'

import {
    Eye as EyeIcon,
    EyeOff as EyeOffIcon
} from 'react-feather';

import { verifyEmail, loginUser } from 'src/redux/thunks'
import { setPassword, setResetCodeFields, setResetLoginFields, verifyEmailReset } from 'src/redux/actions'

const Login = () => {

    const formikRef = useRef()
    const dispatch = useDispatch()
    const location = useLocation()

    const userEmail = useSelector(state => state.auth.email)
    const isLoggingIn = useSelector(state => state.auth.isLoggingIn)
    const didLoginFail = useSelector(state => state.auth.didLoginFail)
    const loginErrorMessage = useSelector(state => state.auth.loginErrorMessage)
    const isEmailDoneLoading = useSelector(state => state.auth.isEmailDoneLoading)
    const didEmailPass = useSelector(state => state.auth.didEmailPass)
    const isEmailVerfying = useSelector(state => state.auth.isEmailVerfying)
    const resetLoginFields = useSelector(state => state.auth.resetLoginFields)

    const [email, setEmail] = useState(userEmail)
    const [showPassword, setShowPassword] = useState('')

    useEffect(() => {
        if ((location.key && formikRef?.current)) {
            formikRef.current.resetForm()
            setEmail('')
            dispatch(verifyEmailReset())
            dispatch(setResetLoginFields(false))
        }
    }, [location, formikRef, resetLoginFields])

    const handleFocus = () => {
        dispatch(setResetCodeFields(true))
    }

    /* step 1 enter email */
    if (isEmailDoneLoading && didEmailPass) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: 300, }}>
                <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={{
                        email: email,
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required')
                    })}
                    onSubmit={(data) => {
                        //data.email = email;
                        dispatch(setPassword(data.password))
                        dispatch(loginUser(data))
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ mb: 1 }}>
                                <Typography color="textPrimary" variant="h2" >
                                    Returning User
                                </Typography>
                            </Box>
                            <TextField
                                sx={{ backgroundColor: 'white' }}
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                            // autoComplete="username"
                            />
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                label="Password"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? "text" : "password"}
                                value={values.password}
                                variant="outlined"
                                autoComplete="current-password"
                                autoFocus
                                sx={{ backgroundColor: 'white' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <Box pt={1}>
                                <Box >
                                    <Typography color="textSecondary" variant="body1" >
                                        <Link component={RouterLink} to="/reset" variant="h5">
                                            <b>Reset Password</b>
                                        </Link>
                                    </Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        Or {' '}
                                        <Link component={Button} onClick={() => {
                                            resetForm()
                                            dispatch(verifyEmailReset())
                                        }} variant="h5" color="#FF0000">
                                            <b>Cancel</b>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ py: 2 }}>
                                <Button
                                    color="primary"
                                    disabled={isLoggingIn}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    sx={{ width: 200 }}
                                >
                                    Login
                                </Button>

                                {didLoginFail && (
                                    <Typography color="#f44335" pt={0.5} fontSize={15} >
                                        {loginErrorMessage}
                                    </Typography>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        );
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: 300, }}>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize
                    initialValues={{
                        email: email,
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(60).required('Email is required'),
                        })
                    }
                    onSubmit={(data) => {
                        data.email = data.email.toLowerCase()
                        dispatch(verifyEmail(data.email, true))
                        setEmail(data.email)
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ mb: 1 }}>
                                <Typography color="textPrimary" variant="h2" >
                                    Login
                                </Typography>
                            </Box>
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Enter your established email"
                                margin="normal"
                                name="email"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                                autoComplete="username"
                                sx={{ backgroundColor: 'white' }}
                            />
                            <Box sx={{ py: 2 }}>
                                <Button
                                    color="primary"
                                    disabled={isEmailVerfying}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    sx={{ width: 200 }}
                                >
                                    Login
                                </Button>

                                {didLoginFail && (
                                    <Typography color="#f44335" pt={0.5} fontSize={15} >
                                        {loginErrorMessage}
                                    </Typography>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        )
    }
}

export default Login
