
import firebaseApp from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"


// Your app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA7vwEkZgDvAhZIjWhVhrpySr-r0y2lLjQ",
    authDomain: "business-maturity-model-cf233.firebaseapp.com",
    projectId: "business-maturity-model-cf233",
    storageBucket: "business-maturity-model-cf233.appspot.com",
    messagingSenderId: "962045912930",
    appId: "1:962045912930:web:5119ce2446edd70ffaee3b",
    measurementId: "G-7XNENR8KWL"
  };


export const firebase = firebaseApp.initializeApp(firebaseConfig);
const baseDb = firebase.firestore();
export const functions = firebase.functions()
export const storage = firebase.storage()

export const db = baseDb;
export const timestamp = firebaseApp.firestore.FieldValue.serverTimestamp();



