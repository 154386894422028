
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography, Card, CardContent, } from '@material-ui/core';

import { resetPassword } from 'src/redux/thunks';
import Footer from 'src/components/shared/Footer';

const ResetPassword = (props) => {

    const { 
        isResetting, 
        didResetSend, 
        didResetFail, 
        resetErrorMessage,
        reset 
    } = props;

    return (
        <>
        <Helmet>
            <title>Vector Reports | Reset</title>
        </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'center' }}>
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        })}
                        onSubmit={(data) => {
                            //use thunk to register user and store in redux 
                            reset(data);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <Card >
                                <CardContent >
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ mb: 3 }}>
                                            <Typography color="textPrimary" variant="h2" >
                                                Reset Password
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom variant="body2" >
                                               Enter your email address
                                            </Typography>
                                        </Box>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email Address"
                                            margin="normal"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                            variant="outlined"
                                        />
                                        <Box sx={{ py: 2 }}>
                                            <Button
                                                color="primary"
                                                disabled={isResetting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Reset
                                            </Button>

                                            {(didResetFail && touched.email) &&  ( 
                                                <Typography color="#f44335" pt={2}  fontSize={15} >
                                                   {resetErrorMessage}
                                                </Typography>
                                            )}
                                            {(didResetSend && touched.email) &&  ( 
                                                <Typography color="#0bd17d" pt={2}  fontSize={15} >
                                                   Email Sent
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box pt={1}>
                                            <Typography color="textSecondary" variant="body1" >
                                                Back to 
                                                {' '}
                                                <Link component={RouterLink} to="/" variant="h5">
                                                    <b>Login</b>
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </form>
                                </CardContent>
                            </Card>
                        )}
                    </Formik>
                </Container>
            </Box>
            <Footer />
        </>
    );
}

const mapStateToProps = state => ({
    isResetting: state.auth.isResetting,
    didResetSend: state.auth.didResetSend,
    didResetFail: state.auth.didResetFail,
    resetErrorMessage: state.auth.resetErrorMessage,
});

const mapDispatchToProps = dispatch => ({
    reset: data => dispatch(resetPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);