import {
    REMOVE_PAYMENT_SESSION,
    SET_PAYMENT_SESSION
} from '../actions/'

const defaultState = {
    session: null
}

const payment = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PAYMENT_SESSION:
            return {
                ...state,
                session: action.session
            }
        case REMOVE_PAYMENT_SESSION:
            return {
                ...state,
                session: null
            }
        default:
            return state
    }
}

export default payment