import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const styles = {
    container: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: -100,
        width: '100%'
    },
    box: {
        fontSize: '48px',
        display: 'block',
        margin: '0 auto',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    }
}

export default function Loading() {
    return (
        <Box sx={styles.container}>
            <CircularProgress sx={styles.box} />
        </Box>
    );
}