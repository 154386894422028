import React, { useEffect} from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, List, ListItem } from '@material-ui/core'
import Copyright from 'src/components/shared/Copyright'
import { makeStyles } from '@material-ui/styles'
/*
 * loops through array to build lists
 */

const FormatList = (props) => {

    const {
        list,
    } = props;

    const classes = useStyles();
    
    const InitList = () => {
        return DisplayList(list)
    }

    //loop through 
    const DisplayList = (listObj, isNested) => {
        //console.log(listArr)
        const isOrdered = listObj.ordered ?  true : false;
        const listArr = listObj.ordered ?  listObj.ordered  : listObj.unordered;
        try {
             return ( 
                 <ListType isOrdered={isOrdered}>
                    {Array.isArray(listArr) && (
                        listArr.map((element, index) => {
                            const isObject = typeof element == 'object';
                            //skip isOrdered object
                            if(!element.isOrdered){
                                //skip if array, it is addressed below
                                if (!isObject) {
                                    return (    
                                        <li key={`dynamic-list-${index}`} style={{ marginLeft: (isNested ? 50 : 0)}}> 
                                            <Typography  variant="body1" style={{ fontSize: 18 }} mt={2}> {element} </Typography>
                                            { 
                                                //display next array in current <li> if applicable
                                                typeof listArr[index + 1] === 'object' && DisplayList(listArr[index +1 ], true)
                                            }
                                        </li> 
                                    )
                                }
                            }
                        })
                    )}
                 </ListType>
             )
        } catch (e) {
            console.log('DisplayList error: ', e.message)
        }
    }

    //set ordered vs unordered lists
    const ListType = props => {
        if (!props.isOrdered) {
            return (
                <ul>
                    {props.children}
                </ul>
            )
        } else {
            return (
                <ol style={{fontFamily: 'Arial', fontSize: 18}}>
                    {props.children}
                </ol>
            )
        }
    }

    return (
       <Box ml={6}>
            <InitList /> 
        </Box>
    )
}

const useStyles = makeStyles({

})

FormatList.propTypes = {
    list: PropTypes.object.isRequired,

}

export default FormatList; 