
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAssessmentHistory, getAdminReports, getCompletedAssessment } from 'src/redux/thunks'
import { updateReportContent } from 'src/redux/actions'
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import EnhancedTable from 'src/components/shared/EnhancedTable';
import DownloadReport from 'src/views/app/DownloadReport';
import HeaderButtons from 'src/components/shared/HeaderButtons';
import Footer from 'src/components/shared/Footer';


const Grouping = (props) => {

    //redux variables
    const {
        userInfo,
        history,
        isLoading,
        getList,
        getAdminReports,
        getCompletedAssessment,
        isCreateEvalLoading,
        adminReports,
        updateReportContent
    } = props;

    const navigate = useNavigate();


    const openSelected = async (items) => {
        navigate('/app/heatmap', { state: { data: items }, replace: true })
    }

    return (
        <>
            <Box sx={{ minHeight: '100%' }} >
                <Box mt={2}>
                    <Typography color="textPrimary" variant="body1" sx={{ fontSize: 20, textAlign: 'center', marginLeft: 27, marginBottom: '.62em' }}>
                        Select which Assessments to add to the <b>Grouping Report</b>
                    </Typography>
                    <EnhancedTable
                        headCells={headCells}
                        rows={history}
                        isLoading={isLoading}
                        openSelected={openSelected}
                        checkBox={true}
                    />
                </Box>
            </Box>
        </>
    );
}


const headCells = [
    { id: 'organizationAssessed', numeric: false, disablePadding: false, label: 'Company Assessed' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
    { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
    { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
    { id: 'include', numeric: true, disablePadding: false, label: 'Select to Include' },
];

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    history: state.assessment.assessmentHistory,
    isLoading: state.assessment.isAssessmentLoading,
    isCreateEvalLoading: state.takingAssessment.isCreateEvalLoading,
    reportContent: state.takingAssessment.reports, //fetch report content on open  
    adminReports: state.adminReports.reports,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getAssessmentHistory(email)),
    getAdminReports: () => dispatch(getAdminReports()),
    getCompletedAssessment: code => dispatch(getCompletedAssessment(code)),
    updateReportContent: content => dispatch(updateReportContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Grouping);