import React from 'react';

import { FormGroup, FormControlLabel,Switch  } from '@material-ui/core';
import { connect  } from 'react-redux';
import { updateDevMode } from 'src/redux/actions';

const DevModeSwitch = (props) => {

    const { 
        inDevMode,
        updateDevMode
    } = props;

    return ( <></>
        // <FormGroup>
        //     <FormControlLabel control={<Switch checked={inDevMode} color="secondary" onChange={() => updateDevMode(!inDevMode)}/>} label="Dev Mode" sx={{ color:"black"}} />
        // </FormGroup>
    )
}


const mapStateToProps = state => ({
    inDevMode: state.auth.inDevMode,
})

const mapDispatchToProps = dispatch => ({
    updateDevMode: (bool) => dispatch(updateDevMode(bool))
})

export default connect(mapStateToProps, mapDispatchToProps)(DevModeSwitch);