import React, { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import moment from 'moment';

import TableSpinner from './TableSpinner';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
    const [isLoading, setIsLoading] = useState('false');

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {
                            headCell.id !== 'reports' && headCell.id !== 'include'
                                ? <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: '',
            },
    title: {
        flex: '1 1 100%',
        paddingLeft: 25
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, checkBox, onDisplayGrouping } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <Typography className={classes.title} variant="h4" id="tableTitle" color="textSecondary" >
                Select to View and/or download an assessment report.
            </Typography>

            {checkBox && <Button onClick={() => onDisplayGrouping()} variant="contained" sx={{ mt: 1, width: 110, height: 50, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}>
                Display Grouping
            </Button>
            }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const EnhancedTable = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selectedRowReports, setSelectedRowReports] = React.useState([]);

    const { headCells, rows, isLoading, openDownload, openSelected, checkBox } = props;

    const handleRequestSort = (event, property) => {
        //console.log(property) //org,type, asessment
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    React.useEffect(() => {
        //add selected reports to state
        if (rows && rows.length > 0) {
            let reports = [];
            rows.map((row, index) => {
                if (row.reports && row.reports.length > 0) {
                    const reportScope = {
                        row: index,
                        report: 0
                    };
                    reports.push(reportScope);
                }
            });
            setSelectedRowReports(reports);
        }
    }, [rows]);

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (accessCode) => {
        if(selected.find(temp => temp.accessCode === accessCode)){
            return true
        } else {
            return false
        }
    }

    const getDate = (seconds) => {
        if (!isNaN(seconds)) {
            const date = moment(seconds * 1000).format('MM/DD/YYYY');//new Date(seconds * 1000).toString();
            return date;
        }
        return 0;
    }



    const handleSelectGroupingClicked = () => {
        if (selected.length > 0) {
            openSelected(selected)
        }
    }

    const handleSelect = (checked, item) => {
        if(checked && selected.length < 8) {
            setSelected([...selected, item])
        } else {
            setSelected(selected.filter((i) => {
                return i.accessCode !== item.accessCode
            }))
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} checkBox={checkBox} onDisplayGrouping={handleSelectGroupingClicked} />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="viewTable" size={'medium'} aria-label="enhanced table" >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows ? rows.length : 0}
                            headCells={headCells}
                        />
                        <TableBody>

                            {rows && rows.length > 0
                                ? (
                                    stableSort(rows, getComparator(order, orderBy))
                                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.accessCode)
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            const key = `assessment-${index}`;
                                            const selectId = `report-select-${index}`;
                                            //const seletectLabelId = `report-label-select-${index}`;
                                            return (
                                                <TableRow hover onClick={(event) => handleClick(event, index)} role="checkbox" tabIndex={-1} key={key} >
                                                    <TableCell component="th" id={labelId} scope="row" padding="normal" sx={{width: 190}}>
                                                        {row.organizationAssessed}
                                                    </TableCell>
                                                    <TableCell>{row.project}</TableCell>
                                                    <TableCell sx={{width: 75}}> {getDate(row.dateAssessed.seconds)}</TableCell>
                                                    <TableCell sx={{width: 75}}>{row.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                                    <TableCell>{'.... ' + row.accessCode.slice(-5)}</TableCell>
                                                    <TableCell>{row.assessorSortName}</TableCell>
                                                    <TableCell>{row.owner?.email}</TableCell>
                                                    {checkBox && <TableCell style={{ width: 105 }} align='center' padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            onChange={(e, checked) => handleSelect(checked, row)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </TableCell>
                                                    }
                                                    {!checkBox && <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => openDownload(row)}
                                                            sx={{ width: 100, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })
                                ) : (
                                    <TableSpinner isLoading={isLoading} colSpan={headCells.length} />
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

EnhancedTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    openDownload: PropTypes.func
};

export default EnhancedTable;