import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography, Card, CardContent, } from '@material-ui/core';

import { loginAdmin } from 'src/redux/thunks';
import Footer from 'src/components/shared/Footer';

const Admin = (props) => {

    const { 
        isLoggingIn, 
        didLoginFail, 
        loginErrorMessage, 
        isAdmin,
        loginAdmin 
    } = props;
    
    const navigate = useNavigate();

    const adminPage = () => navigate('/admin/assessment-content', { replace: true });

    const content = (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '90%', justifyContent: 'center' }}>
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={(data) => {
                            //use thunk to register user and store in redux 
                            data.email = data.email.toLowerCase()
                            loginAdmin(data);
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <Card >
                                <CardContent >
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ mb: 3 }}>
                                            <Typography color="textPrimary" variant="h2" >
                                                Admin Login
                                            </Typography>
                                        </Box>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email Address"
                                            margin="normal"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                            variant="outlined"
                                            autoComplete="username"
                                        />
                                        <TextField
                                            error={Boolean(touched.password && errors.password)}
                                            fullWidth
                                            helperText={touched.password && errors.password}
                                            label="Password"
                                            margin="normal"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                            autoComplete="current-password"
                                        />
                                        <Box sx={{ py: 2 }}>
                                            <Button
                                                color="primary"
                                                disabled={isLoggingIn}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Log in
                                            </Button>

                                            { didLoginFail &&  ( 
                                                <Typography color="#f44335" pt={2}  fontSize={15} >
                                                   {loginErrorMessage}
                                                </Typography>
                                            )}
                                        </Box>
                                        
                                    </form>
                                </CardContent>
                            </Card>
                        )}
                    </Formik>
                </Container>
            </Box>
            <Footer />
        </>
    );

    return isAdmin ? adminPage : content;
};

const mapStateToProps = state => ({
    isLoggingIn: state.auth.isLoggingIn,
    didLoginFail: state.auth.didLoginFail,
    loginErrorMessage: state.auth.loginErrorMessage,
    isAdmin: state.auth.isAdmin 
});

const mapDispatchToProps = dispatch => ({
    loginAdmin: user => dispatch(loginAdmin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin)