

import React from 'react'
//ui
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//custom
import Footer from 'src/components/reports/Footer';
import PageHeader from './PageHeader';

const TocPage = () => {

    //variables
    const classes = useStyles();

    return (
        <>
        <PageHeader />
            {/* header */}
            <Box mt={14} px={4}>
                <Typography   gutterBottom variant="h2">
                    Table of Contents
                </Typography>
            </Box>

            {/* body */}
            <Box px={4}>
                <Box className={classes.splitRow} mt={12}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Introduction
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        ............................. 3
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Your Capabilities Maturity Plot
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        ............................. 4
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Interpreting Your Capabilities Maturity Plot
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                     ............................. 5
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Interpreting Your Vulnerable Quadrant
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                    ............................. 6
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Intepreting Your Process Ratings
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                    ............................. 8
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Your Category Process Ratings Graphs
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                    ............................. 9
                    </Typography>
                </Box>
                <Box ml={4}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 16 }} >
                        Specific guidance on low scoring factors (up to 4 recommendations)
                    </Typography>
                </Box>
                <Box className={classes.splitRow} mt={8}>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                        Closing General Guidance
                    </Typography>
                    <Typography  gutterBottom variant="body1" style={{ fontSize: 22 }} >
                    ............................. *
                    </Typography>
                </Box>
            </Box>

            {/* disclaimer */}
            <Box mt={10}>
                <Typography  gutterBottom variant="body1" style={{ fontSize: 16 }} >
                    <i>* Note that page count varies depending upon number of recommendations provided.</i>
                </Typography>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },

});

export default TocPage;
