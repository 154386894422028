import  { db, timestamp,  } from "../../services/firebase";

import {
    resetNotificaitons,
    adminReportRequest, adminReportSuccess, adminReporFailure,
    updateReportRequest, updateReportSuccess, updateReportFailure,
    accessCodesRequest, accessCodesSuccess, accessCodesFailure, adminHistoryRequest, adminHistoryFailure, adminHistorySuccess,
} from '../actions';


//get history
export const getAdminAssessmentHistory = () => async dispatch => {
    dispatch(adminHistoryRequest());

    try {
        //load all reports
        let query = db.collection("assessmentHistory");
        query.onSnapshot(querySnapshot => {
            let historyList = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    historyList.push(data)
                } else {
                    errMsg = 'Assessment History not found'
                    console.log("getAdminAssessmentHistory: ", 'Assessment History not found');
                    dispatch(adminHistoryFailure('Assessment History not found'));
                }
            });
            //dispatch reports 
            //console.log("dispatch change");
            dispatch(adminHistorySuccess(historyList));
        })

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(adminHistoryFailure(error.message));
    }
}

/*
 * READ
 */

//get reports
export const getAdminReports = () => async dispatch => {
    dispatch(adminReportRequest());

    try {
        //load all reports
        let query = db.collection("reportContent");
        query.onSnapshot(querySnapshot => {
            let reportList = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    reportList.push(data)
                } else {
                    errMsg = 'Report Type not found'
                    console.log("getAdminReports: ", 'Report Type not found');
                    dispatch(adminReporFailure('Report Type not found'));
                }
            });
            //dispatch reports 
            dispatch(adminReportSuccess(reportList));
        })

    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(adminReporFailure(error.message));
    }
}

//get access codes
export const getAccessCodes = () => dispatch => {
    
   dispatch(accessCodesRequest());

    try {
        const query = db.collection("purchaseHistory").orderBy('date', 'desc');
        query.onSnapshot(querySnapshot => {
            let codes = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    codes.push(data)
                } else {
                    errMsg = 'Access Codes not found'
                    console.log("getAccessCodes: ", 'Report Type not found');
                    dispatch(accessCodesFailure('Report Type not found'));
                }
            });
            //dispatch codes
            // console.log('codes: ', codes)
            dispatch(accessCodesSuccess(codes));
        })
    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(accessCodesFailure(error.message));
    }
}

/*
 * UPDATE
 */

export const updateAdminReports = (data, reportId) => async dispatch => {

    if (!reportId || !data) {
        return
    }
    
    dispatch(updateReportRequest());

    try {

        let query = db.collection('reportContent');
        query = query.where('reportId', '==', reportId);
        
        query.get().then(snapshot => {
            //get report
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //data[68].content = temp68
                    //temp update report
                    // let newData = data[42].content
                    // for (let i=0; i < tempArr.length; i++) {
                    //     newData.splice(i,0,tempArr[i])
                    // }
                    // data[70].content = temp70;
                    // console.log('data: ', data);
                    // return 
                    await doc.ref.update({
                        report: data
                    }).then(() => {
                        //data[42].content = tempData
                        // console.log('tempdata: ', tempData);

                        dispatch(getAdminReports())
                        dispatch(updateReportSuccess());
                    }).catch(error => {
                        // The document probably doesn't exist.tempData
                        console.log(error.message);
                        dispatch(updateReportFailure(error.message));
                    });
                } else {
                    console.log("Assessment not found");
                    dispatch(updateReportFailure("Assessment not found"));
                }
            });
        }).catch(error => {
            console.log(error.message);
            dispatch(updateReportFailure(error.message));
        });
    } catch(error) {
        console.log("Error: ", error.message);
        dispatch(updateReportFailure(error.message));
    }

    dispatch(_timeout(resetNotificaitons()));
}

export const updateAccessCode = (code, purchaser, owner, assessor, cost, previousOwner) => async dispatch => {
    if (!code ) {
        console.log('missing params updateAccessCode');
        return
    }

    // dispatch(updateReportRequest());

    try {

        let query = db.collection('purchaseHistory');
        query = query.where('accessCode', '==', code);
        
        query.get().then(snapshot => {
            //get report
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    let data = doc.data()
                    let updateData = {
                        purchaserEmail: purchaser ? purchaser : '',
                        cost: parseFloat(cost)
                    }
                    if(data.owner.email !== owner) {
                        updateData.owner = {
                            email: owner ? owner.toLowerCase() : '',
                            name: '',
                            isClaimed: false
                        }
                    }
                    if(data.assessor.email !== assessor) {
                        updateData.assessor = {
                            email: assessor ? assessor.toLowerCase() : '',
                            isClaimed: false,
                            name: '',
                        }
                    }

                    await doc.ref.update(updateData).then(() => {
                        //create ownership document
                        dispatch(_createOwnershipRecord(code, owner, 'owner', previousOwner));

                        //dispatch(getAdminReports())
                        //dispatch(updateReportSuccess());
                    }).catch(error => {
                        // The document probably doesn't exist.tempData
                        console.log(error.message);
                        //dispatch(updateReportFailure(error.message));
                    });
                } else {
                    console.log("Assessment not found");
                    //dispatch(updateReportFailure("Assessment not found"));
                }
            });
        }).catch(error => {
            console.log(error.message);
            //dispatch(updateReportFailure(error.message));
        });
    } catch(error) {
        console.log("Error: ", error.message);
        //dispatch(updateReportFailure(error.message));
    }

    //dispatch(_timeout(resetNotificaitons()));
}

/*
 * CREATE
 */

//creates record in purchaseHistory
export const createAccessCode =  (assesment, purchaser, owner, assessor, cost )  => async dispatch => {

    // *** TO DO  *** assessment is hardcoded
    //need to create a dropdown and pass dynamic options through
    //assessment param

    // if(!assesment){
    //     console.log('No type createPurchaseRecord');
    //     return;
    // }

    // if(!purchaser || !owner) {
    //     console.log('missing params createAccessCode');
    //     return;
    // }

    try {
        //create purchase record
        const document = await db.collection('purchaseHistory').add({
            accessCode: '',
            assessor: {
                email: assessor ? assessor.toLowerCase() : '',
                name: '',
                isClaimed: false
            },
            cost: parseFloat(cost),
            date: timestamp, 
            isDiscounted: false, 
            owner: {
                email: owner ? owner.toLowerCase() : '',
                name: '',
                isClaimed: false
            },
            paymentMethod: 'Credit Card', //todo: figure out other options
            purchaserEmail: purchaser ? purchaser : '',
            status: 'Available',
            storeId: assesment?.id ? assesment.id : 'vsba', 
            type: assesment?.type ? assesment.type : 'VIA·Biz',
            isExpired : false,
            isCompleted: false,
            //isClaimed: false,
        });

        //TODO: return error dispatch
        if (!document.id){
            console.log('No document.id returned');
            return; 
        }

        //get document and set accesscode
        db.collection('purchaseHistory').doc(document.id).update({accessCode: document.id});

        //create ownership document
        dispatch(_createOwnershipRecord(document.id, owner, 'purchaser'));

    } catch(error){
        console.log('error: ', error.message);
    }
}



/*
 * DELETE
 */

//delete purchaseHistory, ownershipHistory
export const deleteHistory = (code) => async dispatch => {
    if(!code) {
        console.log('deleteHistory missing access code')
        return      
    }

    try {
        const purchase = db.collection('purchaseHistory')//.where('accessCode', '==', code)
        const assessment = db.collection('assessmentHistory')//.where('accessCode', '==', code)
        const owner = db.collection('ownershipHistory')//.where('accessCode', '==', code)
        //purchase history
        await purchase.where('accessCode', '==', code).get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    purchase.doc(doc.id).delete()
                });
            }).catch((e) => {
                console.log(e.message)
                return
            })
        //assessment history
        await assessment.where('accessCode', '==', code).get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    assessment.doc(doc.id).delete()
                });
            }).catch((e) => {
                console.log(e.message)
                return
            })
        //ownership history
        await owner.where('accessCode', '==', code).get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    owner.doc(doc.id).delete()
                });
            }).catch((e) => {
                console.log(e.message)
                return
            })
    } catch(e) {
        console.log('deleteHistory error', e.message)
    }
}

/*
 * HELPER FUNCTIONS
 */



const _timeout = (action) => dispatch => {
    setTimeout(() => {
        dispatch(action);
    }, 10000);
}

//creates new ownershipHistory record
const _createOwnershipRecord = (accessCode, email, type, prevOwnerEmail) => async dispatch => {

    if(!accessCode){
        console.log('No accessCode _createOwnershipRecord');
        return;
    }
    if(!email){
        console.log('No email _createOwnershipRecord');
        return;
    }
    if(!type){
        console.log('No type _createOwnershipRecord');
        return;
    }
    if(!type){
        console.log('No previousOwner _createOwnershipRecord');
        return;
    }

    try {
        const ownershipHistory = db.collection('ownershipHistory');
        switch(type){
            case 'purchaser':
                //create first record
                await ownershipHistory.add({ 
                    accessCode,
                    dateTime: timestamp,
                    email,
                    count: 1, //if record exists else is new record
                    previousOwner: '',
                });
                break;
            case 'owner':
                //get previous and create next record;
                const query = ownershipHistory.where('accessCode', '==', accessCode).orderBy('count', 'desc').limit(1);

                query.get().then(async snapshot => {
                    var previousOwner = {};
                    previousOwner.count = 0;
                    //store previous owner of assessment
                    snapshot.forEach(doc => {
                        if (doc.exists) {
                            previousOwner = doc.data();
                        }
                    });

                    //create new record
                    await ownershipHistory.add({ 
                        accessCode,
                        dateTime: timestamp,
                        email,
                        count: previousOwner.count + 1, //if record exists else is new record
                        previousOwner: prevOwnerEmail,
                    }).then(() => {
                        //dispatch(updateOwnerSuccess());

                        //dispatch(_timeout(hideOwnerNotification()));
                    });

                }).catch(error => {
                    console.log('Error: ', error.message);
                });
                break;
            default:
                break;
        }

    } catch(error){
        console.log('Error: ', error.message);
    }
}

const temp68 = [
    {
        //1
        name: 'RecIntro0',
        content: ''
    },
    {
        //1
        name: 'RecIntro1',
        content: ''
    },
    {
        //1
        name: 'RecIntro2',
        content: ''
    },
    {
        //1
        name: 'RecIntro3',
        content: ''
    },
    {
        //1
        name: 'RecIntro4',
        content: ''
    },
    {
        //1
        name: 'RecIntro5',
        content: ''
    },
]

// const tempData = [
//     {
//         //1 - (1-3)
//         name: 'NRec01',
//         content: '',
//         content_2:''
//     },{
//         //2 - (4-6)
//         name: 'NRec02',
//         content: '',
//         content_2:''
//     },{
//         //3 - (7-9)
//         name: 'NRec03',
//         content: '',
//         content_2:''
//     },{
//         //4 - (10-12)
//         name: 'NRec04',
//         content: '',
//         content_2:''
//     },{
//         //5 - (13-15)
//         name: 'NRec05',
//         content: '',
//         content_2:''
//     },{
//         //6 - (16-18)
//         name: 'NRec06',
//         content: '',
//         content_2:''
//     },{
//         //7 - (19-21)
//         name: 'NRec07',
//         content: '',
//         content_2:''
//     },{
//         //8 - (22-24)
//         name: 'NRec08',
//         content: '',
//         content_2:''
//     },{
//         //9 - (25)
//         name: 'WRec01',
//         content: '',
//         content_2:''
//     },{
//         //10 - (26)
//         name: 'WRec02',
//         content: '',
//         content_2:''
//     },{
//         //11 - (27)
//         name: 'WRec03',
//         content: '',
//         content_2:''
//     },{
//         //12 - (28)
//         name: 'WRec04',
//         content: '',
//         content_2:''
//     },{
//         //13 - (29)
//         name: 'WRec05',
//         content: '',
//         content_2:''
//     },{
//         //14 - (30)
//         name: 'WRec06',
//         content: '',
//         content_2:''
//     },{
//         //15 - (31)
//         name: 'WRec07',
//         content: '',
//         content_2:''
//     },{
//         //16 - (32)
//         name: 'WRec08',
//         content: '',
//         content_2:''
//     },{
//         //17 - (33)
//         name: 'SRec01',
//         content: '',
//         content_2:''
//     },{
//         //18 - (34)
//         name: 'SRec02',
//         content: '',
//         content_2:''
//     },{
//         //19 - (35)
//         name: 'SRec03',
//         content: '',
//         content_2:''
//     },{
//         //20 - (36)
//         name: 'SRec04',
//         content: '',
//         content_2:''
//     },{
//         //21 - (37)
//         name: 'SRec05',
//         content: '',
//         content_2:''
//     },{
//         //22 - (38)
//         name: 'SRec06',
//         content: '',
//         content_2:''
//     },{
//         //23 - (39)
//         name: 'SRec07',
//         content: '',
//         content_2:''
//     },{
//         //24 - (40)
//         name: 'SRec08',
//         content: '',
//         content_2:''
//     },{
//         //25 - (41)
//         name: 'ERec01',
//         content: '',
//         content_2:''
//     },{
//         //26 - (42)
//         name: 'ERec02',
//         content: '',
//         content_2:''
//     },{
//         //27 - (43)
//         name: 'ERec03',
//         content: '',
//         content_2:''
//     },{
//         //28 - (44)
//         name: 'ERec04',
//         content: '',
//         content_2:''
//     },{
//         //29 - (45)
//         name: 'ERec05',
//         content: '',
//         content_2:''
//     },{
//         //30 - (46)
//         name: 'ERec06',
//         content: '',
//         content_2:''
//     },{
//         //31 - (47)
//         name: 'ERec07',
//         content: '',
//         content_2:''
//     },{
//         //32 - (48)
//         name: 'ERec08',
//         content: '',
//         content_2:''
//     }
// ]