import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Typography, List, ListItem, Table, TableContainer, TableBody, TableRow, TableCell } from '@material-ui/core';
import Footer from 'src/components/shared/Footer';

const Learn = () => {
  return (
    <>
      <Helmet>
        <title>Vector Reports | Learn</title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 8 }}>
        <Container maxWidth="md">
          <Card width={80}>
            <CardContent>
              <Box m={6}>
                <Typography color="textPrimary" gutterBottom variant="h2" >
                  Introduction
                </Typography>
                <Typography color="textPrimary" gutterBottom variant="h4" mt={3} mb={2}>
                  Growth
                </Typography>

                <Typography color="textSecondary" mb={2}>
                  The Very Small Business Assessment (VSBA) was created using a both a growth
                  approach and mindset. Every business process we ask you to evaluate is associated
                  with generally accepted good business practices and are associated with either
                  facilitating growth when done well, or hindering growth when the process is
                  vulnerable. How we ask you to assess and what we report back to you is different:
                </Typography>

                <Typography color="textSecondary" mb={2}>
                  Brief VSBA Introduction video:
                </Typography>

                <video width="100%" height="315" controls>
                  <source src="/static/videos/learn.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <Typography color="textSecondary" mt={5} mb={2}>
                  There is a saying in business, <i>“If you are not growing, you’re dying.”</i> We believe
                  growth only for the sake of growth is a fool’s errand that leads to greed instead of
                  service. Vector Reports&trade; has developed a business assessment designed to help
                  very small businesses test themselves (by themselves or with assistance of a
                  business consultant) against tried-and-true business practices and standards. What
                  is different is that those practices are assembled inside an integrated design of
                  interdependencies. Feedback emphasizes whole-system success of your business.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  To that end we see three main customers who may find good value in this virtual
                  consulting service.
                </Typography>

                <Typography color="textPrimary" gutterBottom variant="h5" mt={6} mb={2}>
                  1 - Very Small Business Clients
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  The <i>Small Business Administration (SBA)</i> defines a “small business” as one with less
                  than 500 employees. However, that size range includes companies possessing
                  significantly different capabilities from one another. For example, a company with
                  400 employees might have many individuals whose assigned responsibilities are to
                  identify and make change improvements. A company with less than 50 employees
                  will often have multiple roles assigned to each employee. This suggests small
                  companies could use help analyzing and prioritizing the often complex and
                  competing business processes calling for improvement. Very small businesses could
                  benefit from this method to rapidly obtain cost-effective advice for systemic
                  improvement and change for growth.
                </Typography>

                <Typography color="textSecondary" mb={2}>
                  The VSBA offers the very small company a do-it-yourself approach to gain access to
                  high-quality cost-effective business growth recommendations. Learn what you need
                  to ask for before you start paying a high-priced consultant or ask for mentoring
                  assistance. Imagine the time and cost savings this one-hour assessment might
                  provide you.
                </Typography>

                <Typography color="textPrimary" gutterBottom variant="h5" mt={6} mb={2}>
                  2 - Consultants, Coaches and Mentors
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Consultants and Mentors with business savvy, growth expertise, knowledge and experience in specific content areas can provide additional cost-effective value-
                  adding service to their clients when using VSBA. Mentors and Consultants recognize
                  and appreciate competitive advantages that make a difference and the VSBA can
                  complement your existing services. Your service with clients can be augmented by
                  utilizing and leveraging the VSBA process that delivers outstanding efficiencies to
                  your bottom line and impressive insight into your client.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Often simply conducting a VSBA with a client can become a “door opener” for your
                  services simply by creating awareness in a client’s perception of themselves. In
                  little more than an hour you’ll use a focused, standardized diagnostic tool with your
                  client to point to those vulnerabilities, set the stage for change, and identify the
                  prioritized critical areas to assign to the right expert.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Imagine the savings in time and dollars that this process could facilitate—that’s the
                  value VSBA can provide to you.
                </Typography>

                <Typography color="textPrimary" gutterBottom variant="h5" mt={6} mb={2}>
                  3 - Agencies, Accelerators and Incubators
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Many governmental and non-governmental organizations work to create and build
                  both the spirit of, and application of, entrepreneurialism in individuals, new
                  startups, business, industry clusters and communities. Yet, we often see that there
                  are few benchmarks for agencies to use that measure the effectiveness of funding,
                  mentoring, training, and business development support provided to the
                  participating companies in the development program that details what, and how
                  well, changes are achieved.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Vector Reports&trade; now provides that yardstick that not only measures, but teaches,
                  a standardized set of business processes along the way. VSBA is not a panacea, but
                  it does deliver business growth recommendations as a method to target consulting
                  and mentoring expertise that likely already resides in your program, organization,
                  or agency.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Imagine that in little more than an hour for each company you serve, you could
                  compile the results of all of them to produce information revealing common
                  business process issues / challenges. This can be used to focus, often limited
                  resources, on targeted training, mentoring and guidance in aggregated sessions far
                  more efficiently and effectively than the traditional shotgun offerings.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Repeating assessments over time facilitate reports being used as a measure of
                  progress of all the participants in your programs’ efforts to grow and mature
                  businesses—all based in generally accepted good business practices—that also
                  provides valuable feedback on your agency’s projects, efforts and effectiveness.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Imagine stretching your organizational dollars and time to reach more companies,
                  in less time, with deeper insights and a path to progress. That’s VSBA.
                </Typography>

                <Typography color="textPrimary" gutterBottom variant="h5" mt={6} mb={2}>
                  Our Ask
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  We believe you will like what you find … and see a rapid, cost-effective, and
                  different way to build improvement.
                </Typography>
                <Typography color="textSecondary" mb={2}>
                  Not Sure if this is For You?
                </Typography>
                <Typography color="textSecondary" mb={10}>
                  Please write us with your questions: <a href="mailto: info@vectorreports.com">info@vectorreports.com</a>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default Learn;