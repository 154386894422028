import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import moment from 'moment';
//custom
import Footer from 'src/components/reports/Footer';

const FinalAdvicePage = (props) => {

    const {

    } = props;

    const classes = useStyles();

    return (
        <>



            {/* ending note */}
            <Box mt={35}>
                <Typography variant="h4">
                    <i>Important Disclaimer Note</i>
                </Typography>
                <Typography variant="body1" style={{ fontSize: 20 }} mt={4}>
                    <i>The results of this assessment are based upon your assessor’s individual knowledge of the business or
                        organization being evaluated. As such, supplying inaccurate rating information can and will likely
                        produce inaccurate results. However, all the recommendations are based in solid practices and the
                        effect of an inaccurate rating would be to work on solid practices in a less efficient sequence.</i>
                </Typography>
                <Typography variant="body1" style={{ fontSize: 20 }} mt={4}>
                    <i>This assessment is designed to highlight missing or inadequate processes that build to the four critical
                        capabilities. Failure to mature these capabilities in a timely manner could hinder future success. Please
                        refer to the full set of disclaimers on our website.</i>
                </Typography>
            </Box>

            {/* logo */}
            <Box mt={55} className={classes.centeredColumn} >
                <img width="350px" alt="Logo" src="/static/logo.png" />
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
})

export default FinalAdvicePage;

