
import { TableRow, TableCell, Box, CircularProgress, Typography  } from "@material-ui/core";
import PropTypes from 'prop-types';

//shows load symbol or empty data display for a table
const TableSpinner = (props) => {

    const { isLoading, colSpan } = props;

    if (isLoading) {
        return (
            <TableRow >
                <TableCell colSpan={colSpan}>
                    <Box sx={{ display: 'flex' , p:10, alignItems: 'center', justifyContent:'center'}}>
                        <CircularProgress />
                    </Box>
                </TableCell>
            </TableRow>
        );
    } else {
        return (
            <TableRow >
                <TableCell colSpan={colSpan}>
                    <Typography  color="textSecondary" sx={{ display: 'flex' , p:10, alignItems: 'center', justifyContent:'center'}}>
                        There are no assessments to view at this time
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }
}

TableSpinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    colSpan: PropTypes.number.isRequired 
}

export default TableSpinner;
