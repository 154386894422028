import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Container, Box, Button, TextField, Typography, Card, CardContent, Link } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import {
    Eye as EyeIcon,
    EyeOff as EyeOffIcon
} from 'react-feather';

import { loginUser } from 'src/redux/thunks';
import { setPassword, verifyCodeReset, verifyEmailReset } from 'src/redux/actions';

const RegistrationLogin = () => {

    const email = useSelector(state => state.auth.email)
    const isLoggingIn = useSelector(state => state.auth.isLoggingIn)
    const didLoginFail = useSelector(state => state.auth.didLoginFail)
    const loginErrorMessage = useSelector(state => state.auth.loginErrorMessage)
    const accessCode = useSelector(state => state.auth.accessCode)
    const errorCount = useSelector(state => state.auth.errorCount)
    const [showPassword, setShowPassword] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(errorCount >= 3) {
            goBackToHome()
        }
    }, [errorCount])

    const goBackToHome = () => {
        dispatch(verifyCodeReset())
        dispatch(verifyEmailReset())
        navigate('/', { replace: true })
    }

    return (
        <Container maxWidth="sm">
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        email: Yup.string().max(60), //Yup.string().email('Must be a valid email').max(60).required('Email is required'),
                        password: Yup.string().min(8).max(60).required('Password is required'),
                    })
                }
                onSubmit={(data) => {
                    //console.log(data.email)
                    data.email = email;
                    dispatch(loginUser(data, accessCode.accessCode))
                    dispatch(setPassword(data.password))
                    //verify email (either logs user in or shows register fields)
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>

                        <Card >
                            <CardContent >
                                <Box ml={-2} mt={-1}>
                                    <Button
                                        color="primary"
                                        onClick={goBackToHome}>
                                        Back
                                    </Button>
                                </Box>
                                {/* Returning User*/}
                                <Box sx={{ my: 1 }}>
                                    <Typography color="textPrimary" variant="h2" >
                                        Returning User
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2" >
                                        Enter your password
                                    </Typography>
                                </Box>

                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={email}
                                    variant="outlined"
                                    autoComplete="username"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    variant="outlined"
                                    autoComplete="password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />


                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        disabled={isLoggingIn}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Login
                                    </Button>

                                    {didLoginFail && (
                                        <Typography color="#f44335" pt={2} fontSize={15} >
                                            {loginErrorMessage}
                                        </Typography>
                                    )}
                                </Box>
                                <Box >
                                    <Typography color="textSecondary" variant="body1" >
                                        <Link component={RouterLink} to="/reset" variant="h5">
                                            <b>Reset Password</b>
                                        </Link>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        </Container>
    )
};


export default RegistrationLogin
