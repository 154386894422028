import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Typography, Card, CardContent, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import CircleIcon from '@material-ui/icons/Circle'
import Footer from 'src/components/shared/Footer';

const AssessmentDetails = (props) => {

    window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
    });
    const {

    } = props

    const classes = useStyles();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/app/take', { replace: true })
    }

    const handleNext = () => {
        navigate('/app/information', { replace: true })
    }
    useEffect(() => {
        document.getElementById('details-top').scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    return (
        <>
            <Helmet>
                <title>Vector Reports | Information</title>
            </Helmet>
            <Box sx={{ my: 8 }} id='details-top'> {/* Header */}
                {/* <Container maxWidth='md' >
                    <Box mb={4}>
                        <Typography color="textPrimary" variant="h2" >
                           Instructions
                        </Typography>
                        <Typography color="textSecondary"  gutterBottom  variant="body2" >
                            Enter Company or Organization data below
                        </Typography>
                    </Box>
                 </Container> */}
                <Container maxWidth="md" mt={2}>
                    <Card>
                        <CardContent>
                            <Box px={8} mt={4}>
                                <Box sx={{ display: 'flex' }} mb={4}>
                                    <Box>
                                        <img width="100px" alt="Logo" src="/static/images/best-practices.png" />
                                    </Box>
                                    <Box ml={8}>
                                        <Typography color="textPrimary" variant="h1">Instructions</Typography>
                                        <Typography color="textPrimary" gutterBottom variant="body1" fontSize={20}>
                                            Best practices to get the most out of your assessment
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box mt={4}>


                                    {/* Section */}
                                    <Typography color="textPrimary" gutterBottom variant="h4" pt={2} pb={2}>
                                        Assemble Your Thoughts or Your Assessor Team
                                    </Typography>
                                    <Typography color="textPrimary" pb={2}>
                                        Gather the people in your organization who are in-the-know about process status ratings—even if it is
                                        only you. If you determine that you do not have the right people “in the room” to accurately rate each
                                        process, then click the [<b>Quit</b>] button to close the assessment and go assemble your Assessor team. You
                                        may start the assessment again from the beginning. If you are the only one assessing the organization, it
                                        may require only about 40 minutes. Assessor teams could take up to a couple of hours for team
                                        members to reach consensus and a shared understanding of their organization’s status.
                                    </Typography>

                                    {/* Section */}
                                    <Typography color="textPrimary" gutterBottom variant="h4" pt={2} pb={2}>
                                        Set Assessment Boundaries
                                    </Typography>
                                    <Typography color="textPrimary" pb={2}>
                                        Assessor(s) should clarify what is and what is not being evaluated (Examples: Entire business or name
                                        of portion of business such as Department, Team, Alliance, Project, etc.) Select a name for the
                                        “project” being assessed—it will appear alongside the organization name as an identifier on the cover
                                        of the Report.
                                    </Typography>

                                    {/* Section */}
                                    <Typography color="textPrimary" gutterBottom variant="h4" pt={2} pb={2}>
                                        Take Notes
                                    </Typography>
                                    <Typography color="textPrimary" pb={2}>
                                        As you rate the level of each process, the assessment provides a place to enter notes to help remind you
                                        of the existing or missing basis for your ratings when you receive your Report. If you use a consultant or
                                        mentor, the Report plus this additional information, will help get them up to speed quickly and more
                                        cost effectively. And, this additional information will also be helpful if you are preparing for an audit.
                                    </Typography>

                                    {/* Section */}
                                    <Typography color="textPrimary" gutterBottom variant="h4" pt={2} pb={2}>
                                        Rate Your Processes
                                    </Typography>
                                    <Typography color="textPrimary" pb={2}>
                                        Review the <i>Development Scale</i> below and understand its implications to guide your ratings of how well
                                        your organization currently achieves the <i>Statement Description</i> within each process Category.
                                    </Typography>
                                    <Typography color="textPrimary" pb={2}>
                                        While you may initially think your organization has achieved a Level 2, if it has issues meeting or
                                        demonstrating any aspect of Level 2, <b>you must rate it lower,</b> in this case at Level 1. In other words, your
                                        organization <i>must demonstrate <u>all</u> of the positive accomplishments of <u>all</u> the lower Levels before you can
                                            select a higher Level</i> as your rating.
                                    </Typography>

                                    {/* Section */}
                                    <img width="100%" style={{ marginLeft: -7 }} alt="Logo" src="/static/images/vsba/org-dev-scale.png" />
                                    <Typography color="textPrimary" pb={2} pt={2}>
                                        Assessments are made up of four <u>Process Categories</u>. And inside each category there are
                                        several <u>Processes</u> to be rated. Each Process has a label, a statement of the aspiration condition, and
                                        four <u>Maturity Level Descriptions</u> of evidence of what you might see if the organization is operating at
                                        that maturity level. Read all four Maturity Level Descriptions. <b>Notice that they build up from bottom to
                                            top</b>. Rate the Level that best represents your organization's current maturity Level for each process. You
                                        may either slide the selector or type the Level number in the box.
                                    </Typography>

                                    {/* Section */}
                                    <Typography color="textPrimary" gutterBottom variant="h4" pt={2} pb={2}>
                                        Take your time.
                                    </Typography>

                                    <Typography color="textPrimary" pb={2} >
                                        Take all the time you need to move through the assessment. Use the [<b>Back</b>] and [<b>Next</b>] buttons to move
                                        through the Processes sequentially or use the left-side navigation menu to go directly to a desired
                                        Category and Process.
                                    </Typography>

                                    <List disablePadding={true}>
                                        <ListItem>
                                            <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                                            <Typography color="textPrimary">
                                                <b>Rate all 32 processes:</b> You will not be allowed to [<b>Submit</b>] your ratings until all processes have been
                                                rated.
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography color="textPrimary" pl={3}>
                                                If you need to [<b>Quit</b>] prior to completion, YOU WILL LOSE any ratings or notes collected to that
                                                point—and YOU WILL HAVE TO START OVER again. We do this because status is fleeting and it is
                                                important to have all ratings based in the same time frame.
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                                            <Typography color="textPrimary">
                                                <b>Submit your Assessment:</b> After making all your selections, click the [<b>Submit</b>] Button to send your
                                                assessment to be analyzed. At this point the assessment is complete.
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                                            <Typography color="textPrimary">
                                                <b>View, Print or Download your Report:</b> Click the [<b>View Reports</b>] button on your My Panel page to
                                                display a list of all your completed assessments. To see the customized Report for the assessment
                                                you want, click the [View] button. Buttons on the top of the screen allow you to Download three
                                                versions of your Reports (Main, Executive and Notes.)
                                            </Typography>
                                        </ListItem>
                                    </List>

                                    <Typography color="textPrimary" pb={2} pt={2}>
                                        We hope you discover something new, or have something you already know reinforced, by taking the assessment.  Best wishes to you for a prosperous future.
                                    </Typography>
                                </Box>
                            </Box>
                            {/*  back / next buttons */}
                            <Box px={8} mt={10} className={classes.buttonContainer}>
                                <Button variant="contained" color="error" sx={{ width: 100 }}
                                    onClick={() => handleBack()}>
                                    Back
                                </Button>
                                <Box className={classes.buttonsRight} >
                                    <Button variant="contained" sx={{ width: 100, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                        onClick={() => handleNext()}>
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '77%',
        height: '100%',
        paddingBottom: 50,
        width: '100%'
    },
    buttonsLeft: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonsRight: {
        display: 'flex',
        flexDirection: 'row',

    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentDetails)