/*
 * provides store to app
 */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// import { PersistGate } from 'redux-persist/integration/react'

import App from './App';
import configureStore from './redux/configureStore';

const store = configureStore().store;
// const persistor = configureStore().persistor;

function Root() {
    return (
      <Provider store={store}> 
            {/* <PersistGate loading={ 'Loading...' } persistor={persistor}>  */}
                <Router>
                    <App />
                </Router>
            {/* </PersistGate> */}
      </Provider>
    );
}

export default Root;
