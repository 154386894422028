

import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
//ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//custom


const PageHeader = () => {

    const assessorData = useSelector(state => state.takingAssessment.assessorData)
    const dateAssessed = useSelector(state => state.takingAssessment.dateAssessed)

    //variables
    const classes = useStyles()

    return (
        <Box>
            <Box className={classes.centeredColumn} mb={2}>
                <img height={17} alt="Logo" src="/static/images/VIA-logo.png" />
                <Typography style={{marginTop: 4, marginLeft: 2}} variant="body1" color="textSecondary">
                    Assessment
                </Typography>
            </Box>
            <Box className={classes.splitRow}>
                <Box className={classes.textBox} mt={2}>
                    <Box className={classes.categories}>
                        <Typography sx={{ fontStyle: 'italic' }} variant="body1" color="textSecondary">Company: </Typography>
                        <Typography sx={{ fontStyle: 'italic' }} variant="body1" color="textSecondary">Project: </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" color="textSecondary" ml={1}>
                            {assessorData.company.name ? assessorData.company.name : ''}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" ml={1}>
                            {assessorData.company.projectName ? assessorData.company.projectName : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.textBox} mt={2}>
                    <Box className={classes.categories}>
                        <Typography sx={{ fontStyle: 'italic' }} variant="body1" color="textSecondary">  Assessor: </Typography>
                        <Typography sx={{ fontStyle: 'italic' }} variant="body1" color="textSecondary">Date assessed: </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" color="textSecondary" ml={1}>
                            {assessorData.assessor.name ? assessorData.assessor.name : ''}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" ml={1}>
                            {moment.unix(dateAssessed.seconds).format("DD MMMM YYYY")}
                        </Typography>
                    </Box>
                </Box>
            </Box>


        </Box>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'flext-start',
        alignItems: 'flex-start',
        width: '85%',
        margin: 'auto'
    },
    textBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    categories: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end'
    }

})

export default PageHeader
