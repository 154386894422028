import React, { useState, useEffect } from 'react'
import PropTypes, { element } from 'prop-types'

import { 
    Box, Tabs, Tab, Divider,
} from '@material-ui/core';

const TabBar = (props) => {

    const {
        tabsArr,
        handleTabChange
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        handleTabChange(tabsArr[newValue])
    };

    return(
        <Box >
            <Tabs 
            onChange={handleChange} 
            value={tabValue}
            variant="scrollable" 
            scrollButtons={true}
            allowScrollButtonsMobile={true}
            aria-label="scrollable-tabs"
            wrapped="true"
            >
                {Array.isArray(tabsArr) && (
                    tabsArr.map((element, index) => {
                        return (
                            <Tab key={index} label={element.name }/>
                        )
                    })
                )}
            </Tabs>
            <Divider />
        </Box>
    )
}

TabBar.propTypes = {
    tabsArr: PropTypes.array.isRequired,  
    handleTabChange: PropTypes.func.isRequired
}

export default TabBar;