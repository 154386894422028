import React, { useState, useEffect } from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import moment from 'moment';
//custom
import FormatList from 'src/components/reports/FormatList'
import PageHeader from './PageHeader';

const ProcessAdvicePage = (props) => {

    const {
        isFirstReport,
        isFirstProcess,
        isFirstPage,
        process,
        categoryName,
        didTestOut,
        didFullTestOut,
        assessorData,
        testoutMsg,
        content,
        referralMsg,
        scoreIntro
    } = props;

    const classes = useStyles();
    const companyName = assessorData.company.name ? assessorData.company.name : ''
    //dynamic data for advice
    const InfluentialCategory = 'Give this Category priority over others.'
    const influentialProcess = {
        mostInfMsg: 'Most influential of the lower-scoring Processes for this Category',
        secondInfMsg: '2nd Most influential of the lower-scoring Processes for this Category',
        testOutInfMsg: '',
    }
    //index is equvalent to process selection/score
    // const scoreIntro = [
    //     [
    //         'You reported a Level 0 rating for this process.  This means you are not performing the work called for in this process, or are just initiating efforts to create a process. Perhaps you are being surprised by unexpected (and negative) discoveries using these inadequate processes. You have a lot of important work ahead of you.',
    //         'To get to the next level up, Level 1, you have to build this necessary business process.  It is a shift from reactive activities to doing more of this needed process to reduce surprises and prevent crises. The recommendation below will help you learn about this process\' characteristics. Improve your process maturity by learning the details and implementing the tools identified below.',
    //     ],
    //     [
    //         'You reported a Level 1 rating for this process.  This means you are performing only some of the work called for in this process.  That means you may be suffering from immature, unreliable or uncontrolled results, or the process is labor intensive (which almost always introduces unintended errors).  Improve your process maturity by learning the details and implementing the tools identified below.',
    //         'The next level up, Level 2, is about developing all of the the process potential performance abilities. It is a shift from some partial ability to having a fully characterized process being brought to bear by adding what is missing.'
    //     ],
    //     [
    //         'You reported a Level 2 rating for this process. This means you have a process and are doing the work called for in this process, but you may not be able to completely depend upon it to produce reliable results.  The process may not have been integrated in a way that combines with others to produce a standard that can be controlled and measured to show reliable results.',
    //         'The next level up, Level 3, is all about implementing broad standards and ensuring systemic performance.  It is a shift from stand alone and somewhat inconsistent performance to a process that can be relied upon to perform as designed.'
    //     ],
    //     [
    //         'You are receiving this special Recommendation because only one (or none) of the processes in this Category are vulnerable. Essentially, you reported mature assessment ratings across the board for this Category.  We know that it can be an arduous journey to reach this maturity level that can deliver standardized, reliable, predictable and measurable process performance to your customers and others. That deserves a … Congratulations! ',
    //         'Your ratings for this Category of processes have exceeded the design limits of the Very Small Business Assessment and no standard recommendations are possible. However, Vector Reports can help you start preparing to engage what could be next in your improvement journey.'
    //     ]
    // ]

    const testOutTitle = 'How to use strengths from this Category.'

    //first advice page
    const PageOneContent = () => {
        // const scoreIndex 
        return (
            <>
                {/* full test out does not get category/process specific info */}
                {!didFullTestOut && (
                    <>
                        {/* most important category text */}
                        {isFirstReport && (
                            <Box mt={2} mb={-2}>
                                <Typography variant="h4" sx={{color: 'red'}}>
                                    {InfluentialCategory}
                                </Typography>
                            </Box>
                        )}

                        {/* influential of lower scoring process text -- bottom border if testout is empty */}
                        <Box pb={2}
                            sx={{
                                borderBottom: ((didTestOut || didFullTestOut) && influentialProcess.testOutInfMsg == '')
                                    ? ''
                                    : '2.5px solid black'
                            }}>
                            <Typography color='textSecondary' variant="h5" mt={2}>
                                {(didTestOut || didFullTestOut)
                                    ? influentialProcess.testOutInfMsg
                                    : isFirstProcess
                                        ? influentialProcess.mostInfMsg
                                        : influentialProcess.secondInfMsg
                                }

                            </Typography>
                        </Box>

                        {/* suggested addvice for: process in scope */}
                        <Box mt={4}>
                            <Typography variant="body2" style={{ fontSize: 19 }}>
                                <i>Suggested advice for</i>
                            </Typography>
                            <Box py={4} className={classes.processName}>
                                <Typography variant="body1" style={{ fontSize: 19 }}>
                                    <i>Process Name: </i>
                                </Typography>
                                <Typography variant="h4" pl={8}>
                                    {didTestOut
                                        ? testOutTitle
                                        : process.processName
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}


                {/* score intro msg - selection is equivalent to index */}
                <Box mt={4}>
                    < div className="pa-content" dangerouslySetInnerHTML={{ __html: scoreIntro }}></div>
                    {/* {process 
                        ? scoreIntro[process.selection].map((element, index) => {
                            return (
                            <Box mt={4} key={`adviceScore-${index}`}>   
                                <Typography variant="body2" style={{fontSize: 18}}>
                                    {element}
                                </Typography>
                            </Box>
                        )}) : <></>
                    }  */}
                </Box>

                {/* process advice */}
                <Box mt={4}>
                    < div className="pa-content" dangerouslySetInnerHTML={{ __html: content }}></div>
                </Box>
            </>
        )
    }

    //seconc advice page
    const PageTwoContent = () => {
        return (
            <Box mt={4}>
                {/* process advice */}
                {/* <ProcessAdvice /> */}
                <div className="pa-content2" dangerouslySetInnerHTML={{ __html: content }}></div>
                {/* referral  msg */}
                <Box mt={12}>
                    < div className="pa-content" dangerouslySetInnerHTML={{ __html: referralMsg }}></div>
                </Box>
            </Box>
        )
    }

    const ProcessAdvice = () => {
        const adviceArr = (didTestOut || didFullTestOut) ? testoutMsg : process.advice;
        const breakIndex = didTestOut ? 3 : (didFullTestOut ? 4 : process.advicePageBreakIndex);

        return (
            Array.isArray(adviceArr) && (
                //loop through selected quad messagae and output text
                adviceArr.map((element, index) => {
                    //display first or second page based on break index (page 5/6 main report)
                    if (isFirstPage && index <= breakIndex || !isFirstPage && index > breakIndex) {
                        try {
                            return (
                                <Box mt={4} key={index}>
                                    {typeof element == 'object'
                                        ? <FormatList list={element} />
                                        : <Typography variant="body1" style={{ fontSize: 21 }} >{element}</Typography>
                                    }
                                </Box>
                            )
                        } catch (e) {
                            console.log('QuadMessage return: ', e.message)
                        }
                    }
                })
            )

        )
    }
    return (
        <>
            {/* header */}
            <PageHeader />
            <Box className={classes.splitRow} mt={4}>
                <Box className={classes.centeredColumn}>
                    <Typography gutterBottom variant="h2">
                        {didFullTestOut
                            ? "Issues to address & improve"
                            : `Issues to address & improve: ${categoryName.toUpperCase()} `
                        }

                    </Typography>
                </Box>
            </Box>

            {/* banner */}
            <Box className={classes.whiteBanner} mt={2} py={2}>
                <Typography variant="body1">
                    Based upon our analysis of the integrations and interdependencies of all your process ratings, the actions below
                    represent Vector Reports&#39; best recommendation for further improvements.
                </Typography>
            </Box>

            {/* get page one or two view */}
            {isFirstPage
                ? <PageOneContent />
                : <PageTwoContent />
            }
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    whiteBanner: {
        borderBottom: '3px solid black',
        borderTop: '3px solid black',
    },
    processName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#B8CCE4',
        width: '100%'
    },

})

ProcessAdvicePage.propTypes = {
    isFirstReport: PropTypes.bool.isRequired,
    isFirstProcess: PropTypes.bool.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    process: PropTypes.object,
    categoryName: PropTypes.string.isRequired,
    didTestOut: PropTypes.bool, //one process test out
    didFullTestOut: PropTypes.bool, //every process test out
    testoutMsg: PropTypes.array,
    content: PropTypes.string,
    referralMsg: PropTypes.string,
    scoreIntro: PropTypes.string,
}

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,

})

export default connect(mapStateToProps)(ProcessAdvicePage);

