import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider, Modal, Button, Typography } from '@material-ui/core'

import { logoutUser } from 'src/redux/thunks';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.default',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 20,
    pt: 4,
    px: 4,
    pb: 4,
    margin: 'auto',
    textAlign: 'center'
};

const NoAssetsModal = ({ show }) => {

    const dispatch = useDispatch()

    const isLoggingOut = useSelector(state => state.auth.isLoggingOut)

    const handleLogout = () => {
        dispatch(logoutUser())
    }

    return (
        <Modal
            open={show}
            onClose={null}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="body1" >
                    Thank you for being a Registered client. At this time there are no active or untaken access codes
                    associated with your account, and/or all previously taken assessments are older than the two-year
                    record retention limit. We encourage you to obtain another assessment (purchase [Buy Now], request
                    assignment as Assessor, or request transfer as Owner) to facilitate improvement journeys.
                </Typography>
                <Button
                    color="primary"
                    disabled={false}
                    size="large"
                    variant="contained"
                    sx={{ mt: 4, mb: 3, width: 200 }}
                >
                    Buy New
                </Button>
                <Divider>Or</Divider>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleLogout}
                    disabled={isLoggingOut}
                    sx={{ mt: 4, mb: 2 }}
                >
                    Sign Out
                </Button>

            </Box>
        </Modal>
    )
}

export default NoAssetsModal