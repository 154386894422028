
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types'

const BarChart = (props) => {

    const {
        labels,
        data,
        backgroundColors,
    } = props;

    return (
        <>
            <Bar
                datasetIdKey='product-id'
                data={{
                    labels: labels,
                    datasets: [{
                        backgroundColor: backgroundColors,
                        data: data,
                        legend: {
                            onClick: null
                        },
                    }]
                }}
                options= {{
                    legend: {
                        onClick: (e) => e.stopPropagation(),
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                max: 3.5,
                                fontSize: 25
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontSize: 18
                            }
                        }]
                    }
                }}
            />
        </>
    )
}

BarChart.propTypes = {
    header: PropTypes.string,
    labels: PropTypes.array,
    data: PropTypes.array,
    backgroundColors:  PropTypes.array,
}

export default BarChart;
