import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { createSession } from './lib'

const BuyButton = () => {

    const [stripeError, setStripeError] = useState(null)
    const [isLoading, setLoading] = useState(false)

    if (stripeError) console.log("stripeError: ", stripeError)

    return (

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Formik
                initialValues={{ code: '' }}
                validationSchema={Yup.object().shape({ code: Yup.string().max(26).min(16) })}

                onSubmit={async event => {
                    setLoading(true)
                    let session = await createSession()
                    if (session.data.url) {
                        window.location.href = session.data.url
                    } else {
                        setStripeError(session)
                    }
                    setLoading(false)

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ py: 2 }}>
                            <LoadingButton
                                color="primary"
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                                sx={{ width: 200 }}
                            >
                                <span>Proceed</span>
                            </LoadingButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default BuyButton