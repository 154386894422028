import React from 'react';
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core';

const CustomButton = (props) => {

    const { 
        buttonTitle,
        handleClick,
        backgroundColor,
        hoverColor, 
        disabled,
        minWidth = 120,
    } = props;

    return (
        <Button 
            variant="contained"
            disabled={disabled}
            sx={{ backgroundColor: backgroundColor, '&:hover': { backgroundColor: hoverColor}, minWidth: minWidth}}
            onClick={() => handleClick()}
        > 
            {buttonTitle}
        </Button>
    )
}

CustomButton.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
    hoverColor: PropTypes.string,
    disabled: PropTypes.bool,
}
export default CustomButton;