import { createTheme, colors } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';
import Calibri from './fonts/Calibri.woff';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: '#fff',
      zIndex: -1,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#4166f5' //old 5664d2
    },
    text: {
      primary: '#191919',
      secondary: '#6b778c',
      fontFamily: Calibri 
    }
  },
  shadows,
  typography,
  button: {
    textTransform: "none"
  }
});

export default theme;
