//logout action
export const CLEAR_ASSESSMENT_DATA = 'CLEAR_ASSESSMENT_DATA';
export const assessmentLogout = () => {
    return {
        type: CLEAR_ASSESSMENT_DATA
    };
}

export const LOADING_ALL_DATA = "LOADING_ALL_DATA"
export const setLoadingAllData = (loading) => {
    return {
        type: LOADING_ALL_DATA,
        loading
    }
}

//assessment history
export const GET_ASSESSMENT_HISTORY_REQUEST = 'GET_ASSESSMENT_HISTORY_REQUEST';
export const assessmentHistoryRequest = () => {
    return {
        type: GET_ASSESSMENT_HISTORY_REQUEST,
    };
}
export const GET_ASSESSMENT_HISTORY_SUCCESS = 'GET_ASSESSMENT_HISTORY_SUCCESS';
export const assessmentHistorySuccess = history => {
    return {
        type: GET_ASSESSMENT_HISTORY_SUCCESS,
        history
    };
}
export const GET_ASSESSMENT_HISTORY_FAILURE = 'GET_ASSESSMENT_HISTORY_FAILURE';
export const assessmentHistoryFailure = () => {
    return {
        type: GET_ASSESSMENT_HISTORY_FAILURE
    };
}


//get purchase history
export const GET_PURCHASE_HISTORY_REQUEST = 'GET_PURCHASE_HISTORY_REQUEST';
export const purchaseHistoryRequest = () => {
    return {
        type: GET_PURCHASE_HISTORY_REQUEST,
    };
}
export const GET_PURCHASE_HISTORY_SUCCESS = 'GET_PURCHASE_HISTORY_SUCCESS';
export const purchaseHistorySuccess = history => {
    return {
        type: GET_PURCHASE_HISTORY_SUCCESS,
        history
    };
}
export const GET_PURCHASE_HISTORY_FAILURE = 'GET_PURCHASE_HISTORY_FAILURE';
export const purchaseHistoryFailure = () => {
    return {
        type: GET_PURCHASE_HISTORY_FAILURE,
    };
}
//get ownership data
export const GET_OWNERSHIP_REQUEST = 'GET_OWNERSHIP_REQUEST';
export const ownershipDataRequest = () => {
    return {
        type: GET_OWNERSHIP_REQUEST,
    };
}
export const GET_OWNERSHIP_SUCCESS = 'GET_OWNERSHIP_SUCCESS';
export const ownershipDataSuccess = data => {
    return {
        type: GET_OWNERSHIP_SUCCESS,
        data
    };
}
export const GET_OWNERSHIP_FAILURE = 'GET_OWNERSHIP_FAILURE';
export const ownershipDataFailure = () => {
    return {
        type: GET_OWNERSHIP_FAILURE,
    };
}
//get available assessments 
export const GET_ASSIGNED_REQUEST = 'GET_ASSIGNED_REQUEST';
export const assignedRequest = () => {
    return {
        type: GET_ASSIGNED_REQUEST,
    }
}
export const GET_ASSIGNED_SUCCESS = 'GET_ASSIGNED_SUCCESS';
export const assignedSuccess = data => {
    return {
        type: GET_ASSIGNED_SUCCESS,
        data
    }
}
export const GET_ASSIGNED_FAILURE = 'GET_ASSIGNED_FAILURE';
export const assignedFailure = () => {
    return {
        type: GET_ASSIGNED_FAILURE,
    }
}
//get store items
export const GET_STORE_REQUEST= 'GET_STORE_REQUEST';
export const storeItemsRequest = () => {
    return {
        type: GET_STORE_REQUEST,
    }
}
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const storeItemsSuccess = data => {
    return {
        type: GET_STORE_SUCCESS,
        data
    }
}
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';
export const storeItemsFailure = () => {
    return {
        type: GET_STORE_SUCCESS,
    }
}

//update assessor
export const UPDATE_ASSESSOR_REQUEST = "UPDATE_ASSESSOR_REQUEST";
export const updateAssessorRequest = () => {
    return {
        type: UPDATE_ASSESSOR_REQUEST
    }
};
export const UPDATE_ASSESSOR_SUCCESS = "UPDATE_ASSESSOR_SUCCESS";
export const updateAssessorSuccess = () => {
    return {
        type: UPDATE_ASSESSOR_SUCCESS,
    }
}
export const UPDATE_ASSESSOR_FAILURE = "UPDATE_ASSESSOR_FAILURE";
export const updateAssessorError = message => {
    return {
        type: UPDATE_ASSESSOR_FAILURE,
        message
    }
}
export const RESET_ASSESSOR_NOTIFICATION = "RESET_ASSESSOR_NOTIFICATION";
export const hideAssessorNotification = () => {
    return {
        type: RESET_ASSESSOR_NOTIFICATION
    }
}


//update owner
export const UPDATE_OWNER_REQUEST = "UPDATE_OWNER_REQUEST";
export const updateOwnerRequest = () => {
    return {
        type: UPDATE_OWNER_REQUEST
    }
};
export const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS";
export const updateOwnerSuccess = () => {
    return {
        type: UPDATE_OWNER_SUCCESS,
    }
}
export const UPDATE_OWNER_FAILURE = "UPDATE_OWNER_FAILURE";
export const updateOwnerError = message => {
    return {
        type: UPDATE_OWNER_FAILURE,
        message
    }
}
export const RESET_OWNER_NOTIFICATION = "RESET_OWNER";
export const hideOwnerNotification = () => {
    return {
        type: RESET_OWNER_NOTIFICATION
    }
}

//claim access code
export const CLAIM_CODE_REQUEST = "CLAIM_CODE_REQUEST";
export const claimCodeRequest = () => {
    return {
        type: CLAIM_CODE_REQUEST
    }
}
export const CLAIM_CODE_SUCCESS = "CLAIM_CODE_SUCCESS";
export const claimCodeSuccess = (code, data, purchase) => {
    return {
        type: CLAIM_CODE_SUCCESS,
        code,
        data,
        purchase
    }
}
export const CLAIM_CODE_FAILURE = "CLAIM_CODE_FAILURE";
export const claimCodeFailure = message => {
    return {
        type: CLAIM_CODE_FAILURE,
        message
    }
}
export const CLAIM_CODE_RESET = "CLAIM_CODE_RESET";
export const claimCodeReset = () => {
    return {
        type: CLAIM_CODE_RESET
    }
}
