

import React from 'react'
//ui
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//custom
import Footer from 'src/components/reports/Footer';
import PageHeader from './PageHeader';

const IntroPage = () => {

    //variables
    const classes = useStyles();

    return (
        <>
            <PageHeader />
            {/* header */}
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Introduction
                </Typography>
            </Box>

            {/* body */}
            <Box mt={4}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    Small businesses (a few to 50 employees) typically face many business challenges without fully
                    developed infrastructure to properly respond to them. Solving these challenges usually forces difficult
                    choices on how one uses limited resources—fix the urgent or fix the important. In the absence of full
                    capabilities in the business, leaders are challenged to decide between working “on” the business versus
                    working “in” the business. When the choice is to develop the business, it often occurs in an environment
                    of competition and without a full systems view of all that is required.
                </Typography>
            </Box>
            <Box mt={4}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    In the end, these business-building efforts must produce solutions for the customer in a way that
                    creates profit and the right kind of growth for the business—which facilitates a success cycle. In this
                    environment of limited resources and process holders clambering with “me first” demands, which
                    vulnerable processes should be prioritized for improvement efforts?
                </Typography>
            </Box>
            <Box mt={4}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    Vector Reports asks, “What processes do small businesses need to have in place to change from
                    surviving to thriving?” And if you have those processes what if they are underdeveloped? Vector
                    Reports helps identify these priority processes to help you build four important business capabilities:
                </Typography>
            </Box>
            {/*  bullet list */}
            <Box mt={2} ml={6} className={classes.flexStart}>
                <ul>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                            Capability to develop an offering
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                            Capability to establish a business model
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                            Capability to generate revenue
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                            Capability to respond to market demand
                        </Typography>
                    </li>
                </ul>
            </Box>
            <Box mt={4}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    These capabilities, consisting of mature process abilities, are always present in successful businesses!
                </Typography>
            </Box>
            {/* image and paragraphs */}
            <Box mt={4} className={classes.splitRow}>
                <Box>
                    <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                        The VIA·Business Assessment starts with
                        a Level zero because often
                        small businesses have not yet
                        reached even the initial steps
                        toward having robust processes.
                    </Typography>

                    <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} mt={4}>
                        Any shortfalls from a Level 3
                        should be addressed in an
                        efficient sequence—as quickly as
                        possible—to improve the
                        likelihood of success as one
                        endeavor to succeed and grow.
                    </Typography>
                </Box>
                <Box ml={4}>
                    <img width="650" alt="Logo" src="/static/images/vsba/intro-devScale.png" />
                </Box>
            </Box>
            <Box mt={4}>
                <Typography gutterBottom variant="body1" style={{ fontSize: 21 }} >
                    Lower process scores quickly
                    reveal gaps, imbalances and immaturities in your system. Most businesses fail not from the lack of a
                    good idea, but from the lack of robust supporting structures wrapped around that idea or product that
                    prevent bringing it to fruition. Thus, the focus of the Report is to deliver the “what’s vulnerable”
                    messages so you can develop mature business capabilities sooner rather than later in a prioritized
                    sequence. The report also provides initial suggestions on how to improve on your own, and what
                    guidance might be asked of a mentor, coach or consultant.
                </Typography>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },

});

export default IntroPage;
