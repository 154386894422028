//import { useState } from 'react';

import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../LogoDark';
import DevModeSwitch from 'src/components/shared/DevModeSwitch'

const AssessmentNavbar = ({ onMobileNavOpen, ...rest }) => {

    return (
        <AppBar elevation={0} {...rest} sx={{backgroundColor:'white', borderBottom: '1px solid #c0d0ed'}}>
            <Toolbar>
                    <Box ml={5}>
                        <Logo />
                    </Box>
                <Box sx={{ flexGrow: 1 }} />
                <DevModeSwitch />
                <Hidden lgUp>
                    <IconButton onClick={onMobileNavOpen} >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

AssessmentNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default AssessmentNavbar;
