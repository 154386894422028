import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Button } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf'
import { storage } from 'src/services/firebase'
import Loading from 'src/components/shared/Loading'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import pdfFile from './Success-Guide.pdf'
import Footer from 'src/components/shared/Footer';


const Guide = () => {
    const [url, setUrl] = useState(null)
    const [file, setFile] = useState(pdfFile)
    const [numPages, setNumPages] = useState(null)
    const [pageWidth, setPageWidth] = useState(800)

    const containerRef = useRef(null)

    // useEffect(() => {
    //   storage.ref().child('Success-Guide.pdf').getDownloadURL().then((url) => {
    //     console.log('url: ', url)
    //     // This can be downloaded directly:
    //     var xhr = new XMLHttpRequest();
    //     xhr.responseType = 'blob';
    //     xhr.onload = (event) => {
    //       var blob = xhr.response;
    //     };
    //     xhr.open('GET', url);
    //     xhr.send();

    //     console.log('url: ', url)
    //     setUrl(url)
    //   })
    // }, [storage])

    useEffect(() => {
        setPageWidth(containerRef.current.offsetWidth - 100)
    }, [containerRef])

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages)
    }

    const onDownload = () => {
        fetch(file).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Success Guide.pdf';
                alink.click();
            })
        })
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | Success Guide</title>
            </Helmet>
            <Box sx={{ my: 8 }}>
                <Container maxWidth="md" mt={4} ref={containerRef}>
                    <Box m={6}>
                        <Document loading={<Loading />} file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Card sx={{ marginBottom: 2 }}>
                                    <CardContent>
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            scale={1.2}
                                        // width={pageWidth}
                                        />
                                    </CardContent>
                                </Card>
                            ))}
                        </Document>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => onDownload(file)}
                                sx={{ width: 100 }}>Download
                            </Button>
                        </Box>

                        {/* <Card sx={{ marginBottom: 2 }}>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                                
                            </CardContent>
                        </Card> */}
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default Guide