import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@material-ui/core';

import AccessCodeRegistration from './Registration/AccessCodeRegistration';
import RegistrationLogin from './Registration/Login';
import Form from './Registration/Form';
import { verifyCodeReset, verifyEmailReset } from 'src/redux/actions';
import Footer from 'src/components/shared/Footer';

const Register = () => {

    const email = useSelector(state => state.auth.email)
    const didVerifyPass = useSelector(state => state.auth.didVerifyPass)
    const isEmailDoneLoading = useSelector(state => state.auth.isEmailDoneLoading)
    const didEmailPass = useSelector(state => state.auth.didEmailPass)
    const needsRegistration = useSelector(state => state.auth.needsRegistration)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!didVerifyPass && !didEmailPass && !email) {
          console.log("navigate to home screen")
          navigate('/', { replace: true });
        }
    }, [didVerifyPass, didEmailPass, email])

    const Content = () => {

        if(needsRegistration) {
            return <Form onBack={onBackButton} />
        }

        {/* step 1 enter email */ }
        if (didVerifyPass && !isEmailDoneLoading) {
            //access code registration
            return <AccessCodeRegistration />
        }

        {/* step 2 if email sign in */ }
        if (isEmailDoneLoading && didEmailPass) {
            //access code registration
            return <RegistrationLogin />
        } else {
            return <Form onBack={onBackButton} />
        }
    }

    const onBackButton = () => {
        dispatch(verifyCodeReset())
        dispatch(verifyEmailReset())
        dispatch(needsRegistration(false))
        navigate('/', { replace: true })
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | Register</title>
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 8 }} >
                <Container maxWidth="md">
                    {
                        Content()
                    }
                </Container>
            </Box>
            <Footer />
        </>
    )

};


export default Register
